class BlogPost {
    constructor(baseUrl, apiUrl, apiKey) {
        this.slideIndex = 1
        this.baseUrl = baseUrl;
        let postId = srGetURLVar('sr_post_id');
        this.publishUrl = apiUrl + "blog/user/publish/" + postId + "?sr_api_key=" + apiKey;

        let extraImages = document.querySelectorAll('.extra-image');
        let publishPost = document.querySelector('#publish-post');

        let closeBtn = document.querySelector('#image-lightbox .close');
        let nextBtn = document.querySelector('#image-lightbox .next');
        let prevBtn = document.querySelector('#image-lightbox .prev');

        if (extraImages > 0) {
            this.showSlides(this.slideIndex);
        }

        closeBtn.addEventListener('click', () => {
            this.closeModal();
        });

        nextBtn.addEventListener('click', () => {
            this.plusSlides(1);
        });

        prevBtn.addEventListener('click', () => {
            this.plusSlides(-1);
        });

        extraImages.forEach((item, index) => {
            item.addEventListener('click', () => {

                this.currentSlide(index + 1);
                this.openModal();
            })
        });

        publishPost.addEventListener('click', () => {
           this.setPostToPublished();
        });

    }

    showSlides(n) {
        let i;
        const slides = document.getElementsByClassName("lightboxSlides");

        if (n > slides.length) {this.slideIndex = 1}
        if (n < 1) {this.slideIndex = slides.length}
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }

        slides[this.slideIndex-1].style.display = "block";
    }

    openModal() {
        document.getElementById("image-lightbox").style.display = "block";
    }
    closeModal() {
        document.getElementById("image-lightbox").style.display = "none";
    }
    plusSlides(n) {
        this.showSlides(this.slideIndex += n);
    }
    currentSlide(n) {
        this.showSlides(this.slideIndex = n);
    }

    setPostToPublished() {
        $.ajax({
            url: this.publishUrl,
            method: "GET",
            success: (response) => {
                if (response.status === 1) {
                    window.location.href = this.baseUrl + "user/blog";
                } else {
                    let errorModal = document.querySelector('#sr-modal-blog-error-message');
                    let errorModalText = document.querySelector('#sr-modal-blog-error-message .message')
                    $(errorModal).modal('show');
                    errorModalText.innerHTML = response.message;
                    setTimeout(function() {
                        $(errorModal).modal('hide');
                    },2000);
                }
            }
        })
    }
}

window.BlogPost = BlogPost;
