class TestUI {
    constructor() {

        this.testcaseWrapper = document.querySelector("#testcases");
        this.testcaseHeaderWrapper = document.querySelector("#testcaseHeader");
        this.addTestcaseLink = document.querySelector(".addTestcaseLink");
        this.addCategoriesLink = document.querySelector(".addCategoriesLink");
        this.addTestcaseWrapper = document.querySelector(".addTestcase");
        this.addCategoryWrapper = document.querySelector(".addCategories");
        this.categorySelect = document.querySelector("#category");
        this.categoryAddSelect = document.querySelector("#categoryAdd");
        this.actionSelect = document.querySelector('#action');
        this.fieldIdentificationSelect = document.querySelector('#field-identification')
        this.fieldTypeSelect = document.querySelector('#field-type');
        this.fieldConfirmationKeySelect = document.querySelector('#field-confirmation-key');
        this.dimensionSelect = document.querySelector('#dimension');
        this.addTeststepButton = document.querySelector("#addTestcaseStep");
        this.addTestcaseButton = document.querySelector("#addTestcase");
        this.buttonAddCategory = document.querySelector('#addCategory');
        this.buttonAddSubcategory = document.querySelector('#addSubCategory');
        this.categoryAddSelectId = '';

        this.getTestCategories();
        this.initSelectFields();
        this.initializeGeneralEventListener();
    }

    initializeGeneralEventListener() {

        const disabledFields = [
            {
                name: 'check_field',
                field: false,
                field_identification: false,
                field_value: false,
                field_type: false,
                field_confirmation_key: false,
                field_attribute: false
            },
            {
                name: 'check_visibility'
            },
            {
                name: 'close',
                field: true,
                field_identification: true,
                field_value: true,
                field_type: true,
                field_confirmation_key: true,
                field_attribute: true
            },
            {
                name: 'set_available_promotion',
                field: false,
                field_identification: true,
                field_value: false,
                field_type: true,
                field_confirmation_key: true,
                field_attribute: true
            },
            {
                name: 'set_field_value',
                field: false,
                field_identification: false,
                field_value: false,
                field_type: false,
                field_confirmation_key: false,
                field_attribute: false
            },
            {
                name: 'start',
                field: true,
                field_identification: true,
                field_value: false,
                field_type: true,
                field_confirmation_key: true,
                field_attribute: true
            },
            {
                name: 'wait_page_change'
            }
        ];
        const newTestcase = new NewTestcase();
        const newCategories = new NewCategories();

        this.addTestcaseLink.addEventListener('click', () => {
            this.addTestcaseWrapper.classList.remove('hidden');
            this.testcaseWrapper.innerHTML = "";
            this.testcaseHeaderWrapper.innerHTML = "";
            this.addCategoryWrapper.classList.add('hidden');
        });

        this.addCategoriesLink.addEventListener('click', () => {
            this.addCategoryWrapper.classList.remove('hidden');
            this.testcaseWrapper.innerHTML = "";
            this.testcaseHeaderWrapper.innerHTML = "";
            this.addTestcaseWrapper.classList.add('hidden');
        });

        this.addTeststepButton.addEventListener('click', () => {
            newTestcase.initializeTeststepFormular();

            $('#action').val(null).trigger('change');
            document.querySelector('#field').value = ""
            $('#field-identification').val(null).trigger('change');
            document.querySelector('#field-value').value = "";
            $('#field-type').val(null).trigger('change');
            $('#field-confirmation-key').val(null).trigger('change');
            document.querySelector('#field-attribute').value = "";
        });

        this.addTestcaseButton.addEventListener('click', () => {
            newTestcase.saveTestcase(newTestcase.collectTeststepData());
        });

        $(this.actionSelect).on('select2:select', (event) => {
            disabledFields.forEach((item) => {
                if (item.name === event.params.data.text) {
                    document.querySelector('#field').disabled = item.field;
                    document.querySelector('#field-identification').disabled = item.field_identification;
                    document.querySelector('#field-value').disabled = item.field_value;
                    document.querySelector('#field-type').disabled = item.field_type;
                    document.querySelector('#field-confirmation-key').disabled = item.field_confirmation_key;
                    document.querySelector('#field-attribute').disabled = item.field_attribute;
                }
            })
        });

        this.buttonAddCategory.addEventListener('click', () => {
            const categoryName = document.querySelector('#addNewCategoryLabel');
            newCategories.insertNewCategory(categoryName.value);
        });

        this.buttonAddSubcategory.addEventListener('click', () => {
            const subcategoryName = document.querySelector('#addNewSubCategoryLabel');
            newCategories.insertNewSubcategory(subcategoryName.value, this.categoryAddSelectId);
        });

        $(this.categoryAddSelect).on('select2:select', (event) => {
            this.categoryAddSelectId = event.params.data.id;
        });
    }

    getTestCategories() {
        let testcaseCategoryWrapper = document.querySelector(".testcaseDetailLinks");
        let testcaseWrapper = document.querySelector("#testcases");
        $.ajax({
            url: 'http://localhost:8886/api/v1/testcase/categories',
            type: "GET",
            crossDomain: true,
            xhrFields: {
                withCredentials: false
            },
            success: (response) => {
                let html = '';
                response.forEach((category) => {
                    html += '<div class="testcaseDetailLink" data-sr-category="' + category.id + '" data-sr-category-name="' + category.name + '">' + category.name + '</div>';
                });
                testcaseCategoryWrapper.innerHTML = html;

                let testcaseDetailLinks = document.querySelectorAll('.testcaseDetailLink');

                testcaseDetailLinks.forEach((testcaseDetailLink) => {
                    testcaseDetailLink.addEventListener('click', (event) => {
                        testcaseWrapper.innerHTML = "";
                        this.getSubcategories(event.target.dataset.srCategory, event.target.dataset.srCategoryName);
                        this.addTestcaseWrapper.classList.add('hidden');
                        this.addCategoryWrapper.classList.add('hidden');
                    });
                });
            }
        });
    }

    getSubcategories(id, categoryName) {
        $.ajax({
            url: 'http://localhost:8886/api/v1/testcase/categories/' + id + '/subcategories',
            type: 'GET',
            crossDomain: true,
            xhrFields: {
                withCredentials: false
            },
            success: (response) => {
                this.getTestcasesBySubcategory(response, categoryName);
            }
        });
    }

    getTestcasesBySubcategory(category, categoryName) {
        let testcaseWrapper = document.querySelector('#testcases');
        let testcaseHeader = document.querySelector('#testcaseHeader');
        testcaseHeader.innerHTML = this.testobjectHeaderTemplate(categoryName);
        category.forEach((subcategory) => {
            $.ajax({
                url: 'http://localhost:8886/api/v1/testcase/subcategory/' + subcategory.id,
                type: 'GET',
                crossDomain: true,
                xhrFields: {
                    withCredentials: false
                },
                success: (response) => {
                    let template = this.testobjectTemplate(subcategory, response);
                    $(testcaseWrapper).append(template.html);

                    const testobjectRunBtn = document.querySelectorAll('.testobjectRunner');
                    testobjectRunBtn.forEach((element) => {
                        $(element).off('click').on('click', (event) => {
                            this.testobjectRunnerButton(event);
                        });
                    });

                    const singleRunBtn = document.querySelectorAll('.testcase-run');
                    singleRunBtn.forEach((element) => {
                        const testcaseId = element.parentNode.getAttribute('data-testcase-id');
                        $(element).off('click').on('click', () => {
                            this.singleRunnerButton(testcaseId);
                        });
                    });
                },
            });
        });

        const runAll = document.querySelector('#run');
        runAll.addEventListener("click", (event) => this.allRunnerButton(event));

    }

    runTestcase(id, testcases) {
        if (testcases[id].status === false) {
            $.ajax({
                url: 'http://localhost:8886/api/v1/testcase/' + testcases[id].id,
                type: "GET",
                crossDomain: true,
                xhrFields: {
                    withCredentials: false
                },
                beforeSend: () => {
                    let testcaseStatus = document.querySelector('[data-testcase-id="' + testcases[id].id + '"] .testcase-status');
                    testcaseStatus.innerHTML = "<div class='spinner'><img src='../../images/loading.svg' alt=''></div>";
                },
                success: (response) => {
                    testcases[id].status = true;
                    let testcaseStatus = document.querySelector('[data-testcase-id="' + testcases[id].id + '"] .testcase-status');
                    if (response.status === true) {
                        testcaseStatus.innerHTML = "<div class='testcase-success'><img src='../../images/responsive/icons/success@2x.png' alt=''></div>";
                    } else {
                        testcaseStatus.innerHTML = "<div class='testcase-warning'><img src='../../images/responsive/icons/warning@2x.png' alt=''></div>";
                        let errorMsg = '';
                        response.testcaseResults.forEach((item) => {
                            if (item.status === false) {
                                errorMsg += item.statusMsg + "<br>";
                            }
                        });
                        document.querySelector('.testcase-result[data-testcase-id="' + testcases[id].id + '"]').classList.remove('hide');
                        document.querySelector('.testcase-result[data-testcase-id="' + testcases[id].id + '"] .error-header .error-header-text').innerHTML = "Fehlermeldung";
                        document.querySelector('.testcase-result[data-testcase-id="' + testcases[id].id + '"] .error-msg .error-msg-text').innerHTML = errorMsg;
                    }

                    let startTestcase = new Date(response.testcaseStart);
                    let endTestcase = new Date(response.testcaseEnd);
                    let duration = endTestcase.getTime() - startTestcase.getTime();
                    document.querySelector('[data-testcase-id="' + testcases[id].id + '"] .testcase-duration').innerHTML = (duration / 1000).toFixed(1) + "s";

                    const nextId = ++id;
                    if (nextId in testcases) {
                        this.runTestcase(nextId, testcases);
                    }
                }
            });

        } else if (testcases[id].status === true) {
            const nextId = ++id;
            if (nextId in testcases) {
                this.runTestcase(nextId, testcases);
            }
        }

    }

    testobjectHeaderTemplate(categoryName) {
        let testObjectMainHtml = "<div class='testobject-mainheader'><h2>Testfälle " + categoryName + "</h2>";
        testObjectMainHtml += "<button id='run' class='btn btn-default mr-15' type='button'>Alle Testfälle ausführen</button>";
        testObjectMainHtml += "</div>";

        return testObjectMainHtml;
    }

    testobjectTemplate(testObject, testcases) {
        let testObjectHtml = "<div class='testobject' data-testobject='" + testObject.name + "'>";
        let testcasesAvailable = false;
        testObjectHtml += "<div class='testobject-header'><div class='testobject-title'>" + testObject.name + "</div>";
        testObjectHtml += "<div class='testobject-button'><button class='btn btn-default testobjectRunner' type='button' data-testobject='" + testObject.name + "'>Auführung aller Testfälle in  " + testObject.name + "</button></div></div>";
        testObjectHtml += "<div class='testobject-testcases'>";
        testObjectHtml += "<div class='testobject-table-header'>";
        testObjectHtml += "<div class='testcase-id'>Id</div>";
        testObjectHtml += "<div class='testcase-description'>Beschreibung</div>";
        testObjectHtml += "<div class='testcase-run'>Run</div>";
        testObjectHtml += "<div class='testcase-duration'>Laufzeit</div>";
        testObjectHtml += "<div class='testcase-status'>Status</div>";
        testObjectHtml += "</div>";

        testcases.forEach((testcase) => {
            testcasesAvailable = true;
            testObjectHtml += "<div class='testcase-entry' data-testcase-id='" + testcase.id + "'>";
            testObjectHtml += "<div class='testcase-id'>" + testcase.id + "</div>";
            testObjectHtml += "<div class='testcase-description'>" + testcase.description + "</div>";
            testObjectHtml += "<div class='testcase-run run-icon'></div>";
            testObjectHtml += "<div class='testcase-duration'></div>";
            testObjectHtml += "<div class='testcase-status'></div>";
            testObjectHtml += "</div>";

            testObjectHtml += "<div class='testcase-result hide' data-testcase-id='" + testcase.id + "'>";
            testObjectHtml += "<div class='error-header'><div class='w-5'></div><div class='error-header-text'></div></div>";
            testObjectHtml += "<div class='error-msg'><div class='w-5'></div><div class='error-msg-text'></div></div>";
            testObjectHtml += "</div>";
        });

        testObjectHtml += "</div></div>";

        return {
            'html': testObjectHtml,
            'status': testcasesAvailable
        };
    }

    testobjectRunnerButton(event) {

        const testcases = document.querySelectorAll('[data-testobject="' + event.currentTarget.getAttribute('data-testobject') + '"] .testobject-testcases .testcase-entry');
        let testcaseData = [];
        const testcasesKeys = Object.keys(testcases);

        testcasesKeys.forEach((value) => {
            let element = [];
            element.id = testcases[value].getAttribute('data-testcase-id');

            testcases[value].children[4].innerHTML = "<div class='pending'></div>";
            element.status = false;
            testcaseData.push(element);
        });
        this.runTestcase(0, testcaseData);
    }

    singleRunnerButton(testcaseId) {
        //const testcaseLine = document.querySelector('[data-testcase-id="' + testcaseId + '"] .testcase-status');

        const testcaseData = {};
        testcaseData[testcaseId] = {
            'id': testcaseId,
            'status': false
        };

        this.runTestcase(testcaseId, testcaseData);
    }

    allRunnerButton() {
        const testcases = document.querySelectorAll('.testobject-testcases .testcase-entry');
        let testcaseData = [];

        testcases.forEach((testcase) => {
            let element = [];
            element.id = testcase.getAttribute('data-testcase-id');
            testcase.children[4].innerHTML = "<div class='pending'></div>";
            element.status = false;
            testcaseData.push(element);
        });
        this.runTestcase(0, testcaseData);

    }

    initSelectFields() {
        $(this.dimensionSelect).select2({
            minimumResultsForSearch: Infinity,
            ajax: {
                url: 'http://localhost:8886/api/v1/testcase/dimensions',
                dataType: 'json',
                crossDomain: true,
                xhrFields: {
                    withCredentials: false
                },
                processResults: function (data) {

                    let resVal = [];
                    data.forEach((item) => {
                        resVal.push({
                            'id': item.id,
                            'text': item.name + " (" + item.width + " x " + item.height + ")"
                        });
                    });
                    return {
                        results: resVal
                    }
                }
            }
        });

        $(this.categoryAddSelect).select2({
            minimumResultsForSearch: Infinity,
            ajax: {
                url: 'http://localhost:8886/api/v1/testcase/categories',
                dataType: 'json',
                crossDomain: true,
                xhrFields: {
                    withCredentials: false
                },
                processResults: function (data) {

                    let resVal = [];
                    data.forEach((item) => {
                        resVal.push({
                            'id': item.id,
                            'text': item.name
                        });
                    });
                    return {
                        results: resVal
                    }
                }
            }
        });

        $(this.categorySelect).select2({
            minimumResultsForSearch: Infinity,
            ajax: {
                url: 'http://localhost:8886/api/v1/testcase/categories',
                dataType: 'json',
                crossDomain: true,
                xhrFields: {
                    withCredentials: false
                },
                processResults: function (data) {

                    let resVal = [];
                    data.forEach((item) => {
                        resVal.push({
                            'id': item.id,
                            'text': item.name
                        });
                    });
                    return {
                        results: resVal
                    }
                }
            }
        });

        $(this.actionSelect).select2({
            minimumResultsForSearch: Infinity,
            ajax: {
                url: 'http://localhost:8886/api/v1/testcase/actions',
                dataType: 'json',
                crossDomain: true,
                xhrFields: {
                    withCredentials: false
                },
                processResults: function (data) {

                    let resVal = [];
                    data.forEach((item) => {
                        resVal.push({
                            'id': item.id,
                            'text': item.name
                        });
                    });
                    return {
                        results: resVal
                    }
                }
            }
        });

        $(this.fieldIdentificationSelect).select2({
            minimumResultsForSearch: Infinity,
            ajax: {
                url: 'http://localhost:8886/api/v1/testcase/fieldidentification',
                dataType: 'json',
                crossDomain: true,
                xhrFields: {
                    withCredentials: false
                },
                processResults: function (data) {

                    let resVal = [];
                    data.forEach((item) => {
                        resVal.push({
                            'id': item.id,
                            'text': item.name
                        });
                    });
                    return {
                        results: resVal
                    }
                }
            }
        });

        $(this.fieldTypeSelect).select2({
            minimumResultsForSearch: Infinity,
            ajax: {
                url: 'http://localhost:8886/api/v1/testcase/fieldtype',
                dataType: 'json',
                crossDomain: true,
                xhrFields: {
                    withCredentials: false
                },
                processResults: function (data) {

                    let resVal = [];
                    data.forEach((item) => {
                        resVal.push({
                            'id': item.id,
                            'text': item.name
                        });
                    });
                    return {
                        results: resVal
                    }
                }
            }
        });

        $(this.fieldConfirmationKeySelect).select2({
            minimumResultsForSearch: Infinity,
            ajax: {
                url: 'http://localhost:8886/api/v1/testcase/confirmationkeys',
                dataType: 'json',
                crossDomain: true,
                xhrFields: {
                    withCredentials: false
                },
                processResults: function (data) {

                    let resVal = [];
                    data.forEach((item) => {
                        resVal.push({
                            'id': item.id,
                            'text': item.name
                        });
                    });
                    return {
                        results: resVal
                    }
                }
            }
        });

        $('#subcategory').select2();

        $(this.categorySelect).on("select2:select", () => {
            $('#subcategory').select2({
                ajax: {
                    url: 'http://localhost:8886/api/v1/testcase/categories/' + this.categorySelect.value + '/subcategories',
                    dataType: 'json',
                    crossDomain: true,
                    xhrFields: {
                        withCredentials: false
                    },
                    processResults: function (data) {
                        let resVal = [];
                        data.forEach((item) => {
                            resVal.push({
                                'id': item.id,
                                'text': item.name
                            });
                        });
                        return {
                            results: resVal
                        }
                    }
                }
            });
        });
    }

}

class NewTestcase {
    initializeTeststepFormular() {
        let action = document.querySelector('#action');
        let field = document.querySelector('#field');
        let fieldIdentification = document.querySelector("#field-identification");
        let fieldValue = document.querySelector("#field-value");
        let fieldType = document.querySelector("#field-type");
        let fieldConfirmationKey = document.querySelector('#field-confirmation-key');
        let fieldAttribute = document.querySelector('#field-attribute');

        let stepItem = document.querySelectorAll('.step-item');

        let testobject = {
            'action': {
                id: $(action).select2('data')[0] ? $(action).select2('data')[0].id : '',
                value: $(action).select2('data')[0] ? $(action).select2('data')[0].text : ''
            },
            'field': field.value,
            'field_identification': {
                id: $(fieldIdentification).select2('data')[0] ? $(fieldIdentification).select2('data')[0].id : '',
                value: $(fieldIdentification).select2('data')[0] ? $(fieldIdentification).select2('data')[0].text : ''
            },
            'field_value': fieldValue.value,
            'field_type': {
                id: $(fieldType).select2('data')[0] ? $(fieldType).select2('data')[0].id : '',
                value: $(fieldType).select2('data')[0] ? $(fieldType).select2('data')[0].text : ''
            },
            'field_confirmation_key': {
                id: $(fieldConfirmationKey).select2('data')[0] ? $(fieldConfirmationKey).select2('data')[0].id : '',
                value: $(fieldConfirmationKey).select2('data')[0] ? $(fieldConfirmationKey).select2('data')[0].text : ''
            },
            'field_attribute': fieldAttribute.value
        }

        let teststeps = document.querySelector(".testcaseSteps-list");
        let html = teststeps.innerHTML;
        html += this.teststepTemplate((stepItem.length + 1), testobject);
        teststeps.innerHTML = html;

        // initialize delete buttons
        this.deleteTeststepEventlistener();
    }

    deleteTeststepEventlistener() {
        const deleteButton = document.querySelectorAll('.icon-delete');
        deleteButton.forEach((element) => {
            $(element).off('click').on('click', (event) => {
                this.deleteTeststep(event.currentTarget.parentNode.parentElement.getAttribute('data-teststep-order'));
            });
        });
    }

    deleteTeststep(deleteStepNo) {
        const teststeps = document.querySelectorAll('.step-item');
        teststeps.forEach((teststep) => {
            const stepNo = teststep.getAttribute('data-teststep-order');
            if (stepNo === deleteStepNo) {
                teststep.remove();
            }
        });

        const teststepsNew = document.querySelectorAll('.step-item');
        teststepsNew.forEach((teststep, index) => {

            const newStepNo = index + 1;
            teststep.setAttribute('data-teststep-order', newStepNo);
            teststep.querySelector('.step-order').innerHTML = newStepNo;
        })
    }

    teststepTemplate(stepNo, teststepObject) {
        let html = "";
        html += "<div class='step-item' data-teststep-order='" + stepNo + "'>";
        html += "<div class='step-order'>" + stepNo + "</div>";

        html += "<div class='step-information'>";

        html += "<div class='step-section-one'>";
        html += "<div class='step-detail step-action'>";
        html += "<div class='step-item-header'>Action</div>";
        html += "<div class='step-item-value' data-teststep-action-id='" + teststepObject.action.id + "'>" + teststepObject.action.value + "</div>";
        html += "</div>";
        html += "<div class='step-detail step-field'>";
        html += "<div class='step-item-header'>Field</div>";
        html += "<div class='step-item-value'>" + teststepObject.field + "</div>";
        html += "</div>";
        html += "<div class='step-detail step-field-identification'>";
        html += "<div class='step-item-header'>Field Identification</div>";
        html += "<div class='step-item-value' data-teststep-field-identification-id='" + teststepObject.field_identification.id + "'>" + teststepObject.field_identification.value + "</div>";
        html += "</div>";
        html += "<div class='step-detail step-field-value'>";
        html += "<div class='step-item-header'>Field Value</div>";
        html += "<div class='step-item-value'>" + teststepObject.field_value + "</div>";
        html += "</div></div>";

        html += "<div class='step-section-two'>";
        html += "<div class='step-detail step-field-type'>";
        html += "<div class='step-item-header'>Field Type</div>";
        html += "<div class='step-item-value' data-teststep-field-type-id='" + teststepObject.field_type.id + "'>" + teststepObject.field_type.value + "</div>";
        html += "</div>";
        html += "<div class='step-detail step-field-confirmation-key'>";
        html += "<div class='step-item-header'>Field Confirmation Key</div>";
        html += "<div class='step-item-value' data-teststep-field-confirmation-key-id='" + teststepObject.field_confirmation_key.id + "'>" + teststepObject.field_confirmation_key.value + "</div>";
        html += "</div>";
        html += "<div class='step-detail step-field-attribute'>";
        html += "<div class='step-item-header'>Field Attribute</div>";
        html += "<div class='step-item-value' data-teststep-field-attribute-id='" + teststepObject.field_attribute.id + "'>" + teststepObject.field_attribute.value + "</div>";
        html += "</div></div>";

        html += "</div>";

        html += "<div class='step-buttons'><i class='icon-delete pl-10'></i></div>";

        html += "</div></div>";

        return html;
    }

    collectTeststepData() {
        let steps = document.querySelectorAll('.step-item');
        let testcaseObject = [];
        let testcaseStepsObject = [];

        let description = document.querySelector('#testcaseDescription');
        let category = document.querySelector('#category');
        let subCategory = document.querySelector('#subcategory');
        let dimension = document.querySelector('#dimension');

        steps.forEach(stepElement => {

            let action = stepElement.querySelector('.step-action');
            let field = stepElement.querySelector('.step-field');
            let fieldIdentification = stepElement.querySelector('.step-field-identification');
            let fieldValue = stepElement.querySelector('.step-field-value');
            let fieldType = stepElement.querySelector('.step-field-type');
            let fieldConfirmationKey = stepElement.querySelector('.step-field-confirmation-key');
            let fieldAttribute = stepElement.querySelector('.step-field-attribute');

            let stepObject = {};

            stepObject['action'] = action.querySelector('.step-item-value').getAttribute('data-teststep-action-id');
            stepObject['fieldIdentification'] = fieldIdentification.querySelector('.step-item-value').getAttribute('data-teststep-field-identification-id');
            stepObject['fieldType'] = fieldType.querySelector('.step-item-value').getAttribute('data-teststep-field-type-id');
            stepObject['fieldConfirmationKey'] = fieldConfirmationKey.querySelector('.step-item-value').getAttribute('data-teststep-field-confirmation-key-id');
            stepObject['field'] = field.querySelector('.step-item-value').innerText;
            stepObject['fieldValue'] = fieldValue.querySelector('.step-item-value').innerText;
            stepObject['fieldAttribute'] = fieldAttribute.querySelector('.step-item-value').innerText;

            stepObject['teststepOrder'] = stepElement.getAttribute('data-teststep-order');

            testcaseStepsObject.push(stepObject);
        });
        // $(fieldType).select2('data')[0]
        testcaseObject['description'] = description.value;
        testcaseObject['category'] = {
            "id": $(category).select2('data')[0].id,
            "value": $(category).select2('data')[0].text
        }
        testcaseObject['subcategory'] = {
            "id": $(subCategory).select2('data')[0].id,
            "value": $(subCategory).select2('data')[0].text
        }
        testcaseObject['dimension'] = {
            "id": $(dimension).select2('data')[0].id
        }

        testcaseObject['steps'] = testcaseStepsObject;
        console.log(testcaseObject);
        return testcaseObject;
    }

    saveTestcase(testcaseObject) {

        const testcaseData = {
            description: testcaseObject['description'],
            category: testcaseObject['category'].id,
            subcategory: testcaseObject['subcategory'].id,
            testcaseStepsList: testcaseObject['steps'],
            dimension: testcaseObject['dimension'].id
        }

        $.ajax({
            url: 'http://localhost:8886/api/v1/testcase',
            type: "POST",
            crossDomain: true,
            contentType: 'application/json; charset=utf-8',
            xhrFields: {
                withCredentials: false
            },
            processData: false,
            data: JSON.stringify(testcaseData),
            success: (response) => {
                console.log(response);
            }
        });
    }
}

class NewCategories {
    insertNewCategory(categoryName) {
        let category = {
            name: categoryName
        }
        $.ajax({
            url: 'http://localhost:8886/api/v1/testcase/categories',
            type: "POST",
            crossDomain: true,
            contentType: 'application/json; charset=utf-8',
            xhrFields: {
                withCredentials: false
            },
            processData: false,
            data: JSON.stringify(category),
            success: (response) => {
                console.log(response);
            }
        });
    }

    insertNewSubcategory(subcategoryName, categoryId) {
        let subcategory = {
            name: subcategoryName,
            categoryId: categoryId
        }
        $.ajax({
            url: 'http://localhost:8886/api/v1/testcase/subcategories',
            type: "POST",
            crossDomain: true,
            contentType: 'application/json; charset=utf-8',
            xhrFields: {
                withCredentials: false
            },
            processData: false,
            data: JSON.stringify(subcategory),
            success: (response) => {
                console.log(response);
            }
        });
    }
}

window.TestUI = TestUI;
