class PromotionOverview {
    constructor(data, baseUrl, apiUrl, apiKey) {
        this.data = data;
        this.baseUrl = baseUrl;
        this.apiUrl = apiUrl;
        this.apiKey = apiKey;

        this.initializeForm();
        this.initializeEventListener();

        $('#promotionFields').html(this.templatePromotionFund({
            "promotionId": "new"
        }));
        const quillEditor = new QuillEditor();
        quillEditor.createEditor("promotion-textfield-new");

        this.insertPromotionFund();
        this.insertDateAndTime();

        this.initializeQuillEditor();

        srInitEncml();
    }

    insertDateAndTime() {
        $( ".datepicker").datepicker({format: 'dd.mm.yyyy'});
        this.data.promotionValues.forEach((item) => {
            const date = item.promotionCurrentStatus.split(" ");
            const d = new Date(date[0] + "T" + date[1]);
            $('#promotion-last-date-' + item.promotionId).datepicker("setDate", d).datepicker("update");
            $('#promotion-last-time-' + item.promotionId).val(date[1]).trigger('change');
        });
    }

    insertPromotionFund() {
        let html = '';
        this.data.promotionValues.forEach((item) => {
            if (item.promotionProgressbar === 1) {
                html += this.templatePromotionFragment(
                    this.data.adminButtons,
                    item
                );
            } else {
                html += this.templatePromotionFragmentNoBar(
                    this.data.adminButtons,
                    item
                );
            }
        });

        $('#akkordeon-wrapper').html(html);

        this.initializeEventListener();
        this.initializeSelect2();
    }

    initializeForm() {

        if (this.data.adminButtons === "1") {
            $('#promotionAdminButtons').html(this.templateForAdminButtons("new", "all"));
        }
    }

    initializeSelect2() {
        $('.promotion-country').select2({
            placeholder: "Bitte auswählen",
            ajax: {
                url: this.apiUrl + "promotion/countries?sr_api_key=" + this.apiKey,
                dataType: 'json',
                data: function(params) {
                    return {
                        sr_cityname: params.term
                    }
                },
                processResults: (data) => {
                    let resVal = [];
                    $.each(data, function(index,item) {
                        resVal.push({
                            'id' : item.country_id,
                            'text': item.country_name
                        });
                    });
                    return {
                        results: resVal
                    };
                }
            }
        });

        let data = [];
        for (let key in this.data.fstates) {
            const item = {
                id: key,
                text: this.data.fstates[key]
            }
            data.push(item);
        }
        $('.promotion-fstate').select2({
            data: data
        });

        let priceData = [];
        for (let key in this.data.priceTable) {
            const item = {
                id: this.data.priceTable[key],
                text: this.data.priceTable[key]
            }
            priceData.push(item);
        }
        $('.promotion-price-table').select2({
            data: priceData
        });

        // pre select values
        this.data.promotionValues.forEach((item) => {
            $('#promotion-fstate-' + item.promotionId).val(item.promotionFstateId).trigger('change');
            $('#promotion-price-table-' + item.promotionId).val(item.promotionPriceTableYear).trigger('change');
            const option = new Option(item.promotionCountryName, item.promotionCountryId, true, true);
            $('#promotion-country-' + item.promotionId).append(option).trigger('change');
        });

        // Pre select Germany for select field for adding new promotion funds
        const option = new Option('Germany', 57, true, true);
        $('#promotion-country-new').append(option).trigger('change');

    }

    initializeEventListener() {
        const thisForm = this;

        $('.promotion-button').off('click').on('click', function(e) {
            const id = $(this).data('promotionFundId');

            let selectorPromotionFundAdmin = $(this).parents('.akkordeon-topic-body').find('.promotion-admin-fields');
            if (id === "new") {
                selectorPromotionFundAdmin = $(this).parent().next();
            }
            selectorPromotionFundAdmin.toggleClass('show');
        });

        $('.deleteData').off('click').on('click', function(e) {
            thisForm.deletePromotionFund(e);
        });

        $('.saveData, .submitData').off('click').on('click', function(e) {
            thisForm.updateOrInsertPromotionFund(e);
        });

        // accordion active toggle, remove active from other accordions when clicking another
        $(".akkordeon-topic a").off('click').on('click', function () {
            $(this).parents('.akkordeon-topic').toggleClass('active');
            if (!$(this).parents(".akkordeon-topic").hasClass("active")) {
                // clicking on a closing accordion removes all active ones
                $('.akkordeon-topic').removeClass('active');
            } else {
                // remove them from all other and add active to the one clicked
                $('.akkordeon-topic').removeClass('active');
                $(this).parents(".akkordeon-topic").addClass("active");
            }

            thisForm.handleMoreLink($(this));
        });

        $('.promotion-total-general').off('click').on('click', (e) => {
           const inputField = $(e.currentTarget).parents('.form-col').find('.promotion-total');
           const inputFieldAvailable = $(e.currentTarget).parents('.form-horizontal').find('.promotion-available');

           if (inputField.attr('disabled') === "disabled") {
               inputField.removeAttr('disabled');
           } else {
               inputField.val(0);
               inputField.attr('disabled', 'disabled');
           }

            if (inputFieldAvailable.attr('disabled') === "disabled") {
                inputFieldAvailable.removeAttr('disabled');
            } else {
                inputFieldAvailable.val(0);
                inputFieldAvailable.attr('disabled', 'disabled');
            }
        });

        $('.promotion-membership-general').off('click').on('click', (e) => {
            const inputField = $(e.currentTarget).parents('.form-col').find('.promotion-membership');

            if (inputField.attr('disabled') === "disabled") {
                inputField.removeAttr('disabled');
            } else {
                inputField.val(0);
                inputField.attr('disabled', 'disabled');
            }
        });
    }

    handleMoreLink(element) {
        const akkordeon = element.parent().next();
        const infoElement = akkordeon.find('.promotion-info-text');
        const moreLink = akkordeon.find('.more-link');

        moreLink.hide();
        const screenWidth = $(window).width();

        if (screenWidth <= 768) {
            const collapsedContainerHeight = 135;
            setTimeout(() => {
                if (infoElement.height() < collapsedContainerHeight) {
                    infoElement.next().hide();
                } else {
                    infoElement.next().show();
                    moreLink.show();
                    infoElement.css("max-height", collapsedContainerHeight + "px");
                    infoElement.css("overflow", "hidden");
                }

                moreLink.on("click", function(e) {
                    e.preventDefault();

                    if ($(this).attr('data-sr-length') === "more") {
                        $(this).attr('data-sr-length', 'less');
                        $(this).parent().prev().css("max-height", "inherit");
                        $(this).parent().prev().css("overflow", "inherit");
                        $(this).html("weniger lesen");
                    } else {
                        $(this).attr('data-sr-length', 'more');
                        $(this).parent().prev().css("max-height", collapsedContainerHeight + "px");
                        $(this).parent().prev().css("overflow", "hidden");
                        $(this).html("mehr lesen");
                    }
                });

            }, 100);

        }
    }

    checkOnMandatoryFields(mainElement) {
        const msg_empty = "Pflichtfeld";
        let feedback = true;

        mainElement[0].querySelectorAll('.sr_validate_numbernotempty').forEach((element) => {
            const feedback_elem = $(element).next("span.sr_form_feedback");
            if ($(element).val().trim() === "" || !$.isNumeric($(element).val())) {
                feedback_elem.html(msg_empty);
                $(element).addClass("feedback-form");
                feedback = false;
            } else {
                if (feedback_elem.html() === msg_empty) {
                    feedback_elem.html("");
                }
                $(element).removeClass("feedback-form");
                feedback = true;
            }
        });

        mainElement[0].querySelectorAll('.sr_validate_notempty').forEach((element) => {
            const feedback_elem = $(element).next("span.sr_form_feedback");
            if (!$(element).val() || $(element).val().trim() === "") {
                feedback_elem.html(msg_empty);
                $(element).addClass("feedback-form");
                feedback = false;
            } else {
                if (feedback_elem.html() === msg_empty) {
                    feedback_elem.html("");
                }
                $(element).removeClass("feedback-form");
                feedback = true;
            }
        });
        return feedback;
    }

    updateOrInsertPromotionFund(e) {
        const promotionId = $(e.target).attr('data-promotion-fund-id');
        const form = this;

        $('#sr-promotion-changes-finish').modal('show');

        $('#sr-promotion-final-finish').off('click').on('click', function(e) {
            const quillEditor = new QuillEditor();
            const promotionInfo = quillEditor.getEditor(document.querySelector("#promotion-textfield-" + promotionId));

            const data = {
                'promotionId': promotionId,
                'country_id': parseInt($('#promotion-country-' + promotionId).val()),
                'fstate': $('#promotion-fstate-' + promotionId).select2('data')[0].text,
                'priceTable': parseInt($('#promotion-price-table-' + promotionId).val()),
                'promotionTotal':  parseInt($('#promotion-total-' + promotionId).val() * 100),
                'promotionTotalGeneral': $('#promotion-total-general-' + promotionId).prop('checked') === true ? 1 : 0,
                'promotionAvailable': parseInt($('#promotion-available-' + promotionId).val() * 100),
                'promotionPercent': parseInt($('#promotion-percent-' + promotionId).val()),
                'promotionMax': parseInt($('#promotion-fix-' + promotionId).val() * 100),
                'promotionMembership': parseInt($('#promotion-membership-' + promotionId).val()),
                'promotionMembershipGeneral': $('#promotion-membership-general-' + promotionId).prop('checked') === true ? 1 : 0,
                'active': $('#promotion-active-' + promotionId).prop('checked') === true ? 1 : 0,
                'lastDate': $('#promotion-last-date-' + promotionId).val(),
                'lastTime': $('#promotion-last-time-' + promotionId).val(),
                'promotionProgressbar': $('#promotion-progressbar-' + promotionId).prop('checked') === true ? 1 : 0,
                'promotionInfo': promotionInfo.getSemanticHTML()
            }

            if (promotionId === "new") {
                $.ajax({
                    url: form.apiUrl + "promotion/insert?sr_api_key=" + form.apiKey + "&XDEBUG_SESSION=IDEA",
                    type: 'post',
                    data: data,
                    success: function (response) {
                        const utility = new Utility();
                        utility.showErrorMessageInBanner(3000, response.msg);
                        if (response.error === 0) {
                            setTimeout(() => {
                                location.reload();
                            }, 3000);
                        }

                    }
                });
            } else {
                $.ajax({
                    url: form.apiUrl + "promotion/update?sr_api_key=" + form.apiKey + "&XDEBUG_SESSION=IDEA",
                    type: 'post',
                    data: data,
                    success: function (response) {
                        const utility = new Utility();
                        utility.showErrorMessageInBanner(3000, response.msg);
                        if (response.error === 0) {
                            setTimeout(() => {
                                location.reload();
                            }, 3000);
                        }
                    }
                });
            }
        });

    }

    deletePromotionFund(e) {
        const form = this;
        $('#sr-delete-promotion-finish').modal('show');
        $('#sr-promotion-delete-finish').on('click', function() {

            const data = {
                'promotionId': $(e.currentTarget).attr('data-promotion-fund-id')
            }

            $.ajax({
                url: form.apiUrl + "promotion/delete?sr_api_key=" + form.apiKey + "&XDEBUG_SESSION=IDEA",
                type: 'post',
                data: data,
                success: function(response) {
                    const utility = new Utility();
                    utility.showErrorMessageInBanner(3000, response.msg);
                    if (response.error === 0) {
                        setTimeout(() => {
                            location.reload();
                        }, 3000);
                    }
                }
            });
        });
    }

    templateForAdminButtons(promotionFundId, exportFstate, active) {
        let adminButtons = '';

        if (promotionFundId !== "new") {
            if (active) {
                adminButtons = '<a href="/admin/promotions/xls?L=1&export=' + exportFstate + '&XDEBUG_SESSION=IDEA"><button type="button" class="btn btn-success" id="exportAllPromotionFunds">Excel-Export</button></a>';
            }
            adminButtons += '<button type="button" id="addNewPromotionFund" class="promotion-button btn float-right" data-promotion-fund-id="' + promotionFundId +'">Daten ändern</button>';
        } else {
            adminButtons = '<a href="/admin/promotions/xls?L=1&export=' + exportFstate + '&XDEBUG_SESSION=IDEA"><button type="button" class="btn btn-success" id="exportAllPromotionFunds">Excel-Export aller Fördertöpfe</button></a>';
            adminButtons += '<button type="button" id="addNewPromotionFund" class="promotion-button btn float-right" data-promotion-fund-id="' + promotionFundId +'">Neuen Fördertopf hinzufügen</button>';
        }

        return adminButtons;
    }

    templatePromotionFund(data) {

        if (data.promotionId === "new") {
            data.promotionTotal = '';
            data.promotionAvailable = '';
            data.promotionMax = '';
            data.promotionFundPercent = '';
            data.promotionMembership = '';
            data.promotionInfo = '';
            data.overviewFstates = this.data.fstates
        } else {
            if (data.promotionTotal !== -1) {
                data.promotionTotal = data.promotionTotal / 100;
            }
            data.promotionAvailable = data.promotionAvailable / 100;
            data.promotionMax = data.promotionMax / 100;
        }

        const infoData = {
            'priceTable': 'Für manche Bundesländer soll es die Möglichkeit geben, dass die Teilnahmegebühren aus einem vorangegangenen Jahr gelten. Dies kann über dieses Feld eingestellt werden.',
            'promotionTotal': 'In diesem Feld wird die Höhe des gesamten Fördertopfes eingetragen. Sollte es sich um einen pauschalen Fördertopf handeln (bspw. Hessen), so muss nur die Checkbox aktiviert werden.',
            'promotionAvailable': 'In diesem Feld steht der noch verfügbare Betrag im Fördertopf. Änderungen müssen vorsichtig gemacht werden, da sich dieser Betrag mit jeder Kommunenanmeldung aktualisiert. ',
            'promotionPercent': 'Der prozentuale Wert beschreibt zu wie viel Prozent die Teilnahmegebühren gefördert werden.',
            'promotionFixed': 'Der maximale Förderbetrag ist der Wert zu dem die Teilnahmegebühren einer Kommune maximal gefördert werden können. Dieser Wert gilt pro Kommune, nicht pro Kommunenanmeldung.',
            'promotionMembership': 'Soll eine Kommune gefördert werden, wenn eine Mitgliedschaft vorhanden ist (z.B. AGFK), so wird hier der Prozentwert eingetragen zu wie viel die Teilnahmegebühren gefördert werden. Sollen alle Mitglieder pauschal gefördert werden (unanhängig von dem Fördertop), so muss nur die Checkbox aktiviert werden.',
            'active': 'Wenn ein Fördertopf aktiv ist, wird dieser auf der Webseite angezeigt und auch bei der Kommunenanmeldung berücksichtigt. Ist ein Fördertopf nicht aktiv, so wird er nur auf der Webseite angezeigt, aber nicht bei der Kommunenanmeldung beachtet.',
            'date': 'Dieses Feld zeigt das Datum an, wann das letzte Mal der Fördertopf verändert wurde. Dient nur zur Anzeige unter der Progressbar.',
            'description': 'Die Beschreibung wird auf der Webseite angezeigt.',
            'showProgressbar': 'Hier kann die Statusleiste eines Fördertopfes ein- und ausgeblendet werden.'
        }

        let checked = '';
        let disabled = '';
        let html = '<div class="form-horizontal promotion-fund">';
        html += '<div class="form-group"><label class="control-label">Land</label><div class="form-col"><select class="promotion-country sr_validate_notempty" id="promotion-country-' + data.promotionId +'"></select></div></div>';
        html += '<div class="form-group"><label class="control-label">Bundesland</label><div class="form-col"><select class="promotion-fstate sr_validate_notempty" id="promotion-fstate-' + data.promotionId +'"></select></div></div>';
        html += '<div class="form-group"><label class="control-label control-label-info">Preis Tabelle<div class="icon-info" data-container="body" data-toggle="popover" data-html="true" data-trigger="hover" data-placement="top" data-content="' + infoData.priceTable + '"></div></label><div class="form-col"><select class="promotion-price-table sr_validate_notempty" id="promotion-price-table-' + data.promotionId +'"></select></div></div>';

        let valueTotal = data.promotionTotal;
        if (data.promotionTotal === -1) {
            checked = ' checked';
            disabled = ' disabled=disabled';
            valueTotal = 0;
        }
        html += '<div class="form-group">';
        html += '<label class="control-label control-label-info">Fördertopf Höhe<div class="icon-info" data-container="body" data-toggle="popover" data-html="true" data-trigger="hover" data-placement="top" data-content="' + infoData.promotionTotal + '"></div></label>';
        html += '<div class="form-col">';
        html += '<input type="text" class="form-control promotion-total sr_validate_numbernotempty" id="promotion-total-' + data.promotionId +'" name="promotion-total-' + data.promotionId +'" value="' + valueTotal + '" ' + disabled + '>';
        html += '<div class="checkbox-wrapper">';
        html += '<input type="checkbox" class="promotion-total-general" id="promotion-total-general-' + data.promotionId +'" name="promotion-total-general-' + data.promotionId +'" ' + checked + '>';
        html += '<label for="promotion-total-general-' + data.promotionId +'" class="icon fa fa-check-square mt-3"></label><div class="checkbox-text">Pauschaler Fördertopf</div>';
        html += '</div>';
        html += '</div></div>';

        html += '<div class="form-group"><label class="control-label control-label-info">Fördertopf Restbetrag<div class="icon-info" data-container="body" data-toggle="popover" data-html="true" data-trigger="hover" data-placement="top" data-content="' + infoData.promotionAvailable + '"></div></label><div class="form-col"><input type="text" class="form-control promotion-available sr_validate_numbernotempty" id="promotion-available-' + data.promotionId +'" name="promotion-available-' + data.promotionId +'" value="' + data.promotionAvailable + '" ' + disabled + '></div></div>';
        html += '<div class="form-group"><label class="control-label control-label-info">Prozentuale Förderung<div class="icon-info" data-container="body" data-toggle="popover" data-html="true" data-trigger="hover" data-placement="top" data-content="' + infoData.promotionPercent + '"></div></label><div class="form-col"><input type="text" class="form-control promotion-percent sr_validate_numbernotempty" id="promotion-percent-' + data.promotionId +'" name="promotion-percent-' + data.promotionId +'"  value="' + data.promotionFundPercent + '"></div></div>';
        html += '<div class="form-group"><label class="control-label control-label-info">Maximale Förderung<div class="icon-info" data-container="body" data-toggle="popover" data-html="true" data-trigger="hover" data-placement="top" data-content="' + infoData.promotionFixed + '"></div></label><div class="form-col"><input type="text" class="form-control promotion-fix sr_validate_numbernotempty" id="promotion-fix-' + data.promotionId +'" name="promotion-fix-' + data.promotionId +'"  value="' + data.promotionMax + '"></div></div>';

        checked = '';
        disabled = '';
        let valueMembership = data.promotionMembership;
        if (data.promotionMembership === -1) {
            checked = ' checked';
            disabled = ' disabled=disabled';
            valueMembership = 0;
        }
        html += '<div class="form-group"><label class="control-label control-label-info">Förderung bei Mitgliedschaft<div class="icon-info" data-container="body" data-toggle="popover" data-html="true" data-trigger="hover" data-placement="top" data-content="' + infoData.promotionMembership + '"></div></label>';
        html += '<div class="form-col">';
        html += '<input type="text" class="form-control promotion-membership sr_validate_numbernotempty" id="promotion-membership-' + data.promotionId +'" name="promotion-membership-' + data.promotionId +'"  value="' + valueMembership + '" ' + disabled + '>';
        html += '<div class="checkbox-wrapper">';
        html += '<input type="checkbox" class="promotion-membership-general" id="promotion-membership-general-' + data.promotionId +'" name="promotion-membership-general-' + data.promotionId +'" ' + checked + '>';
        html += '<label for="promotion-membership-general-' + data.promotionId +'" class="icon fa fa-check-square mt-3"></label><div class="checkbox-text">Pauschaler Fördertopf</div>';
        html += '</div>';
        html += '</div></div>';

        checked = '';
        if (data.promotionActive) {
            checked = ' checked';
        }
        html += '<div class="form-group"><label class="control-label control-label-info">Aktiv<div class="icon-info" data-container="body" data-toggle="popover" data-html="true" data-trigger="hover" data-placement="top" data-content="' + infoData.active + '"></div></label><div class="form-col">';
        html += '<input type="checkbox" id="promotion-active-' + data.promotionId +'" name="promotion-active-' + data.promotionId +'" ' + checked + '>';
        html += '<label for="promotion-active-' + data.promotionId + '" class="icon fa fa-check-square mt-3"></label>';
        html += '</div></div>';

        checked = '';
        if (data.promotionProgressbar) {
            checked = ' checked';
        }
        html += '<div class="form-group"><label class="control-label control-label-info">Anzeige Statusleiste<div class="icon-info" data-container="body" data-toggle="popover" data-html="true" data-trigger="hover" data-placement="top" data-content="' + infoData.showProgressbar + '"></div></label><div class="form-col">';
        html += '<input type="checkbox" id="promotion-progressbar-' + data.promotionId +'" name="promotion-progressbar-' + data.promotionId +'" ' + checked + '>';
        html += '<label for="promotion-progressbar-' + data.promotionId + '" class="icon fa fa-check-square mt-3"></label>';
        html += '</div></div>';

        html += '<div class="form-group"><label class="control-label control-label-info">Letzter Stand<div class="icon-info" data-container="body" data-toggle="popover" data-html="true" data-trigger="hover" data-placement="top" data-content="' + infoData.date + '"></div></label><div class="form-col d-flex justify-between">';
        html += '<input type="text" class="form-control input-date d-inline-block datepicker sr_validate_notempty" name="promotion-last-date-' + data.promotionId +'" id="promotion-last-date-' + data.promotionId +'" placeholder="Datum" size="10" maxlength="10">';
        html += '<input type="text" class="form-control promotion-last-time sr_validate_notempty" name="promotion-last-time-' + data.promotionId +'" id="promotion-last-time-' + data.promotionId +'"  value="00:00:00" step="1">';
        html += '</div></div>';

        html += '<div class="form-group"><label class="control-label control-label-info">Beschreibung<div class="icon-info" data-container="body" data-toggle="popover" data-html="true" data-trigger="hover" data-placement="top" data-content="' + infoData.description + '"></div></label><div class="form-col w-70">';
        //html += '<textarea rows="20" id="promotion-textfield-' + data.promotionId +'" class="promotion-textfield" cols="50" name="promotion-textfield-' + data.promotionId +'">' + data.promotionInfo + '</textarea>';
        html += '<div id="promotion-textfield-' + data.promotionId +'" class="quillTextEditor promotion-textfield" data-sr-promotion-id="' + data.promotionId +'">' + data.promotionInfo + '</div>';
        html += '</div></div>';
        html += '</div>';
        html += '<div class="final-admin-buttons"><button type="button" class="deleteData btn" data-promotion-fund-id="' + data.promotionId +'">Daten löschen</button><button type="button" class="saveData" data-promotion-fund-id="' + data.promotionId +'">Daten speichern</button></div>';

        return html;
    }

    templatePromotionFragment(admin, item) {
        const promotionValueLeft = (item.promotionValueLeft / 100).toLocaleString(undefined, { minimumFractionDigits: 2 }) + ' €';
        const promotionValueTotal = (item.promotionValueTotal / 100).toLocaleString(undefined, { minimumFractionDigits: 2 }) + ' €';

        let html = '<div class="panel akkordeon-topic ' + item.promotionVisibility +'"  id="a-' + item.promotionFstate +'" data-sr-promotion-id="' + item.promotionId + '">';
        html += '<div class="akkordeon-topic-header">';
        html += '<a class="collapsed" role="button" data-parent="#akkordeon-wrapper" data-toggle="collapse" href="#a-' + item.promotionFstate +'-content" aria-expanded="false" aria-controls="a-' + item.promotionFstate +'-content">';
        html += '<div class="akkordeon-topic-link">';
        html += '<div class="state-name">' + item.promotionFstate +'</div>';
        html += '<div class="promotion-progressbar ' + item.promotionHideProgressBar +'">';
        html += '<div class="progress">';
        html += '<div class="progress-bar ' + item.promotionProgressbarHighlight +'" role="progressbar" aria-valuenow="' + item.promotionPercentLeft +'%" aria-valuemin="0" aria-valuemax="100" style="width:' + item.promotionPercentLeft +'%;">';
        html += '<span class="' + item.promotionProgressbarValueClass +'">' + item.promotionText +'</span>';
        html += '</div></div></div></div></a></div>';
        html += '<div class="akkordeon-topic-body collapse" id="a-' + item.promotionFstate +'-content">';
        html += '<div class="akkordeon-content">';
        html += '<div class="promotion-progressbar ' + item.promotionHideProgressBar +'">';
        html += '<div class="progress">';
        html += '<div class="progress-bar ' + item.promotionProgressbarHighlight +'" role="progressbar" aria-valuenow="' + item.promotionPercentLeft +'%" aria-valuemin="0" aria-valuemax="100" style="width:' + item.promotionPercentLeft +'%;">';
        html += '<span class="' + item.promotionProgressbarValueClass +'">' + item.promotionText +'</span>';
        html += '</div></div></div>';
        html += '<div class="promotion-values ' + item.promotionHideProgressBar +' ' + item.promotionHideValues +'">';
        html += '<div class="promotion-value-mobile">' + promotionValueLeft +' von ' + promotionValueTotal +' aufgebraucht</div>';
        html += '<div class="promotion-value-left">' + promotionValueLeft +' aufgebraucht</div>';
        html += '<div class="promotion-value-total">' + promotionValueTotal +' Fördersumme</div></div>';
        html += '<div class="promotion-current-status ' + item.promotionHideProgressBar +' ' + item.promotionHideValues +'">Stand: ' + item.promotionCurrentStatus +' Uhr</div>';
        html += '<div class="promotion-info-text">' + item.promotionInfo +'</div>';
        html += '<div class="more-link-wrapper"><a href="" class="more-link" data-sr-length="more">mehr lesen</a></div>';

        if (admin) {
            html += '<div class="admin-panel">' + this.templateForAdminButtons(item.promotionId, item.promotionFstate, item.promotionActive);
            html += '<div class="promotion-admin-fields">' + this.templatePromotionFund(item) + '</div></div>';
        }

        html += '</div></div></div>';

        return html;
    }

    templatePromotionFragmentNoBar(admin, item) {
        let html = '<div class="panel akkordeon-topic ' + item.promotionVisibility +'"  id="a-' + item.promotionFstate +'" data-sr-promotion-id="' + item.promotionId + '">';
        html += '<div class="akkordeon-topic-header">';
        html += '<a class="collapsed" role="button" data-parent="#akkordeon-wrapper" data-toggle="collapse" href="#a-' + item.promotionFstate +'-content" aria-expanded="false" aria-controls="a-' + item.promotionFstate +'-content">';
        html += '<div class="akkordeon-topic-link"><div class="state-name">' + item.promotionFstate +'</div></div></a></div>';
        html += '<div class="akkordeon-topic-body collapse" id="a-' + item.promotionFstate +'-content">';
        html += '<div class="akkordeon-content"><div class="promotion-info-text">' + item.promotionInfo +'</div>';
        html += '<div class="more-link-wrapper"><a href="" class="more-link" data-sr-length="more">mehr lesen</a></div>';

        if (admin) {
            html += '<div class="admin-panel">' + this.templateForAdminButtons(item.promotionId, item.promotionFstate);
            html += '<div class="promotion-admin-fields">' + this.templatePromotionFund(item) + '</div></div>';
        }
        html += '</div></div></div></div>';

        return html;
    }

    initializeQuillEditor() {
        const quillEditor = new QuillEditor();
        document.querySelectorAll('.promotion-fragment-admin').forEach(item => {
            const promotionFragmentId = item.getAttribute('data-sr-promotion-id');
            this.data.promotionValues.forEach((item, index) => {
                if (parseInt(promotionFragmentId) === item.promotionId) {
                    quillEditor.createEditor('promotion-textfield-' + item.promotionId, false, 0, item.promotionInfo);
                }
            });
        });
    }
}

window.PromotionOverview = PromotionOverview;
