class Utility {

    validateNotEmpty(inputElement, message, showMessageInBanner = false) {
        const thisParent = this;
        const feedbackElement = inputElement.parents('.sr_form_feedback_parent').find('.sr_form_feedback');

        let ident     = "<!--" + $(inputElement).attr("name") + "-->";
        let msg_ident = ident + message;

        if (!$(inputElement).val() || $(inputElement).val().trim() === "") {
            if (showMessageInBanner) {
                this.showErrorMessageInBanner(2000, message);
            } else {
                feedbackElement.html(msg_ident);
                inputElement.addClass("feedback-form");
                inputElement.off('keyup').on('keyup', () => thisParent.validateNotEmpty(inputElement, message));
                inputElement.off('change').on('change', () => thisParent.validateNotEmpty(inputElement, message));
            }
            return false;
        } else {
            if (feedbackElement.html() === msg_ident) {
                feedbackElement.html("");
            }
            inputElement.removeClass("feedback-form");

        }

        return true;
    }

    validateDateInFuture(inputElement, message, showMessageInBanner = false) {
        const thisParent = this;
        const feedbackElement = inputElement.parents('.sr_form_feedback_parent').find('.sr_form_feedback');

        let ident     = "<!--" + $(inputElement).attr("name") + "-->";
        let msg_ident = ident + message;

        let today = new Date();
        today.setHours(0,0,0);
        const splittedDate = $(inputElement).val().split('.');
        let enteredDate = new Date(splittedDate[2], splittedDate[1] - 1, splittedDate[0]);

        if (enteredDate > today) {
            if (showMessageInBanner) {
                this.showErrorMessageInBanner(2000, message);
            } else {
                feedbackElement.html(msg_ident);
                inputElement.addClass("feedback-form");
                inputElement.off('keyup').on('keyup', () => thisParent.validateNotEmpty(inputElement, message));
                inputElement.off('change').on('change', () => thisParent.validateNotEmpty(inputElement, message));
            }
            return false;
        } else {
            return true;
        }

    }

    validateRichTextEditorNotEmpty(inputElement, rteId, message, showMessageInBanner = false) {
        const thisParent = this;
        const feedbackElement = inputElement.parents('.sr_form_feedback_parent').find('.sr_form_feedback');

        let msg_ident = message;
        const quillEditor = new QuillEditor();
        const editor = quillEditor.getEditor(document.querySelector('#' + rteId));

        if (editor.getSemanticHTML() === "<p></p>") {
            if (showMessageInBanner) {
                this.showErrorMessageInBanner(2000, message);
            } else {
                feedbackElement.html(msg_ident);
                inputElement.addClass("feedback-form");

                editor.on('text-change', (delta, oldDelta, source) => {
                    thisParent.validateRichTextEditorNotEmpty(inputElement, rteId, message, showMessageInBanner);
                });
            }
            return false;
        } else {
            if (feedbackElement.html() === msg_ident) {
                feedbackElement.html("");
            }

        }

        return true;
    }

    validateCheckbox(inputElement, message, showMessageInBanner = false) {
        const thisParent = this;
        const feedbackElement = inputElement.parents('.sr_form_feedback_parent').find('.sr_form_feedback');

        let ident     = "<!--" + $(inputElement).attr("name") + "-->";
        let msg_ident = ident + message;

        if (!inputElement.is(":checked")) {
            if (showMessageInBanner) {
                this.showErrorMessageInBanner(2000, message);
            } else {
                feedbackElement.html(msg_ident);
                inputElement.addClass("feedback-form");
                inputElement.off('change').on('change', () => thisParent.validateCheckbox(inputElement, message));
                inputElement.off('change').on('change', () => thisParent.validateCheckbox(inputElement, message));
            }
            return false;
        } else {
            if (feedbackElement.html() === msg_ident) {
                feedbackElement.html("");
            }
        }

        return true;
    }

    showErrorMessageInBanner(timeout, text) {
        $('#sr-error-message-text').html(text);
        $('#sr-error-modal').modal('show');

        setTimeout(function () {
            $('#sr-error-modal').modal('hide');
        }, timeout);
    }

    showPermanentErrorModal(modalElement, textElement, text) {
        $(textElement).html(text);
        $(modalElement).modal('show');
    }

    getUrlParameter(sParam) {
        let sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return false;
    };

}

window.Utility = Utility;
