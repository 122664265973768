class BlogEditor {
    constructor(baseUrl, apiUrl, apiKey, translations, blogTextValue='') {
        const utility = new Utility();
        let submit = document.querySelector('#blogpost-submit');
        let form = document.querySelector('#blogForm');
        let blogSaveUrl = apiUrl + "blog/user/save?sr_api_key=" + apiKey;

        let blogTitel = document.querySelector('#post_title');
        let blogTitelCountText = document.querySelector('#post_title_count');
        let blogTextElement = document.querySelector('#post_text');
        let blogImage = document.querySelector('#post_image');
        let blogExtraImages = document.querySelector('.blog-extra-image-edit-box');
        let blogMainImage = document.querySelector('#main-image-preview');

        let urlParamAction = srGetURLVar('sr_action');
        let urlParamPostId = srGetURLVar('sr_post_id');

        this.mainImageHandler(blogImage);
        this.handleWordCount(blogTitel, blogTitelCountText)
        this.initializeRTE(blogTextElement, blogTextValue);
        this.handleExtraImages(blogExtraImages);

        submit.addEventListener('click', (event) => {
            event.preventDefault();
            const blogTitelValidation = utility.validateNotEmpty($('#post_title'), translations.mandatoryField);

            let blogIntroImageValidation = true;
            if (blogMainImage.getAttribute('src').length !== 0) {
                blogIntroImageValidation = true;
            } else {
                blogIntroImageValidation = utility.validateNotEmpty($('#post_image'), translations.mandatoryField);
            }

            const blogTextValidation = utility.validateRichTextEditorNotEmpty($('#post_text'), 'post_text', translations.mandatoryField);
            const blogPrivacyValidation = utility.validateCheckbox($('#confirmPrivacy'), translations.mandatoryField);

            if (blogTitelValidation && blogIntroImageValidation && blogTextValidation && blogPrivacyValidation) {
                this.submitBlogPost(baseUrl, blogSaveUrl, blogTitel, blogTextElement, blogImage);
            } else {
                this.showErrorModal({message: translations.mandatoryField});
            }
        });

        if (urlParamAction === "edit" && urlParamPostId !== null) {
            this.implementMainImageInEditor(apiUrl, apiKey, urlParamPostId);
            this.implementExtraImageInEditor(apiUrl, apiKey, urlParamPostId);
            document.querySelector('#picture-privacy').classList.remove('hide');
        }
    }

    submitBlogPost(baseUrl, url, blogTitle, blogText, blogImage) {
        let blogImageData = document.querySelector('#main-image-preview').getAttribute('src');
        let blogExtraImages = document.querySelectorAll('.extra-image-item');
        let blogPostId = document.querySelector('#post_id');
        let blogDate = document.querySelector('#post_date');
        let blogTime = document.querySelector('#post_time');
        const utility = new Utility();
        const userId = utility.getUrlParameter('sr_user_id');
        const quillEditor = new QuillEditor();
        const blogTextEditor = document.querySelector('#post_text');

        let extraImageData = [];
        blogExtraImages.forEach((item) => {
            let file = item.querySelector('.hidden-file-select');
            let fileBase64 = item.querySelector('.extra-image-preview').getAttribute('src');
            let imageTitle = item.querySelector('.input-field-blog');

            if (file) {
                let fileData = {
                    'imageTitle': imageTitle.value,
                    'name': file.files[0] ? file.files[0].name : null,
                    'type': file.files[0] ? file.files[0].type : null,
                    'size': file.files[0] ? file.files[0].size : null,
                    'data': fileBase64 ? fileBase64 : null
                }
                extraImageData.push(fileData);
            }

        })

        let data = {
            'postId': blogPostId.value,
            'postTitel': blogTitle.value,
            'postDate': blogDate.value,
            'postTime': blogTime.value,
            'postText': quillEditor.getEditor(blogTextEditor).getSemanticHTML(),
            'postImage': {
                'name': blogImage.files[0] ? blogImage.files[0].name : null,
                'type': blogImage.files[0] ? blogImage.files[0].type : null,
                'size': blogImage.files[0] ? blogImage.files[0].size : null,
                'data': blogImageData ? blogImageData : null
            },
            'postExtraImage': extraImageData
        }

        if (userId) {
            data.userId = userId;
        }

        $.ajax({
            url: url,
            method: 'POST',
            data: data,
            success: (response) => {
                if (response.status === 1) {
                    window.location.href = baseUrl + "specials/blog?sr_post_id=" + response.data.postId;
                } else {
                    this.showErrorModal(response);
                }
            }
        });
    }

    initializeRTE(element, blogTextValue) {
        const quillEditor = new QuillEditor();
        quillEditor.createEditor(element.getAttribute('id'), false, 0, blogTextValue);
    }

    handleWordCount(inputElement, outputElement) {
        let chars = inputElement.value.length;
        const maxLength = inputElement.getAttribute('maxlength');
        outputElement.innerHTML = maxLength - chars;

        inputElement.addEventListener('keyup', () => {
            let charCount = inputElement.value.length;
            let maximum = inputElement.getAttribute('maxlength');
            outputElement.innerHTML = maximum - charCount;

        });
    }

    mainImageHandler(element) {
        //added for integration cropping-tool-modal
        let mainImageUploadButton = document.querySelector('#main-image-upload-button');

        element.addEventListener('change', () => {
            let mainImageReplaceText = document.querySelector('#replace-image-text');
            let mainImageDeleteIcon = document.querySelector('#icon-delete-main');
            let mainImagePreview = document.querySelector('#main-image-preview');
            let mainImageWrapperLeft = document.querySelector('#main-image-wrapper-left');

            mainImageWrapperLeft.classList.remove('hidden');
            //mainImageWrapperRight.classList.add('col-sm-offset-3');
            mainImageDeleteIcon.classList.remove('hidden');
            mainImageReplaceText.classList.remove('hidden');
            mainImagePreview.parentElement.classList.add('main-image-spacing');

            this.srPictureSelected(element, "16/9", mainImagePreview, mainImageDeleteIcon, false);
        })

        mainImageUploadButton.addEventListener('click', () => element.click());

    }

    handleExtraImages(blogExtraImages) {
        let selectExtraImageButton = document.querySelectorAll('.select-extra-image-btn');
        let hiddenFileSelect = blogExtraImages.querySelectorAll('.hidden-file-select');

        selectExtraImageButton.forEach((item) => {
            $(item).off('click').on('click', () => {
                item.parentElement.querySelector('.hidden-file-select').click();
            });
        });

        hiddenFileSelect.forEach((item) => {
            $(item).off('change').on('change', () => {
                let extraImageDeleteIcon = item.parentNode.parentNode.querySelector('.delete-blog-image');
                let extraImagePreview = item.parentNode.querySelector('.extra-image-preview');
                let counter = item.parentNode.dataset.srExtraImage;
                let extraImageFileInput = document.querySelector('#post_extra_image_' + counter);
                let extraImageUploadButton = item.parentNode.querySelector('.select-extra-image-btn');
                let hiddenFileSelectRecalculated = blogExtraImages.querySelectorAll('.hidden-file-select');
                let extraImageTitle = item.parentNode.parentNode.querySelector('.extra-image-title');
                let extraImageTitleField = item.parentNode.parentNode.querySelector('.input-field-blog');
                let extraImageTitleCount = item.parentNode.parentNode.querySelector('.post_title_count');

                this.srPictureSelected(extraImageFileInput, "", extraImagePreview, extraImageDeleteIcon, true);
                extraImageTitle.classList.remove('hidden');
                extraImageUploadButton.classList.add('hidden');

                if (hiddenFileSelectRecalculated.length < 9) {
                    $(blogExtraImages).append(this.addExtraImageTemplate(selectExtraImageButton.length))
                }

                this.handleWordCount(extraImageTitleField, extraImageTitleCount)
                this.handleExtraImages(blogExtraImages);
            });
        });
    }

    addExtraImageTemplate(counter) {
        let html = '<div class="extra-image-item col-xs-12 col-md-12">';

        html += '<div class="col-xs-12 col-sm-5 col-md-5 extra-image-item-wrapper" data-sr-extra-image=' + counter + '>';
        html += '<img src="" class="extra-image-preview hidden">';
        html += '<button class="btn btn-block btn-default btn-width mb-10 mr-15 select-extra-image-btn" id="upload-button-post_extra_image_' + counter + '" type="button">Datei auswählen</button>';
        html += '<input type="file" name="post_extra_image_' + counter + '"  id="post_extra_image_' + counter + '" class="hidden-file-select"/>';
        html += '<label class="hidden form-control upload-name" id="upload-text-post_extra_image_' + counter + '"></label>';
        html += '</div>';

        html += '<div class="col-xs-12 col-sm-7 col-md-7 px-xs-0">';
        html += '<div class="extra-image-title hidden">';
        html += '<input class="input-field-blog " type="text" id="post_image_extra_' + counter + '" maxlength="100" name="post_image_extra_' + counter + '" maxlength="240" placeholder="Bildtitel (optional)"/>';
        html += '<p class="mt-5"><small>Noch </small><small class="post_title_count"></small><small> Zeichen</small></p>';
        html += '</div></div>';

        //html += '<div class="col-md-12">';

        html += '<div class="delete-blog-image hidden">';
        html += '<a class="icon-trash-alt delete-image">Bild löschen</a>';
        html += '</div>';

        //html += '</div>';
        html += '</div>';
        html += '</div>';

        return html;
    }

    refreshExtraImageCounter() {
        let extraImageItems = document.querySelectorAll('.extra-image-item');

        extraImageItems.forEach((item, index) => {
            item.querySelector('.extra-image-item-wrapper').setAttribute('data-sr-extra-image', index);
            item.querySelector('.select-extra-image-btn').setAttribute('id', 'upload-button-post_extra_image_' + index);
            item.querySelector('.hidden-file-select').setAttribute('id', 'post_extra_image_' + index);
            item.querySelector('.hidden-file-select').setAttribute('name', 'post_extra_image_' + index);
            item.querySelector('.upload-name').setAttribute('id', 'upload-text-post_extra_image_' + index);
            item.querySelector('.input-field-blog').setAttribute('id', 'post_image_extra_' + index);
            item.querySelector('.input-field-blog').setAttribute('name', 'post_image_extra_' + index);
            //item.querySelector('.extra-title-count').setAttribute('id', 'extra_title_count_' + index);
        });
    }

    srPictureSelected(elementId, aspectRatio, previewElement, deleteElement, fullDelete) {
        const file = elementId.files[0];

        // Create Modal Dialog and get image in dialog
        let cropper_modal = document.querySelector('#sr-cropping-modal');
        let image = document.querySelector('#pic_cropped');

        //previewElement.classList.remove('hidden');

        // read loaded file and show modal dialog of cropping tool
        let reader = new FileReader();
        reader.onload = function (e) {
            $(image).attr('src', e.target.result).change();
        };
        $(cropper_modal).modal('show');
        reader.readAsDataURL(file);

        // if modal dialog is shown, initialize cropping tool
        $(cropper_modal).off('shown.bs.modal').on('shown.bs.modal', function () {
            let cropperData;

            let cropSubmitButton = document.querySelector('#crop');
            if (aspectRatio === "16/9") {
                cropperData = {
                    autoCropArea: 1,
                    aspectRatio: 16 / 9,
                    viewMode: 3,
                    preview: '.preview'
                }
            } else {
                cropperData = {
                    autoCropArea: 1,
                    aspectRatio: 0,
                    viewMode: 0,
                    preview: '.preview'
                }
            }

            $(image).cropper(cropperData);

            let cropper = $(image).data('cropper');

            // on click on confirm button data from the cropping tool will be saved and covert to bs64 file - modal dialog will be closed
            $(cropSubmitButton).off('click').on('click', function () {

                let canvas = cropper.getCroppedCanvas({
                    height: 768,
                    width: 768
                });

                canvas.toBlob(function (blob) {

                    reader.readAsDataURL(blob);

                    reader.onloadend = function () {
                        previewElement.setAttribute('src', reader.result);
                    }
                });

                $(cropper_modal).modal('hide');

                // show privacy-checkbox and disable save-button
                $("#picture-privacy").removeClass("hide");
                previewElement.classList.remove('hidden');
                deleteElement.classList.remove('hidden');
            });
        }).on('hidden.bs.modal', function () {
            // if modal dialog hide the cropped image will be destroyed so you can open the cropper with new image
            $(image).cropper('destroy');
        });

        this.deleteImage(previewElement, deleteElement, fullDelete);
    }

    deleteImage(imageElement, deleteElement, fullDelete = false) {
        const blogEditor = this;
        $(deleteElement).on('click', function () {

            if (fullDelete === true) {
                const blogExtraImages = document.querySelector('.blog-extra-image-edit-box');
                const extraImagesAmount = document.querySelectorAll('.extra-image-item').length;
                const lastImageItem = blogExtraImages.lastElementChild.querySelector('.extra-image-preview ');

                deleteElement.parentNode.remove();
                blogEditor.refreshExtraImageCounter();
                if (extraImagesAmount === 9 && lastImageItem.getAttribute('src') !== "") {
                    $(blogExtraImages).append(blogEditor.addExtraImageTemplate(8));
                    blogEditor.handleExtraImages(blogExtraImages);
                }

            } else {
                const mainImageReplaceText = document.querySelector('#replace-image-text');
                const mainImageWrapperLeft = document.querySelector('#main-image-wrapper-left');
                //const mainImageInput = document.querySelector('#post_image');

                mainImageWrapperLeft.classList.add('hidden');
                mainImageReplaceText.classList.add('hidden');
                imageElement.setAttribute("src", "");
                //mainImageInput.value = "";
                imageElement.classList.add('hidden');
                deleteElement.classList.add('hidden');
                imageElement.parentNode.classList.remove('main-image-spacing');
            }
        });
    }

    implementMainImageInEditor(apiUrl, apiKey, postId) {
        let blogGetMainImageUrl = apiUrl + "blog/user/mainimage/" + postId + "?sr_api_key=" + apiKey;
        $.ajax({
            url: blogGetMainImageUrl,
            method: "GET",
            success: (response) => {
                if (response.status === 1) {
                    let imageElement = document.querySelector('#main-image-preview');
                    let deleteElement = document.querySelector('.delete-main-image.delete-blog-image');
                    let mainImageReplaceText = document.querySelector('#replace-image-text');
                    const mainImageWrapperLeft = document.querySelector('#main-image-wrapper-left');
                    mainImageReplaceText.classList.remove('hidden');
                    imageElement.parentElement.classList.add('main-image-spacing');

                    imageElement.setAttribute('src', 'data: image/png;base64,' + response.urlpath);
                    imageElement.classList.remove('hidden');
                    mainImageWrapperLeft.classList.remove('hidden');
                    deleteElement.classList.remove('hidden');
                    this.deleteImage(imageElement, deleteElement)
                } else {
                    this.showErrorModal(response);
                }

            }
        })
    }

    implementExtraImageInEditor(apiUrl, apiKey, postId) {
        let blogGetExtraImageUrl = apiUrl + "blog/user/extraimage/" + postId + "?sr_api_key=" + apiKey;
        let blogEditor = this;
        $.ajax({
            url: blogGetExtraImageUrl,
            method: "GET",
            success: (response) => {
                if (response.status === 1) {
                    let imageBox = document.querySelector('.blog-extra-image-edit-box');
                    imageBox.innerHTML = '';
                    let counter = 0;
                    response.data.forEach((item) => {
                        $(imageBox).append(this.addExtraImageTemplate(counter));

                        let imageItems = document.querySelectorAll('.extra-image-item');
                        let image = imageItems[counter].querySelector('.extra-image-preview');
                        let imageUploadButton = imageItems[counter].querySelector('.select-extra-image-btn');
                        let imageDeleteButton = imageItems[counter].querySelector('.delete-blog-image');
                        let imageTitle = imageItems[counter].querySelector('.input-field-blog');
                        let imageTitleWrapper = imageItems[counter].querySelector('.extra-image-title');
                        let imageTitleCount = imageItems[counter].querySelector('.post_title_count');

                        image.setAttribute('src', 'data: image/png;base64,' + item.urlpath);
                        imageTitle.value = item.imageTitle;
                        image.classList.remove('hidden');
                        imageUploadButton.classList.add('hidden');
                        imageDeleteButton.classList.remove('hidden');
                        imageTitleWrapper.classList.remove('hidden');

                        this.handleWordCount(imageTitle, imageTitleCount);
                        this.deleteImage(image, imageDeleteButton, true)

                        counter++;
                    })

                    if(counter < 9) {
                        $(imageBox).append(this.addExtraImageTemplate(counter));

                        this.handleExtraImages(imageBox)
                    }
                } else {
                    this.showErrorModal(response);
                }
            }
        });
    }

    showErrorModal(response) {
        let errorModal = document.querySelector('#sr-modal-blog-error-message');
        let errorModalText = document.querySelector('#sr-modal-blog-error-message .message')
        $(errorModal).modal('show');
        errorModalText.innerHTML = response.message;
        setTimeout(function() {
            $(errorModal).modal('hide');
        }, 2000);
    }
}

window.BlogEditor = BlogEditor;
