class Wrw_table {
    constructor(apiUrl, apiKey, baseUrl) {
        const self = this;
        const amountOfResultsPerSite = document.querySelectorAll('#numberOfResults div');
        let wrwTable = $('#sr-wrw-table').DataTable({
            "responsive": false,
            "info": false,
            "order": [[ 0, "asc" ]],
            "paging": true,
            "pagingType": "simple_numbers",
            "lengthChange": true,
            "lengthMenu": [[10, 20, 50, -1], [10, 20, 50, '{_"Alle"}']],
            "iDisplayLength": 10,
            "columnDefs": [{
                "targets": [12, 13],
                "orderable": false
            },
            {
                "targets": [3, 4, 5, 6, 7, 8, 9, 10],
                "className": "col-merch"
            },
            {
                "targets": [10],
                "className": "col-rng-select"
            }],
            ajax: {
                url: apiUrl + 'cities/wrw_data/load?sr_api_key=' + apiKey + '&XDEBUG_SESSION=IDEA',
                type: 'POST'
            },
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            "processing": true,
            serverSide: true,
            columns: [
                {data: "name"},
                {data: "start"},
                {data: "regdate"},
                {data: "flyer"},
                {data: "poster"},
                {data: "merch1"},
                {data: "merch3"},
                {data: "merch4"},
                {data: "merch6"},
                {data: "merch7"},
                {data: "merch8"},
                {data: "rng"},
                {data: "comment"},
                {data: "empty"},
            ],
            // optional loading settings
            "fnPreDrawCallback":function(){
                $("#loading").show();
            },
            "language": {
                "lengthMenu": '{_"Einträge pro Seite: _MENU_"}',
                "processing": '<div class="spinner"><img src="' + baseUrl + '/images/loading.svg"></div>',
                "zeroRecords": 'Keine Einträge gefunden',
                "info": '{_"Seite _PAGE_ von _PAGES_"}',
                "infoEmpty": 'Keine Einträge gefunden',
                "infoFiltered": '{_"(gefiltert aus _MAX_)"}',
                "decimal": ".",
                "thousands": ",",
                "search":         '{_"Suchen:"}',
                oPaginate: {
                    sNext: '<svg class="pagination-icon" viewBox="0 0 100 100"><path d="M 10,50 L 60,100 L 62,98 L 15,50  L 62,2 L 60,0 Z" class="arrow" transform="translate(100, 100) rotate(180) "></path></svg>',
                    sPrevious: '<svg class="pagination-icon" viewBox="0 0 100 100"><path d="M 10,50 L 60,100 L 62,98 L 15,50  L 62,2 L 60,0 Z" class="arrow"></path></svg>',
                    sFirst: '<i class="fa fa-step-backward"></i>',
                    sLast: '<i class="fa fa-step-forward"></i>'
                }
            },
            "scrollX": false,
        }).on( 'draw', function () {
            self.initializeSelect2($('.select-options'));
            self.initializeEventListener(apiUrl, apiKey);
            // remove search field from select
            $('.select-options').select2({
                minimumResultsForSearch: -1
            });
            // popover for edit button
            //make popovers also work with dark-bg
            $(".dark-bg [data-toggle='popover'], .dark-bg-popover [data-toggle='popover']").popover('destroy');
            $(".dark-bg [data-toggle='popover'], .dark-bg-popover [data-toggle='popover']").popover({
                html: true,
                template: '<div class="popover dark-bg"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>'
            });
        });

        $('#wrw-search').keyup(function(e) {
            if (typeof e == "undefined" || e.keyCode == 13) {
                wrwTable.search($(this).val()).draw();
            }
        });

        amountOfResultsPerSite.forEach(item => {
            item.addEventListener('click', (event) => {

                amountOfResultsPerSite.forEach(site => {
                    site.classList.remove('active');
                });

                item.classList.add('active');
                wrwTable.page.len(item.getAttribute('data-number')).draw();
            });
        });
    }

    initializeEventListener(apiUrl, apiKey) {
        this.checkboxEventListener(apiUrl, apiKey);
        this.dropDownEventListener(apiUrl, apiKey);
    }

    initializeSelect2(element) {
        element.select2({});
    }

    checkboxEventListener(apiUrl, apiKey) {
        const tableItem = $('.checkbox-wrapper label');
        const self = this;
        tableItem.off('click').on('click', function() {
            const inputElement = $(this).parent().find('input');

            const cityId = inputElement.attr('data-sr-city-id');
            const elementName = inputElement.attr('name');
            let valueChecked;

            // valueChecked muss umgekehrt sein, da der EventListener den Check nicht schnell genug verarbeitet
            if(inputElement.is(':checked')) {
                valueChecked = "";
            } else {
                valueChecked = "on";
            }

            self.saveElementAjaxCall(apiUrl, apiKey, cityId, elementName, valueChecked)

        });
    }

    dropDownEventListener(apiUrl, apiKey) {
        const dropdownItem = $('.select-options');
        const self = this;

        dropdownItem.on('select2:select', (event) => {
           const choosenValue = event.params.data.id;
           const cityId = $(event.currentTarget).attr('data-sr-city-id');
           self.saveElementAjaxCall(apiUrl, apiKey, cityId, 'sr_creg_admin_fee_options', choosenValue);
        });
    }

    saveElementAjaxCall(apiUrl, apiKey, cityId, elementName, value) {
        const utility = new Utility();
        $.ajax({
            url: apiUrl + "cities/wrw_data/setOption?sr_api_key=" + apiKey + "&XDEBUG_SESSION=IDEA",
            type: 'POST',
            data: {
                'cityId': cityId,
                'elementName': elementName,
                'value': value
            },
            success: (response) => {
                if (response.status === 1) {
                    const dataTables = $('#sr-wrw-table').DataTable();
                    dataTables.draw('page');
                } else {
                    utility.showErrorMessageInBanner(3000, response.message);
                }
            }
        });
    }
}

window.Wrw_table = Wrw_table;
