class CityAdministraionEvents {
    constructor(apiUrl, apiKey) {
        this.apiUrl = apiUrl;
        this.apiKey = apiKey;
    }
    /************************************************************/
    /*                  Create Dates                            */
    /************************************************************/

    addDate(type = '') {
        const quillEditor = new QuillEditor();
        let eventEditor = quillEditor.getEditor(document.querySelector('#sr_event_text'));

        const events = this;
        let data = {
            event_name: $('input[name="sr_date_title"]').val(),
            event_date: $('input[name="sr_date_date"]').val(),
            city_id: $('input[name="sr_city_id"]').val(),
            event_time: $('input[name="sr_date_time"]').val(),
            event_text: eventEditor.getSemanticHTML(),
            event_id: $('#sr-add-extra-date').attr('data-sr-event'),
            type: type,
            fstate: $('input[name="sr_fstate"]').val()
        }

        let text = eventEditor.getSemanticHTML();
        text = text.replaceAll("&nbsp;", "");
        text = text.replace(/<\/?[^>]+(>|$)/g, "");

        if (
            $('input[name="sr_date_title"]').val() === '' ||
            $('input[name="sr_date_date"]').val() === '' ||
            $('input[name="sr_date_time"]').val() === ''

        ) {
            events.showMessage('error', "Bitte füllen Sie Titel, Datum und Uhrzeit aus.");
        } else if (text.length > 500) {
            events.showMessage('error', "Ihr Text überschreitet die maximale Länge von 500 Zeichen");
        }
        else {
            if ($('#sr-add-extra-date').attr('data-sr-event') !== "" && typeof $('#sr-add-extra-date').attr('data-sr-event') !== "undefined") {

                $.ajax({
                    type: 'POST',
                    cache: false,
                    crossDomain: true,
                    xhrFields: {
                        withCredentials: true
                    },
                    url: this.apiUrl + 'cityinfos/updateEvent?sr_api_key=' + this.apiKey,
                    data: data,
                    success: function(data) {
                        events.getEvents(type);
                        events.clearFields();
                        events.showMessage('success', data.message);
                        events.changeEventBtn("add");
                    }
                });
            } else {
                $.ajax({
                    type: 'POST',
                    cache: false,
                    crossDomain: true,
                    xhrFields: {
                        withCredentials: true
                    },
                    url: this.apiUrl + 'cityinfos/date_add?sr_api_key=' + this.apiKey,
                    data: data,
                    success: function(data) {
                        events.getEvents(type);
                        events.clearFields();
                        events.showMessage('success', data.message);
                    }
                });
            }

        }
    }

    printEvent(data, type) {
        const events = this;
        $('#sr-extra-date-infos').html('');

        $(data.events).each((index, item) => {
            let html = '';
            html += "<div class='event-item'>";
            html += "<div class='event-header'>"
            html += "<div class='event-date'><div class='event-date-text'>" + item.event_date + "</div><div class='event-time-text'>" + item.event_time + "</div></div>";
            html += "<div class='icons'>";
            html += "<div class='delete-event icon-trash-alt ' data-sr-event='" + item.event_id + "'></div>";
            html += "<div class='update-event icon-edit ' data-sr-event='" + item.event_id + "'></div>";
            html += "</div>";
            html += "</div>";
            html += "<div class='event-title'>" + item.event_name + "</div>";
            html += "<div class='event-text'>" + item.event_text + "</div>";
            html += "</div>";
            $('#sr-extra-date-infos').append(html);

        });

        $('.delete-event').each((index, item) => {
            $(item).off('click').on('click', function() {
                events.srDeleteDateItem($(item).data('sr-event'), type);
            });
        });

        $('.update-event').each((index, item) => {
            $(item).off('click').on('click', function() {
                events.setValuesToUpdateEvent($(item).data('sr-event'));
            })
        })
    }

    getEvents(type = '') {
        const events = this;

        let data = {
            city_id: $('input[name="sr_city_id"]').val(),
            type: type,
            fstate: $('input[name="sr_fstate"]').val()
        }

        $.ajax({
            type: 'POST',
            cache: false,
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            url: this.apiUrl + 'cityinfos/getEvents?sr_api_key=' + this.apiKey,
            data: data,
            success: function(data) {
                events.printEvent(data, type);
            }
        });
    }

    srDeleteDateItem(id, type)
    {
        const events = this;
        // delete entry
        let data = {
            event_id: id,
            city_id: $('input[name="sr_city_id"]').val(),
        }
        $("#sr-modal-event-delete").modal("show");

        $('#eventDeleteConfirmButton').off('click').on('click', () => {
            $.ajax({
                type: 'POST',
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                },
                url: this.apiUrl + 'cityinfos/date_delete?sr_api_key=' + this.apiKey,
                data: data,
                success: function(data) {
                    events.showMessage('success', "Der Eintrag wurde erfolgreich gelöscht");

                    events.getEvents(type);
                }
            });
        });
    }

    clearFields() {
        const quillEditor = new QuillEditor();
        let eventEditor = quillEditor.getEditor(document.querySelector('#sr_event_text'));

        $('input[name="sr_date_title"]').val('');
        $('input[name="sr_date_date"]').val('');
        $('input[name="sr_date_time"]').val('');
        eventEditor.deleteText(0, eventEditor.getLength());
    }

    showMessage(type, message) {
        let modal = '';
        let modalText = ''
        if (type === 'success') {
            modal = $("#sr-modal-success");
            modalText = $("#sr-modal-success-message-text");
        } else {
            modal = $("#sr-missing-field");
            modalText = $("#sr-missing-field-message-text");
        }

        $(modalText).html('');
        $(modalText).append("<span>" + message + "</span>");
        $(modal).modal("show");
        setTimeout(function () {
            $(modal).modal('hide');
        }, 2000);
    }

    setValuesToUpdateEvent(id) {
        const events = this;

        $.ajax({
            type: 'GET',
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            url: this.apiUrl + 'cityinfos/getEvents/' + id + '?sr_api_key=' + this.apiKey,
            success: function(data) {
                const quillEditor = new QuillEditor();
                let updateButton = $('#sr-cancel-event-update');
                //let addButton = $('#sr-add-newsfeed-entry');

                $('input[name="sr_date_title"]').val(data[0].event_name);
                $('input[name="sr_date_date"]').val(data[0].event_date);
                $('input[name="sr_date_time"]').val(data[0].event_time);
                quillEditor.createEditor('sr_event_text', true, 500, data[0].event_text);

                updateButton.removeClass('hide');

                updateButton.off('click').on('click', function() {
                    let eventEditor = quillEditor.getEditor(document.querySelector('#sr_event_text'));

                    $('input[name="sr_date_title"]').val('');
                    $('input[name="sr_date_time"]').val('');
                    $('input[name="sr_date_date"]').val('');

                    eventEditor.deleteText(0, eventEditor.getLength());
                    events.changeEventBtn("add");
                });
                events.changeEventBtn("update", data[0].id);
            }
        });
    }

    changeEventBtn(type, id = "") {
        let addButton = $('#sr-add-extra-date');
        let updateButton = $('#sr-cancel-event-update');

        if (type === "update") {
            updateButton.removeClass('hide');
            addButton.html("Speichern");
            addButton.attr('data-sr-event', id);
        } else if (type === "add") {
            updateButton.addClass('hide');
            addButton.html("Termin hinzufügen");
            addButton.attr('data-sr-event', "");
        }
    }
}

window.CityAdministraionEvents = CityAdministraionEvents;
