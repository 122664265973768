class DashboardTeams {
    constructor(baseUrl, apiUrl, apiKey) {
        this.baseUrl = baseUrl;
        this.apiUrl = apiUrl;
        this.apiKey = apiKey;
        this.getChangeNameUrl = this.apiUrl + "teams/newAndNameChangedTeams?sr_api_key=" + apiKey + "&XDEBUG_SESSION=IDEA";

        let amountOfResultsPerSite = document.querySelectorAll('.numberOfResults .siteNumber');

        let dashboardTeams = this;

        let tableDesktop = $('#dashboard-teams-table').DataTable({
            //"dom": '<<t>>', // show only table, no pagination and no filter
            "autoWidth": false,
            "info": false,
            "order": [[0, "asc"]],
            "lengthChange": false,
            "lengthMenu": [[5, 10, 20, -1], [5, 10, 20, "Alle"]],  //[[5, 10, 20, -1], [5, 10, 20, "{_"Alle"}"]],
            "pagingType": "simple_numbers",
            "iDisplayLength": 5,
            "searching": false,
            'columnDefs':  [
                {
                    "targets": [ 0 ],
                    "width": "50%",
                    "className": "teamname"
                },
                {
                    'targets': [1],
                    "width": "30%",
                    "className": "category"
                },
                {
                    'targets': [2],
                    "width": "20%",
                    "className": "action",
                    'orderable': false,
                },

            ],
            ajax: {
                url: this.getChangeNameUrl,
                type: "GET",
                cache:  false,
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                }
            },
            columns: [
                {data: "teamname_html"},
                {data: "specialcategory"},
                {data: "action"}
            ],
            "language": {
                "loadingRecords": "&nbsp;",
                "processing": '<div class="spinner"><img src="{BASE_URL}images/loading.svg"></div>',
                "lengthMenu": "Einträge pro Seite: _MENU_",
                "zeroRecords": '<p style="padding-top:40px; padding-bottom:40px; font-weight:bold">Keine Einträge gefunden</p>',
                "info": "Seite _PAGE_ von _PAGES_",
                "infoEmpty": "Keine Einträge gefunden",
                "infoFiltered": "(gefiltert aus _MAX_)",
                "decimal": ".",
                "thousands": ",",
                oPaginate: {
                    sNext: '<svg class="pagination-icon" viewBox="0 0 100 100"><path d="M 10,50 L 60,100 L 62,98 L 15,50  L 62,2 L 60,0 Z" class="arrow" transform="translate(100, 100) rotate(180) "></path></svg>',
                    sPrevious: '<svg class="pagination-icon" viewBox="0 0 100 100"><path d="M 10,50 L 60,100 L 62,98 L 15,50  L 62,2 L 60,0 Z" class="arrow"></path></svg>',
                    sFirst: '<i class="fa fa-step-backward"></i>',
                    sLast: '<i class="fa fa-step-forward"></i>'
                }
            }
        }).on('draw.dt', function() {
            dashboardTeams.eventListenerActionIcons(tableDesktop);
        });

        amountOfResultsPerSite.forEach(item => {
           item.addEventListener('click', (event) => {

               amountOfResultsPerSite.forEach(site => {
                  site.classList.remove('active');
               });

               item.classList.add('active');
               tableDesktop.page.len(item.getAttribute('data-number')).draw();
           });
        });

    }

    eventListenerActionIcons() {
        let editBtn = document.querySelectorAll('.action-icons.edit');

        editBtn.forEach(item => {

            item.addEventListener('click', (event) => {
                let postId = event.originalTarget.dataset.srPostId;
                window.location = this.baseUrl + "user/blog?L=0&sr_action=edit&sr_post_id=" + postId;
            });
        })

    }

}

window.DashboardTeams = DashboardTeams;
