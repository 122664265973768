/**
 * This class contains the methods which are important to make the form interactive
 */

class CitySignupForm {
    constructor(data, translationData) {
        this.extraHtml = data.extraHtml;
        this.subExtraHtml = data.subExtraHtml;
        this.subHtml = data.subHtml;
        this.translationData = translationData;
        this.data = data;

        this.citySignupShoppingBasketInit = new CitySignupShoppingBasketInit(this, this.data, this.translationData);
        this.citySignupValidation = new CitySignupValidation(translationData);

        this.citySignupShoppingBasketInit.initializeShoppingBasketScrollFunction();
    }

    /** geprüft */
    add_extra_coordination()
    {
        const html = this.extraHtml.html;
        $("#sr_kommunenregistrierung_extra_coordination").append(html);
        $("#sr_creg_country").change(); // Update country-specific fields
        this.citySignupValidation.validation_register();

        $(".removeExtraCoordination").off('click').on('click', (e) => {
            this.remove_extra_coordination(e.currentTarget);
        });

        $('.sr_creg_coordination_salutation').select2();

        $('.sr_creg_coordination_title').select2();
    }

    /** geprüft */
    remove_extra_coordination(element)
    {
        if (confirm(this.translationData.removePerson)) {
            $(element).closest("form").remove();
        }
    }

    /** geprüft */
    update_member_checkboxes()
    {
        // #2906
        const translationData = this.translationData;
        const validaton = this.citySignupValidation;
        if (!this.data.adminMode) {
            $("input[name=sr_creg_is_kb_member],input[name=sr_creg_is_agfk_member]").off("click").on("click", function() {

                let countryId = $("#sr_creg_country").val();
                let fState    = $("#sr_creg_fstate").val();
                let self      = this;

                if (countryId === "57") {    // Germany only
                    // KB membership: All fStates                     // AGFK: Only BW / NRW
                    if ($(this).attr("name") === "sr_creg_is_kb_member" || (fState === "Baden-Württemberg" || fState === "Nordrhein-Westfalen" || fState === "Brandenburg")) {
                        setTimeout(function() {
                            if ($(self).is(":checked")) {
                                validaton.showErrorMessage(4000, translationData.memberCheckboxChecked);
                            } else {
                                validaton.showErrorMessage(4000, translationData.memberCheckboxUnchecked);
                            }
                        }, 50);
                        $("[name=sr_creg_ags]").change();    // Trigger update
                        return false;
                    }
                }
                return true;
            });
        }
    }

    /** geprüft */
    update_team_tags(selectedFState)
    {
        $("[data-role=team-tag-checkbox]").each(function() {

            // NOTE: Order is important here!

            // 1. Tags that are only enabled in certain FStates
            let onlyEnabledInFStates = $(this).attr("data-only-enabled-in-fstates");
            if (onlyEnabledInFStates) {
                onlyEnabledInFStates = JSON.parse(onlyEnabledInFStates);
                if (onlyEnabledInFStates.indexOf(selectedFState) < 0) {
                    $(this).removeAttr("checked");
                    $(this).prop("readonly", "readonly");
                    $(this).off("click").on("click", function() { return false });    // Force readonly
                    $(this).parent("[data-sr-role=team-tag-cb-cell]").css("visibility", "hidden");
                    $(this).parent("[data-sr-role=team-tag-cb-cell]").next("[data-sr-role=team-tag-label-cell]").css("visibility", "hidden");
                } else {
                    $(this).removeAttr("readonly");
                    $(this).off("click");                                             // Remove readonly
                    $(this).parent("[data-sr-role=team-tag-cb-cell]").css("visibility", "inherit");
                    $(this).parent("[data-sr-role=team-tag-cb-cell]").next("[data-sr-role=team-tag-label-cell]").css("visibility", "inherit");
                }
            }

            // 2. Tags that are required in certain FStates and can not be deselected
            let requiredInFStates = JSON.parse($(this).attr("data-required-in-fstates"));
            if (requiredInFStates.indexOf(selectedFState) >= 0) {
                $(this).prop("checked", "checked");
                $(this).prop("readonly", "readonly");
                $(this).off("click").on("click", function() { return false });    // Force readonly
                $(this).parent("[data-sr-role=team-tag-cb-cell]").css("opacity", "0.5");
                $(this).parent("[data-sr-role=team-tag-cb-cell]").next("[data-sr-role=team-tag-label-cell]").css("opacity", "0.5");
            } else {
                $(this).removeAttr("readonly");
                $(this).off("click");                                             // Remove readonly
                $(this).parent("[data-sr-role=team-tag-cb-cell]").css("opacity", "1");
                $(this).parent("[data-sr-role=team-tag-cb-cell]").next("[data-sr-role=team-tag-label-cell]").css("opacity", "1");
            }

            // #2870
            if (selectedFState === "Baden-Württemberg") {
                $(".sr-radpendler-only").removeClass("hide");
            } else {
                $(".sr-radpendler-only").addClass("hide");
            }
        })
    }

    /** geprüft */
    check_datepicker(elem)
    {

        // Try to read a valid datepicker date
        // If not valid, reset field value to empty
        let testDate = elem.datepicker("getDate");
        if (!testDate) {
            elem.val("").datepicker("update").change();
        }
    }

    /** geprüft */
    update_enddate()
    {
        citySignUpConfig.g_endDate = null;
        let startDateSelector = $("#sr_creg_start_date");

        if (startDateSelector.val().trim()) {
            let date = startDateSelector.datepicker("getDate");
            date.setDate(date.getDate() + (this.data.campaignDays - 1));
            $("#sr_creg_end_date_text").html(this.translationData.lastDay + ": <span id='sr_creg_end_date'>" + date.getDate() + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear() + "</span>");
            citySignUpConfig.g_endDate = date;
        } else {
            $("#sr_creg_end_date_text").html("");
        }
    }

    /** geprüft */
    update_radar_end_date(tabOffset = 0)
    {
        let radarStartDate = $("#sr_creg_radar_start_date");
        let radarEndDate = $("#sr_creg_radar_end_date");

        if (tabOffset !== 0) {
            radarStartDate = $("#sr_creg_radar_start_date_" + tabOffset);
            radarEndDate = $("#sr_creg_radar_end_date_" + tabOffset);
        }

        if (radarStartDate && radarStartDate.val() && radarStartDate.val().trim()) {
            let date = radarStartDate.datepicker("getDate");
            date.setDate(date.getDate() + (this.data.campaignDays - 1));
            radarEndDate.datepicker("setDate", date);
        } else {
            radarEndDate.val("").datepicker("update");
        }
    }

    /** geprüft */
    async load_form(data, citySignupAddSubCity)
    {
        let form = this;
        const container = $('.container-height');
        const utility = new Utility();

        //
        //  Update shopping basket
        //
        let tempSafe = utility.getUrlParameter('sr_action');

        let basketObj = false;
        if (typeof data.sr_creg_pricing_json !== "undefined") {
            basketObj = await this.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.citySignupShoppingBasketCalculation.refreshBasketJsonBasedOnFormular(data);
        }

        // show all step at once to fill in all information
        container.each((index, item) => {
            $(item).removeClass('hidden');
        })

        // disable the shopping basket when sr_action is activation
        // should be enabled when user tempsave or get data from preYear
        if (tempSafe === 'load_temp_cityreg' || tempSafe === false) {
            citySignUpConfig.g_update_basket_disabled = false;
        } else {
            citySignUpConfig.g_update_basket_disabled = true;
        }

        if (citySignUpConfig.g_forceRecalculate) {
            citySignUpConfig.g_update_basket_disabled = false;
        }

        // Load shopping basket only for activation (sr_creg_id is already set)
        if (!utility.getUrlParameter('sr_token')) {
            if (basketObj) {

                if (basketObj.price) {
                    this.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.update_basket_attendence('','attendence',basketObj, data);
                }

                if (basketObj.promotion) {
                    this.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.update_basket_promotion(basketObj);
                }

                if (basketObj.lk_attendence) {
                    this.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.update_basket_lk_attendence('','lk_attendence',basketObj.lk_attendence);
                }

                if (basketObj.radar) {
                    this.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.update_basket_radar_fee('', 'radar_fee', basketObj.radar, data);
                }

                if (basketObj.merchandise) {
                    $.each(basketObj.merchandise, function(i,item) {
                        form.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.update_basket_merchandise('', i, item);
                    });
                }
            }
        }

        for (let key in data)
        {
            if (key !== "extra_coordination" && key !== "sub_cities")
            {

                const formElements = [
                  'sr_kommunenregistrierung_main',
                  'sr_kommunenregistrierung_merchandise',
                  'sr_kommunenregistrierung_legal'
                ];
                for (let element in formElements) {
                    let selector = $("#" + formElements[element] + " [name=" + key + "]:not([type=checkbox]):not([type=radio])");
                    let ret = selector.val(data[key]);

                    if (ret.length === 0) {// FIXME: Checks if set and 0

                        ret = $("#" + formElements[element] + " [name=" + key + "]:checkbox").prop("checked", data[key]);
                        selector.trigger('change');
                    }

                    if (ret.length === 0) {
                        if (typeof data[key] === "string") {
                            ret = $("#" + formElements[element] + " [name=" + key + "][type=radio][value='" + data[key].replace("'", " ") + "']").attr("checked", "checked");
                        } else {
                            ret = $("#" + formElements[element] + " [name=" + key + "][type=radio][value='" + data[key] + "']").attr("checked", "checked");
                        }
                    }

                    if (selector.hasClass('select')) {
                        selector.trigger('change');
                    }

                    if (key === "sr_creg_start_date") {
                        $("#sr_creg_start_date").datepicker("setDate", new Date(data[key].replace(/(.*)\.(.*)\.(.*)/, '$3-$2-$1'))).datepicker("update");
                    }
                }
            } else if (key === "extra_coordination") {

                let extra_count = data.extra_coordination.length;
                for (let i=0; i<extra_count; i++)
                {
                    this.add_extra_coordination();

                    for (let extra_key in data.extra_coordination[i]) {
                        let ret = $(".sr_kommunenregistrierung_extra:last [name=" + extra_key + "]:not([type=checkbox]):not([type=radio])").val(data.extra_coordination[i][extra_key]);
                        if (ret.length === 0) ret = $(".sr_kommunenregistrierung_extra:last [name=" + extra_key + "]:checkbox").attr("checked", data.extra_coordination[i][extra_key]);  // FIXME: Checks if set and 0
                        if (ret.length === 0) ret = $(".sr_kommunenregistrierung_extra:last [name=" + extra_key + "][type=radio][value='" + data.extra_coordination[i][extra_key] + "']").attr("checked", "checked");
                    }
                }

            } else if (key === "sub_cities") {

                let sub_count = data.sub_cities.length;

                for (let i=0; i<sub_count; i++)
                {
                    let tabOffset = citySignupAddSubCity.addSubCityPreLoad(form, data.sub_cities[i]);

                    if (data.sub_cities[i]["sr_creg_radar_enabled"] === "on") {
                        $('#sr_kommunenregistrierung_sub_' + tabOffset + ' [name=sr_creg_radar_enabled]').trigger('change');
                    }

                    if (data.sub_cities[i]["sr_creg_sub_coordination"] === "on") {
                        $('#sr_kommunenregistrierung_sub_' + tabOffset + ' [name=sr_creg_sub_coordination]').trigger('change');
                    }
                }

                const akkordeonWrapper = document.querySelector('#akkordeon-wrapper_' + ((sub_count + 1) * 100));

                if (typeof this.initializedOffsets == "undefined" || !this.initializedOffsets) {
                    this.initializedOffsets = {};
                }

                $(akkordeonWrapper).off('click').on('click',() => {
                    if (typeof this.initializedOffsets[(sub_count + 1) * 100] == "undefined" || !this.initializedOffsets[(sub_count + 1) * 100]) {
                        citySignupAddSubCity.initializeNewSubCity(form, (citySignupAddSubCity.getCurrentOffset()) * 100, form.citySignupShoppingBasketInit, true);
                        citySignupAddSubCity.initializeSubCityRadar((sub_count + 1) * 100, form, form.citySignupValidation);
                        this.initializedOffsets[(sub_count + 1) * 100] = true;
                    }
                });
            }

            if (data.sr_creg_radar_firstname && data.sr_creg_radar_lastname && data.sr_creg_radar_email) {
                $('#sr_creg_radar_contact').prop('checked', 'checked');
                $('#sr_creg_radar_contact').trigger('change');
            }

            if (data.sr_creg_citytype_preset == "EigeneBezeichnung") {
                $('#article').css('display', 'table-row');
                $('#own_description').css('display', 'table-row');
            }
        }

        form.checkPreYearMembershipWithCurrentMembership(data['sr_creg_ags'], 'sr_kommunenregistrierung_main');


        citySignupAddSubCity.initializeSubCitySelect2();

        const citySignupInitialization = new CitySignupInitialization(this.translationData);
        const cityRegistrationSub = $('.sr_kommunenregistrierung_sub');

        await citySignupInitialization.callAgsInfo(data['sr_creg_ags'], form.data.registerMode, form.data.adminMode, $('#sr_creg_is_kb_member'), $('#sr_creg_is_agfk_member'), form.data.apiUrl, form.data.apiKey, 0);

        cityRegistrationSub.each((index, item) => {
            const tabOffset = $(item).attr('data-sr-offset');
            let kbMember = $('#sr_creg_is_kb_member_' + tabOffset);
            let agfkMember = $('#sr_creg_is_agfk_member_' + tabOffset);

            $('#sr_creg_ags_search_main_' + tabOffset).on('select2:select', function(e) {
                $('#sr_creg_cityname_' + tabOffset).val(e.params.data.city);
                $('#sr_creg_uuid_' + tabOffset).val(e.params.data.id);
                $('#sr_creg_ags_' + tabOffset).val(e.params.data.ags).change();

                citySignupInitialization.callAgsInfo(e.params.data.ags, form.data.registerMode, form.data.adminMode,kbMember, agfkMember, form.data.apiUrl, form.data.apiKey, tabOffset);

            });
        });

        if (data['sr_creg_coordination_enabled'] === "on") {
            $("#sr_kommunenregistrierung_main [name=sr_creg_coordination_enabled]").trigger('change');
        }
        if (data['sr_creg_invoice_enabled'] === "on") {
            $("#sr_kommunenregistrierung_main [name=sr_creg_invoice_enabled]").trigger('change');
        }

        if (data['sr_creg_radar_enabled'] === "on" || data['sr_creg_radar_enabled'] === 1) {
            $("#sr_kommunenregistrierung_main [name=sr_creg_radar_enabled]").trigger('change');
        }

        // Update shopping basket with merchandise
        if (utility.getUrlParameter('sr_token')) {
            $('#sr_creg_merchandise_1').change();
            $('#sr_creg_merchandise_3').change();
            $('#sr_creg_merchandise_4').change();
            $('#sr_creg_merchandise_6').change();
            $('#sr_creg_merchandise_7').change();
            $('#sr_creg_merchandise_8').change();

            // #5306 Reset flyers and posters in cart
            $("#sr_creg_send_flyers_0").change();
            $("#sr_creg_send_posters_0").change();

            // Trigger cart update for selected option
            $("[name=sr_creg_send_flyers]:checked").change();
            $("[name=sr_creg_send_posters]:checked").change();
        }

        //citySignupAddSubCity.checkSingleSubCitiesOnMandatoryFields(this);

        if (citySignUpConfig.g_update_basket_disabled === false) {
            $('#sr_creg_population').change();
            form.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.citySignupShoppingBasketCalculation.ajaxCalculatePromotion(data['sr_creg_fstate'], data['sr_creg_population']);
        }
        form.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.citySignupShoppingBasketCalculation.calculateTotal();

        // after fill in all information only show the first page
        container.each((index, item) => {
            if (index !== 0) {
                $(item).addClass('hidden');
            }
        });

        let renewCityMessageShown = false;
        renewCityMessageShown = this.checkCityUUIDExists(data.sr_creg_ags, $('#sr_creg_cityname'), $('#sr_creg_ags'), $('#sr_creg_uuid'), form, renewCityMessageShown, false);

        cityRegistrationSub.each((index, item) => {
            const tabOffset = $(item).attr('data-sr-offset');
            const agsOffset = $('#sr_creg_ags_' + tabOffset);
            renewCityMessageShown = this.checkCityUUIDExists(agsOffset.val(), $('#sr_creg_cityname_' + tabOffset), agsOffset, $('#sr_creg_uuid_' + tabOffset), form, renewCityMessageShown, document.querySelector('#sr_kommunenregistrierung_sub_' + tabOffset));
        });

        // check if there is a missing field - if yes then show the "!" in the step wizzard
        for (let key in mandatoryFieldArray) {
            const step = document.querySelector('.sr-creg-step-city[data-step="' + key + '"]');
            if (mandatoryFieldArray[key].length > 0) {
                step.querySelector('.missing-field-info').classList.remove('hidden');
            } else {
                step.querySelector('.missing-field-info').classList.add('hidden');
            }
        }

    }

    checkCityUUIDExists(ags, elementCityname, elementAgs, elementUuid, citySignupForm, renewCityMessageShown, elementForMandatoryField) {
        // update the uuid by ags from commune service
        const url = this.data.apiUrl + "city/ags_info?sr_api_key=" + this.data.apiKey + "&sr_ags=" + ags;
        if (ags) {
            $.ajax({
                url: url,
                type: "GET",
                async: false,
                success: function (response) {
                    const currentStep = $(elementCityname).parents('.step-city').attr('data-step');
                    if (response.found === 0) {
                        citySignupForm.checkCityUUIDExistsWithoutZeros(ags, elementCityname, elementAgs, elementUuid, citySignupForm, renewCityMessageShown, elementForMandatoryField)
                    } else {
                        elementUuid.val(response.uuid);
                    }
                }
            });
        }
        return renewCityMessageShown;
    }

    checkCityUUIDExistsWithoutZeros(ags, elementCityname, elementAgs, elementUuid, citySignupForm, renewCityMessageShown, elementForMandatoryField) {
        // remove trailing 0
        ags = ags.replace(/0{7}$/, '');

        const url = this.data.apiUrl + "city/ags_info?sr_api_key=" + this.data.apiKey + "&sr_ags=" + ags;
        $.ajax({
            url: url,
            type: "GET",
            async: false,
            success: function (response) {
                const currentStep = $(elementCityname).parents('.step-city').attr('data-step');
                if (response.found === 0) {
                    elementCityname.val("");
                    elementAgs.val("");
                    elementUuid.val("");
                    if (!renewCityMessageShown) {
                        citySignupForm.citySignupValidation.showErrorMessage(3000, "Bitte wähle erneut eine Kommune aus");
                        renewCityMessageShown = true;
                    }
                    mandatoryFieldArray[currentStep] = citySignupForm.citySignupValidation.getMissingMandatoryFieldItems(elementForMandatoryField, false, true);
                } else {
                    elementAgs.val(ags);
                    elementUuid.val(response.uuid);
                }
            }
        });
    }

    checkPreYearMembershipWithCurrentMembership(ags, formElements) {
        const url = this.data.apiUrl + "city/ags_info?sr_api_key=" + this.data.apiKey + "&sr_ags=" + ags;

        if (ags) {
            $.ajax({
                url: url,
                type: "GET",
                async: false,
                success: function (response) {
                    if (response.is_kb_member === 1) {
                        $("#" + formElements + " [name=sr_creg_is_kb_member]:checkbox").attr("checked", true);
                    } else {
                        $("#" + formElements + " [name=sr_creg_is_kb_member]:checkbox").attr("checked", false);
                    }

                    if (response.is_agfk_member === 1) {
                        $("#" + formElements + " [name=sr_creg_is_agfk_member]:checkbox").attr("checked", true);
                    } else {
                        $("#" + formElements + " [name=sr_creg_is_agfk_member]:checkbox").attr("checked", false);
                    }
                }
            });
        }
        return true;
    }

    /** geprüft */
    serialize_form(form)
    {
        let data = {};

        $.each(form.serializeArray(), function() {
            data[this.name] = this.value;
        });

        return data;
    }

    /** geprüft */
    serialize_all()
    {
        let data = this.serialize_form( $("#sr_kommunenregistrierung_main"));
        let merchData = this.serialize_form($('#sr_kommunenregistrierung_merchandise'));
        let legalData = this.serialize_form($('#sr_kommunenregistrierung_legal'));

        Object.assign(data, merchData, legalData);

        let extra_coordination = [];
        let sub_cities         = [];
        const form = this;

        $(".sr_kommunenregistrierung_extra").each(function() {
            let extra_data = form.serialize_form($(this));
            extra_coordination.push(extra_data);
        });
        data.extra_coordination = extra_coordination;

        $(".sr_kommunenregistrierung_sub").each(function() {
            let sub_data = form.serialize_sub($(this));
            if (sub_data['sr_creg_ags'] && sub_data['sr_creg_ags'] !== '-') {
                sub_cities.push(sub_data);
            }
        });
        data.sub_cities = sub_cities;
        data.sr_creg_pricing_json = $('#sr_creg_pricing_json').val();

        return data;
    }

    /** geprüft */
    serialize_sub(element)
    {
        const form = this;
        let sub_data = form.serialize_form(element);
        let sub_extra_coordination = [];

        element.find(".sr_kommunenregistrierung_sub_extra").each(function() {
            let extra_data = form.serialize_form($(this));
            sub_extra_coordination.push(extra_data);
        });
        sub_data.extra_coordination = sub_extra_coordination;

        return sub_data;
    }

    /** geprüft */
    trim_all()
    {
        $("#sr_kommunenregistrierung_main").children().each(function() {
            this.value = $(this).val().trim();
        })

        $(".sr_kommunenregistrierung_sub").each(function() {
            $(this).children().each(function(){
                this.value = $(this).val().trim();
            })
        });
    }


    /** teilweise geprüft - funktionsweise muss auf staging getestet werden, da nur dort die Anbindung zu google funktioniert */
    lookup_coordinates(offset)
    {
        if (this.data.adminMode) {
            const form = this;
            let nameField = $('#sr_creg_cityname');
            let latField  = $('#sr_creg_lat');
            let lonField  = $('#sr_creg_lon');
            let map       = $('#map');
            let fstate    = $("#sr_creg_fstate");

            if (typeof offset !== "undefined" && offset) {
                nameField = $('#sr_creg_cityname_'+offset);
                latField  = $('#sr_creg_lat_'+offset);
                lonField  = $('#sr_creg_lon_'+offset);
                map       = $('#map_'+offset);
            }


            let address = nameField.val() + (fstate.val() ? ", "+fstate.val() : "") + ", " + $("#sr_creg_country option:selected").html();

            address = window.prompt(this.translationData.getCoordinates, address);

            let geocoder = new google.maps.Geocoder();
            geocoder.geocode({
                "address": address
            }, function(results) {
                if (results && typeof results[0] !== "undefined") {
                    let lat = results[0].geometry.location.lat;
                    let lon = results[0].geometry.location.lng;
                    latField.val(lat);
                    lonField.val(lon);
                    form.update_map(offset);
                } else {
                    form.citySignupValidation.showErrorMessage(2000, form.translationData.getCoordinatesFailed);
                }
            });
        }
    }

    /** teilweise geprüft - funktionsweise muss auf staging getestet werden, da nur dort die Anbindung zu google funktioniert */
    update_map(offset)
    {
        if (this.data.adminMode) {
            if (typeof google === "undefined") return;

            let latField  = $('#sr_creg_lat');
            let lonField  = $('#sr_creg_lon');
            let map       = document.getElementById('map');

            if (typeof offset !== "undefined" && offset) {
                latField  = $('#sr_creg_lat_'+offset);
                lonField  = $('#sr_creg_lon_'+offset);
                map       = document.getElementById('map_'+offset);
            } else {
                offset = 0;
            }

            let lat = latField.val();
            let lon = lonField.val();

            if (lat && lon) {
                if (typeof citySignUpConfig.g_maps[offset] !== "undefined" && citySignUpConfig.g_maps[offset]) {
                    citySignUpConfig.g_maps[offset].setCenter(new google.maps.LatLng(parseFloat(lat), parseFloat(lon)));
                } else {
                    citySignUpConfig.g_maps[offset] = new google.maps.Map(map,
                        {
                            center: new google.maps.LatLng(parseFloat(lat), parseFloat(lon)),
                            zoom: 10,
                            mapTypeId: google.maps.MapTypeId.ROADMAP,
                            mapTypeControl: false,
                            zoomControl: true
                        });
                }
            }
        }
    }

    /** funktionsweise prüfen? - wird die Funktion noch benötigt? Wenn ja wo? */
    init_maps()
    {
        if (this.data.adminMode) {
            this.update_map();
            for (let i=0; i<=citySignUpConfig.g_tabOffset; i+=100) {
                this.update_map(i);
            }
        }
    }

    /** geprüft */
    autodetectAGS(elem, ags_elem)
    {
        if (elem.val() && ags_elem.val().trim() === "")
        {
            this.searchAGS(elem.val().trim(), $('#sr_creg_country').val(), $('#sr_creg_fstate').val(), function(data) {
                if (data.result && data.result.length === 1) {
                    ags_elem.val(data.result[0].ags).change();
                }
            });
        }
    }

    /** geprüft */
    searchAGS(search, countryId, fState, onSuccess)
    {
        const form = this;
        if (citySignUpConfig.g_agsAJAXRequest !== false) {
            citySignUpConfig.g_agsAJAXRequest.abort();
            citySignUpConfig.g_agsAJAXRequest = false;
        }

        citySignUpConfig.g_agsAJAXRequest = $.ajax({
            url: form.data.apiUrl + "city/ags_search?sr_api_key=" + form.data.apiKey + "&sr_cityname="+encodeURIComponent(search)
                + (typeof countryId !== "undefined" && countryId ? "&sr_country_id=" + encodeURIComponent(countryId) : "")
                + (typeof fState    !== "undefined" && fState    ? "&sr_fstate="     + encodeURIComponent(fState)    : ""),
            dataType: "json",
            success: onSuccess,
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
        });
    }

    requestPreYearData() {
        const form = this;
        let emailElement = $("input[name=sr_prevyear_email]");
        let emailAddress = emailElement.val().trim();

        if (!emailAddress || !this.citySignupValidation.validate_email_or_empty(emailElement)) {
            form.citySignupValidation.showErrorMessage(2000, this.translationData.validEmail);
            return;
        }

        if (citySignUpConfig.g_formAJAXMailRequest) {
            form.citySignupValidation.showErrorMessage(2000, this.translationData.dataAlreadySend);
            //g_formAJAXMailRequest.abort();
            return;
        }

       citySignUpConfig. g_formAJAXMailRequest = $.ajax({
            url:  this.data.formUrl + "&sr_action=prevYear&sr_email="+encodeURIComponent(emailAddress)+"&XDEBUG_SESSION=IDEA",
            type: "POST",
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            complete: function () {
                citySignUpConfig.g_formAJAXMailRequest = false;
            },
            success: function (data) {
                if (data === "success") {
                    $("#sr_prevyear_feedback_text").html(form.translationData.preYearFeedback);
                } else if (data === "notfound") {
                    form.citySignupValidation.showErrorMessage(2000, form.translationData.preYearNotFound);
                } else if (data === "error") {
                    form.citySignupValidation.showErrorMessage(2000, form.translationData.preYearFailure);
                }
            },
            error: function (xhr) {
                if (!isNaN(xhr.responseText)) {
                    form.citySignupValidation.showErrorMessage(2000, xhr.responseText);
                } else {
                    form.citySignupValidation.showErrorMessage(2000, form.translationData.preYearFailure);
                }
            }
        });

        return true;
    }
}

window.CitySignupForm = CitySignupForm;
