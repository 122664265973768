export function initImageEventHandler(element, data) {
    $('.image-wrapper').off('click');

    setPaddingTopToHeightOfTextContainer();

    $(element.currentTarget).children('.lightbox-background, .lightbox-wrapper').addClass('active');
    $('body').addClass('lightbox-open');
    data.currentPicture = 0;
    data.lightboxImage = $('.lightbox-wrapper.active .lightbox-image');
    data.countImages = data.lightboxImage.length;
    data.lightboxImage.eq(0).addClass('active');
    checkPageArrowVisibility(data.currentPicture, data.countImages);
    data = handleSwipeImages(data);

    return data;
}

export function setPaddingTopToHeightOfTextContainer() {
    let elementWrapper = $('.lightbox-wrapper.active');
    let elementTextHeight = $('.lightbox-wrapper.active .lightbox-text').height();
    let deviceWidth = window.innerWidth;

    /*
    if (deviceWidth <= 475) {
        elementWrapper.css("padding-top", elementTextHeight);
    }
    */

}

export function handleSwipeImages(data) {
    let start = null;
    $(".lightbox-wrapper.active").off("touchstart").on("touchstart", (event) => { event.originalEvent.changedTouches.length === 1 ? start = event.originalEvent.changedTouches.item(0).clientX : start = null });
    $(".lightbox-wrapper.active").off("touchend").on("touchend", (event) => swipeAnimation(data, event, start));

    return data;
}

export function swipeAnimation(data, event, start) {
    let offset = 100;
    let end = event.originalEvent.changedTouches.item(0).clientX;
    let element = $(".lightbox-wrapper.active .lightbox-image-slider .lightbox-image.active");
    if (start) {
        if ((end > start + offset) && (data.currentPicture > 0)) {
            element.animate({marginLeft: '+=100%'}, 10, function () {
                $.map(data.lightboxImage, (child) => $(child).css('margin-left', "unset"));
            });
            data.currentPicture -= 1;
        }

        if ((end < start - offset) && (data.currentPicture < (data.countImages - 1))) {
            element.animate({marginRight: '-=100%'}, 10, function () {
                $.map(data.lightboxImage, (child) => $(child).css('margin-right', "unset"));
            });
            data.currentPicture += 1;
        }

        $.map(data.lightboxImage, (child) => $(child).removeClass('active'));
        data.lightboxImage.eq(data.currentPicture).addClass('active');
        handleImagePoints(data.currentPicture);
    }
}

export function handleIconCloseButton(event, data) {
    event.stopPropagation();
    $(event.currentTarget).parents('.lightbox-wrapper').removeClass('active');
    $(event.currentTarget).parents('.lightbox-wrapper').prev().removeClass('active');
    $('body').removeClass('lightbox-open');
    $.map(data.lightboxImage, (child) => $(child).removeClass('active'));
    return $('.image-wrapper').click((element) => initImageEventHandler(element, data));
}

export function checkPageArrowVisibility(currentPicture, countImages) {
    let statusLeft = currentPicture === 0 ? 'hidden' : 'unset';
    let statusRight = currentPicture === (countImages - 1) ? 'hidden' : 'unset';

    $('.pagination-arrow-left').css('visibility', statusLeft);
    $('.pagination-arrow-right').css('visibility', statusRight);

}

export function handleImagePoints(currentPicture) {
    let lightboxPoints = $('.lightbox-image.active .lightbox-point-wrapper .lightbox-point')
    $.map(lightboxPoints, (element) => $(element).css("opacity", "20%"));
    $(lightboxPoints).eq(currentPicture).css("opacity", "50%");
}

export function handleImageArrows(event, currentPicture, data) {
    event.stopPropagation();
    data.currentPicture = currentPicture;
    $.map(data.lightboxImage, (child) => $(child).removeClass('active'));
    data.lightboxImage.eq(data.currentPicture).addClass('active');
    checkPageArrowVisibility(data.currentPicture, data.countImages);
    handleImagePoints(data.currentPicture);
}

window.handleImageArrows = handleImageArrows;
window.handleImagePoints = handleImagePoints;
window.checkPageArrowVisibility = checkPageArrowVisibility;
window.handleIconCloseButton = handleIconCloseButton;
window.swipeAnimation = swipeAnimation;
window.handleSwipeImages = handleSwipeImages;
window.setPaddingTopToHeightOfTextContainer = setPaddingTopToHeightOfTextContainer;
window.initImageEventHandler = initImageEventHandler;
