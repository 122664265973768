export function faq_init() {
    var category_index = {
        "cat1": 1,
        "cat2": 2,
        "cat3": 4,
        "cat4": 8,
        "cat5": 16,
        "cat6": 32,
        "cat7": 64,
        "cat8": 128
    }

    $(".faq-category").each(function() {
        $(this).click(function() {

            var category = $(this).attr('data-sr-category-index');
            var mask = category_index[category];

            var this_is_active = $(this).hasClass('active');

            $('.faq-category.active').removeClass('active');

            if (!this_is_active) {
                $(this).addClass('active');
            }

            var non_is_active = $('.faq-category.active').length === 0;

            $(".akkordeon-topic").each(function() {
                var chosen_category= parseInt($(this).attr('data-sr-category'));
                $(this).show();

                if (non_is_active || (chosen_category & mask) && !this_is_active) {

                } else {
                    $(this).hide();
                }

            });
        });
    });

    $('#search-field').keyup(function () {
        $(".faq-category").each(function() {
            $('.faq-category.active').removeClass('active');
        });

        var search_str = $('#search-field').val().toLowerCase();

        $('.akkordeon-topic').hide();

        $.each(contents, function(index, value) {
            var header_str = value.header.toLowerCase();
            var body_str = value.flexform_content.toLowerCase();
            if (header_str.includes(search_str) || body_str.includes(search_str)) {
                $('#a-' + value.uid).show();
            }
        });

        $('#clear-btn').addClass('delete');
        $('#clear-btn').click(function() {
            $('#search-field').val("");
            $('#clear-btn').removeClass('delete');
            $('.akkordeon-topic').show();
        });

        if ($('#search-field').val().length < 1) {
            $('#clear-btn').removeClass('delete');
        }

    });

    // accordion active toggle, remove active from other accordions when clicking another
    $(".akkordeon-topic a").click(function () {
        $(this).parents('.akkordeon-topic').toggleClass('active');
        if (!$(this).parents(".akkordeon-topic").hasClass("active")) {
            // clicking on a closing accordion removes all active ones
            $('.akkordeon-topic').removeClass('active');
        } else {
            // remove them from all other and add active to the one clicked
            $('.akkordeon-topic').removeClass('active');
            $(this).parents(".akkordeon-topic").addClass("active");
        }
    });

}
window.faq_init = faq_init
