function matchStart(params, data)
{
    if ($.trim(params.term) === '') {
        return data;
    }

    // Skip if there is no 'children' property
    if (typeof data.children === 'undefined') {
        return null;
    }

    var filteredChildren = [];
    $.each(data.children, function (idx, child) {
        if (child.text.toUpperCase().indexOf(params.term.toUpperCase()) == 0) {
            filteredChildren.push(child);
        }
    });

    if (filteredChildren.length) {
        var modifiedData = $.extend({}, data, true);
        modifiedData.children = filteredChildren;

        return modifiedData;
    }

    // Return `null` if the term should not be displayed
    return null;
}

export function adminAuswertungInit(pageUrl, typo3Url, auswertungUrl, auswertungXlsUrl) {
    //==== Select2 Dropdown with collapsable groups
    $(".select").select2({
        matcher: matchStart,
        language: {
            noResults: function (term) {
                return '{_"Keine Einträge gefunden"}';
            }
        }
    });
    $('select').select2();

    $("#city-result").hide();

    $("#cities-dropdown").change(function() {

        var selected_city = $("select option:selected").text();
        var city_id = encodeURIComponent($(this).val());

        var URL = pageUrl + "&sr_city_id=" + encodeURIComponent($(this).val());
        var kommunenseite = typo3Url +  selected_city.toLowerCase();
        var teams_URL = auswertungUrl + "&sr_action=show_city&sr_city_id=" + city_id;
        var radelnde_URL = auswertungUrl + "&sr_action=show_city_radler&sr_city_id=" + city_id;
        var downloadlink = auswertungXlsUrl + "&sr_city_id=" + city_id;

        if($('#cities-dropdown option:selected').attr('data-all') !== "1") {
            $("#city-result").show();
            $("#cityname").text(selected_city);
            $("#citylink").attr("href", kommunenseite);
            $("#teams").attr("href", teams_URL);
            $("#radelnde").attr("href", radelnde_URL);
            $("#xls_download").attr("href", downloadlink);
        } else {
            $("#city-result").hide();
        }

    });
}

window.adminAuswertungInit = adminAuswertungInit;
