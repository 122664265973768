/**
 * This class contains the initialization of the shopping basket of the city signup form
 */

class CitySignupShoppingBasketInit {
    constructor(citySignupForm, data, translationData) {
        this.citySignupShoppingBasketUpdate = new CitySignupShoppingBasketUpdate(citySignupForm, this, translationData, data);
    }

    initData() {
        return {
            population: $("input[id='sr_creg_population']").val(),
            fstate: $("#sr_creg_fstate").val(),
            sub_communes: $('#subcity-existing-content-wrapper .sr_kommunenregistrierung_sub'),
            regio_key_main: $('#sr_creg_ags').val(),
            country: $('#sr_creg_country').val()
        }
    }

    initializeShoppingBasketScrollFunction() {
        $(window).on({
            'scroll': function() {

                let curHeight = $(this).scrollTop();
                let container = $('.commune-pricing');
                let containerHeight = $('.container-height:not(.hidden)');
                let heightShoppingBasket = $('.shopping-basket').height();
                let heightContainer = containerHeight.height();
                let startPosContainer = containerHeight.offset().top;
                let maxHeight = heightContainer + startPosContainer;
                let scrollHeight = 0;
                let heightNavbar = $('#navbar').height();

                const maxHeightShoppingBasketContent = $('.kommune-registrieren .shopping-content').css('max-height');
                const heightShoppingBasketContent = $('.kommune-registrieren .shopping-content').height();

                if (parseInt(heightShoppingBasketContent) >= parseInt(maxHeightShoppingBasketContent)) {
                    $('.shopping-basket-fade').removeClass('hidden');
                } else {
                    $('.shopping-basket-fade').addClass('hidden');
                }

                if ((curHeight > (startPosContainer - 100)) && curHeight < (maxHeight - heightShoppingBasket - 320)) {
                    container.addClass('fixed-top');
                    scrollHeight = curHeight - startPosContainer + heightNavbar + 150;
                    container.css('top', scrollHeight);
                    if (container.hasClass('fixed-bottom')) {
                        container.removeClass('fixed-bottom')
                    }
                } else if (curHeight < startPosContainer) {
                    container.removeClass('fixed-top');
                    container.css("top","62px");
                    $('#total-overview').css("position","relative");
                    if (container.hasClass('fixed-bottom')) {
                        container.removeClass('fixed-bottom');
                    }
                } else if (curHeight > (maxHeight - heightShoppingBasket - 320)) {
                    container.removeClass('fixed-top');
                    $('#total-overview').css("position","relative");
                    container.css("top","unset");
                    container.addClass('fixed-bottom');
                }
            }
        });
    }

    /**
     *  implements the trigger
     */
    initTrigger() {
        const form = this;
        // Enabeling Trigger when the basket should be updating

        //Attendence Fee
        $("input[id='sr_creg_population']").off('change').on('change', function (e) {
            form.citySignupShoppingBasketUpdate.update_basket_special_cases();
            form.citySignupShoppingBasketUpdate.update_basket_radar_fee($(this), 'radar_fee');
        });

        //RADar
        $("input[name='sr_creg_radar_duration']").off('change').on('change', function () {
            form.citySignupShoppingBasketUpdate.update_basket_radar_fee($(this), 'radar_fee');
        });

        $("input[id^='sr_creg_radar_enabled']").on('change', function () {
            form.citySignupShoppingBasketUpdate.update_basket_radar_fee($(this), 'radar_fee');
        });

        $("input[id^='sr_creg_radar_enabled_']").on('change', function () {
            form.citySignupShoppingBasketUpdate.update_basket_radar_fee($(this), 'radar_fee');
        });

        $("input[id='sr_creg_radar_start_date']").off('change').on('change', function() {
            form.citySignupShoppingBasketUpdate.update_basket_radar_fee($(this), 'radar_fee');
        });

        //Flyer
        $("input[name='sr_creg_send_flyers']").off('change').on('change', function (e) {
            if (citySignUpConfig.g_update_basket_disabled === false) {
                form.citySignupShoppingBasketUpdate.update_basket_merchandise($(this), 'flyer');
            }
        });

        //Poster
        $("input[name='sr_creg_send_posters']").off('change').on('change', function (e) {
            if (citySignUpConfig.g_update_basket_disabled === false) {
                form.citySignupShoppingBasketUpdate.update_basket_merchandise($(this), 'poster');
            }
        });
    }

    /**
     *  implements the trigger which got called once
     */
    initBasketTrigger() {
        const form = this;

        $("#sr_creg_fstate").on('change', function () {
            form.citySignupShoppingBasketUpdate.update_basket_attendence($(this), 'attendence');
        });

        $("#sr_creg_is_agfk_member").on('change', function () {
            form.citySignupShoppingBasketUpdate.update_basket_attendence($(this), 'attendence');
        });

        $("#sr_creg_citytype_preset").on('change', function () {
            form.citySignupShoppingBasketUpdate.update_basket_attendence($(this), 'attendence');
        });

        $("#sr_creg_citytype").on('change', function () {
            form.citySignupShoppingBasketUpdate.update_basket_attendence($(this), 'attendence');
        });

        $("#sr_creg_country").on('change', function () {
            form.citySignupShoppingBasketUpdate.update_basket_attendence($(this), 'attendence');
        });

        $("#sr_creg_ags").on('change', function () {
            form.citySignupShoppingBasketUpdate.update_basket_special_cases();
        });

        $("#sr_creg_ags_search_main").on('change', function () {
            form.citySignupShoppingBasketUpdate.update_basket_special_cases();
        });

        $("#sr_creg_is_newcomer").on('change', function () {
            form.citySignupShoppingBasketUpdate.update_basket_attendence($(this), 'attendence');
        });

        //Werbematerialien
        $('#merchandise').children().find('[name^=sr_creg_merchandise]').on('change', function (e) {
            if (citySignUpConfig.g_update_basket_disabled === false) {
                form.citySignupShoppingBasketUpdate.update_basket_merchandise($(this), $(this).data('label'));
            }
        });
    }

}

window.CitySignupShoppingBasketInit = CitySignupShoppingBasketInit;
