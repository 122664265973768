function srGetURLVars()     // FIXME: Move to common (multiple uses in project)
{
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
}

function srGetURLVar(name)  // FIXME: Move to common (multiple uses in project)
{
    var vars = srGetURLVars();
    if (typeof vars[name] !== 'undefined') {
        return vars[name];
    }
    return null;
}

window.srGetURLVar = srGetURLVar;
window.srGetURLVars = srGetURLVars;



function escapeHTML(text)
{
    var map = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#039;"
    };

    return text.replace(/[&<>"']/g, function(m) { return map[m]; });
}

window.escapeHTML = escapeHTML;
