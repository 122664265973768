class AgsAdministration {
    constructor(baseUrl, apiUrl, apiKey, translation) {
        this.dataTable = this.initiateDataTable(baseUrl, apiUrl, apiKey, translation);
        this.initiateSelects(baseUrl, this.dataTable)
        this.initiateEventListener(baseUrl, apiUrl, apiKey, this.dataTable)
    }

    initiateDataTable(baseUrl, apiUrl, apiKey, translation) {
        return $('#ags-list').DataTable({
            //"dom": '<<t>>', // show only table, no pagination and no filter
            "info": false,
            "order": [[1, "asc"]],
            "lengthChange": true,
            "lengthMenu": [[20, 50, 100, 1000], [20, 50, 100, 1000]],  //[[20, 50, 100, -1], [20, 50, 100, "{_"Alle"}"]],
            "pagingType": "simple_numbers",
            "iDisplayLength": 50,
            serverSide: true,
            'columnDefs':  [
                {
                    'targets': [3, 4, 5],
                    'orderable': false
                },
                {
                    'targets': [1, 2, 3, 4, 5],
                    'searchable': false
                }
            ],
            ajax: {
                url: "/admin/ags/ajax",
                type: "POST",
                cache:  false,
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                }
            },
            columns: [
                {data: "country_name"},
                {data: "regionKey"},
                {data: "agsCityname"},
                {data: "officialName"},
                {data: "is_kb_member"},
                {data: "is_agfk_member"},
                {data: "action"},
                {data: "usableSvg"},
                {data: "customSvg"},
                {data: "blacklisted"}
            ],
            "language": {
                "loadingRecords": translation.loadingRecords,
                "processing": translation.processing,
                "lengthMenu": translation.lengthMenu,
                "zeroRecords": translation.zeroRecords,
                "info": translation.info,
                "infoEmpty": translation.infoEmpty,
                "infoFiltered": translation.infoFiltered,
                "decimal": ".",
                "thousands": ",",
                "search": translation.search,
                oPaginate: {
                    sNext: '<svg class="pagination-icon" viewBox="0 0 100 100"><path d="M 10,50 L 60,100 L 62,98 L 15,50  L 62,2 L 60,0 Z" class="arrow" transform="translate(100, 100) rotate(180) "></path></svg>',
                    sPrevious: '<svg class="pagination-icon" viewBox="0 0 100 100"><path d="M 10,50 L 60,100 L 62,98 L 15,50  L 62,2 L 60,0 Z" class="arrow"></path></svg>',
                    sFirst: '<i class="fa fa-step-backward"></i>',
                    sLast: '<i class="fa fa-step-forward"></i>'
                }
            }
        }).on('draw.dt', (e) => {
            const dataTable = this;
            $('.ags_upt').on('click', (e) => {
                this.showUpdateConfirmation(baseUrl, apiUrl, apiKey, e.currentTarget.dataset.uuid, e.currentTarget.dataset.ags)
            });

            $('.ags_del').on('click', (e) => {
                this.showDeleteConfirmation(baseUrl, apiUrl, apiKey, e.currentTarget.dataset.uuid, e.currentTarget.dataset.ags)
            });

            $('#country_filter').on('select2:select', (e) => {
                const country_id_new = e.params.data.element.attributes["data-sr-country-id"].nodeValue;
                dataTable['dataTable'].columns(0).search(country_id_new).draw();
            });

            $('#ags-filter-search').keyup(function(e) {
                if (typeof e == "undefined" || e.keyCode == 13) {
                    $('#ags-list').addClass("loading");
                    dataTable['dataTable'].search($(this).val()).draw();
                }
            });

            $('#toggle-usable-entries, #toggle-ags-entries ').off('change').on('change', (e) => {

                if ($(e.currentTarget).is(':checked')) {
                    dataTable['dataTable'].ajax.url("/admin/ags/ajax?load=all").load();
                } else {
                    dataTable['dataTable'].ajax.url("/admin/ags/ajax?load=usable").load();
                }
            });

            $('#toggle-ags-entries').off('change').on('change', (e) => {
                if ($(e.currentTarget).is(':checked')) {
                    dataTable['dataTable'].ajax.url("/admin/ags/ajax?load=ags").load();
                } else {
                    dataTable['dataTable'].ajax.url("/admin/ags/ajax?load=usable").load();
                }
            });

            $('input[name=blacklisted-checkbox]').off('click').on('click', (e) => {

                const data = {
                    uuid: $(e.currentTarget).attr('data-uuid'),
                    value: $(e.currentTarget).is(':checked')
                }

                $.ajax({
                    url: apiUrl + 'ags/blacklist/set?sr_api_key=' + apiKey + '&XDEBUG_SESSION=IDEA',
                    type: "POST",
                    data: data,
                    crossDomain: true,
                    xhrFields: {
                        withCredentials: true
                    },
                    success: function (response) {

                    }
                });
            });

            $('input[name=commune-checkbox]').off('click').on('click', (e) => {
                const data = {
                    uuid: $(e.currentTarget).attr('data-uuid'),
                    value: $(e.currentTarget).is(':checked'),
                    type: $(e.currentTarget).attr('data-sr-checkbox-type')
                }
                $.ajax({
                    url: apiUrl + 'ags/changeUsableCustomValue?sr_api_key=' + apiKey,
                    type: "POST",
                    data: data,
                    crossDomain: true,
                    xhrFields: {
                        withCredentials: true
                    },
                    success: function (response) {

                    }
                });
            });
        });
    }

    initiateSelects(baseUrl) {
        //set focus on search field of select2 in modal dialog
        $.fn.modal.Constructor.prototype.enforceFocus = function() {};
        const countryFilter = $('#country_filter');

        $('#ags_country_add').select2();
        $('#ags_country_add_new').select2();
        $('#ags_country_update').select2();
        $('#ags_fstate_add_new').select2();
        $('#ags_timezone_add_new').select2();
        $('#ags_type_add_new').select2();

        $('#ags_fstate_update').select2();
        $('#ags_timezone_update').select2();
        $('#ags_type_update').select2();

        countryFilter.select2();
        $('#ags_official_name_add').select2({
            minimumInputLength: 3,
            ajax: {
                url: baseUrl + '/admin/ags/ajax?updateCommuneList=true',
                dataType: 'json',
                data: function(params) {
                    return {
                        sr_cityname: params.term,
                        sr_country: $('#ags_country_add').val()
                    };
                },
                processResults: function(data) {
                    let resVal = [];

                    if(!data.error) {
                        $.each(data, function(index,item) {
                            resVal.push({
                                'id' : item.uuid,
                                'text': item.official_name + " (" + item.city_international_id + ")",
                                'city_international_id': item.city_international_id,
                                'city': item.official_name,
                            });
                        });
                    }

                    return {
                        results: resVal
                    };
                }
            }
        });

    }

    initiateEventListener(baseUrl, apiUrl, apiKey) {
        const originThis = this;
        $('#show-rs-menu').off('click').on('click', function() {
            originThis.showAddConfirmation(apiUrl, apiKey);
        });

        $('#import-rs').off('click').on('click', () => {
           originThis.showImportConfirmation(apiUrl, apiKey);
        });

        $('#import_data_button').off('click').on('click', () => {
            $('#import_data_file').trigger('click');
        });

        $('#import_data_file').off('change').on('change', (e) => {
            $('#file-name').html(e.currentTarget.files[0].name);
        });

        $('#ags-import-finish').off('click').on('click', () => {
            $('#membershipsNotFound').html("");
            $('#uploadButtonWrapper').addClass('hidden');
            $('#waiting-spinner').removeClass('hidden');
            let uploadResult = $('#uploadResult');
            let fData = new FormData();
            fData.append('import-file', document.getElementById('import_data_file').files[0]);
            fData.append('importType', $('input[name=sr_ags_upload_type]:checked').val());

            $.ajax({
                url: apiUrl + 'ags/import?sr_api_key=' + apiKey + '&XDEBUG_SESSION=IDEA',
                type: "POST",
                data: fData,
                processData: false,
                contentType: false,
                cache: false,
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                },
                success: function (response) {
                    $('#waiting-spinner').addClass('hidden');
                    uploadResult.removeClass('hidden');
                    if (response.status === "error") {
                        uploadResult.html(response.message);
                        $('#uploadButtonWrapper').removeClass('hidden');
                    } else {
                        $('#closeUploadModal').removeClass('hidden');
                        $('#updatedMemberships').html(response.membershipChanged);
                        $('#membershipsNotFound').removeClass('hidden');
                        $('#uploadResult').removeClass('hidden');
                        console.log(response);
                        response.agsNotFound.forEach((item) => {

                            if (typeof response['agsNotFoundDetails'] === "undefined") {
                                $('#membershipsNotFound').append(item['cityName'] + " (" + item['ags'] + ") " + "(keine Alternative - möglicherweise LK)");
                            } else {
                                $('#membershipsNotFound').append(originThis.templateOfAgsDropDown(item['ags'], item['cityName'], response['agsNotFoundDetails'][item['ags']]['agsHelper']));
                            }
                        });

                        $('.refresh-membership').off('click').on('click', (e) => {
                            originThis.setMembership(e.currentTarget.dataset.srAgs, e.currentTarget.dataset.srUuid, e.currentTarget.dataset.srMembership, apiUrl, apiKey);
                        });
                    }
                }
            });
        });
    }

    templateOfAgsDropDown(ags, cityName, alternativeAgs) {
        let html = "<div class='d-flex mt-5 mb-5'>";
        html += "<a role='button' data-toggle='collapse' href='#collapse-" + ags + "' aria-expanded='false' aria-controls='collapseExample'>";
        html += cityName + " (" + ags + ")";
        html += '</a>';
        html += '</div>';

        html += '<div class="collapse" id="collapse-' + ags + '">';
        alternativeAgs.forEach((item) => {
            html += '<div class="d-flex">';
            html += '<div class="w-30">' + item['officialName'] + '</div>';
            html += '<div class="w-30">' + item['regionKey'] + '</div>';
            if (typeof item['membership'] !== "undefined" && item['membership'] !== null) {
                html += '<div class="w-30">' + item['membership']['label'] + '</div>';
                if (item['membership']['id'] === 2) {
                    html += '<div class="w-30 button-wrapper"><button type="button" class="btn btn-default small-button refresh-membership" data-sr-uuid="' + item['uuid'] + '" data-sr-ags="' + item['regionKey'] + '" data-sr-membership="0">Löschen</button></div>';
                } else {
                    html += '<div class="w-30 button-wrapper"><button type="button" class="btn btn-default small-button refresh-membership" data-sr-uuid="' + item['uuid'] + '" data-sr-ags="' + item['regionKey'] + '" data-sr-membership="1">Hinzufügen</button></div>';
                }
            } else {
                html += '<div class="w-30 button-wrapper"><button type="button" class="btn btn-default small-button refresh-membership" data-sr-uuid="' + item['uuid'] + '" data-sr-ags="' + item['regionKey'] + '" data-sr-membership="1">Hinzufügen</button></div>';
            }

            html += '</div>';
        })
        html += '</div>';

        return html;
    }

    setMembership(ags, uuid, value, apiUrl, apiKey) {
        $.ajax({
            url: apiUrl + 'ags/update/membership/' + uuid + '?sr-membership-value=' + value + '&sr_api_key=' + apiKey + '&XDEBUG_SESSION=IDEA',
            type: "GET",
            processData: false,
            contentType: false,
            cache: false,
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            success: function (response) {
                $('[data-sr-ags="' + ags +'"]').parent().html('');
            }

        });
    }

    showImportConfirmation(apiUrl, apiKey) {
        $('#import-ags-modal').modal('show');
    }

    showDeleteConfirmation(baseUrl, apiUrl, apiKey, uuid, agsId) {
        if (uuid === null) uuid = 0;

        $('#delete-ags-key-modal').modal('show');

        $.ajax({
            url: apiUrl + 'ags/get/' + uuid + '?sr_api_key=' + apiKey,
            cache:  false,
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            type: "GET",
            data: {
                "ags": agsId
            },
            success: function(response) {
                var data = response.response.data;
                $('#delete-ags-country-confirmation').html(data.country_name);
                $('#delete-ags-key-confirmation').html(data.ags);
                $('#delete-ags-name-confirmation').html(data.cityname);
                $('#delete-ags-kb-confirmation').html(data.is_kb_member);
                $('#delete-ags-agfk-confirmation').html(data.is_agfk_member);
            }
        });

        $('#ags-key-delete-finish').off('click').on('click', function () {

            $.ajax({
                url: apiUrl + 'ags/delete/' + uuid + '?sr_api_key=' + apiKey,
                cache: false,
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                },
                data: {
                    "ags": agsId
                },
                type: "POST",
                success: function (response) {
                    $('#ags-list').DataTable().ajax.reload();
                    $('#delete-ags-msg').html(response.message);
                    $('#delete-ags-key-success').modal('show');
                }

            });

        });
    }

    showUpdateConfirmation(baseUrl, apiUrl, apiKey, uuid, agsId) {
        if (uuid === null) uuid = 0;

        $('#update-ags-key-modal').modal('show');
        $.ajax({
            url: apiUrl + 'ags/get/' + uuid + '?sr_api_key=' + apiKey,
            cache: false,
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            type: "GET",
            data: {
                "ags": uuid
            },
            success: function (response) {
                const data = response.response.data;

                $('#ags_country_update').val(data.country_id).change();
                $('#ags_key_update').val(data.ags);
                $('#ags_official_name_update').val(data.official_name);
                $('#ags_manual_name_update').val(data.manual_name);
                $('#ags_type_update').val(data.cityType).change();
                $('#ags_timezone_update').val(data.timezone).change();
                $('#ags_fstate_update').val(data.fstate).change();
                if (data.is_kb_member === 1) {
                    $('#ags_kb_update').prop('checked', true);
                } else {
                    $('#ags_kb_update').prop('checked', false);
                }
                if (data.is_agfk_member === 1) {
                    $('#ags_agfk_update').prop('checked', true);
                } else {
                    $('#ags_agfk_update').prop('checked', false);
                }

                if (!data.uuid) {
                    $('#ags-update-uuid').removeClass('hidden');
                } else {
                    $('#ags-update-uuid').addClass('hidden');
                }
            }
        });

        $('#ags-key-update-finish').off('click').on('click', function () {
            var data = {
                'country_id': $('#ags_country_update').val(),
                'ags': $('#ags_key_update').val(),
                'official_name': $('#ags_official_name_update').val(),
                'manual_name': $('#ags_manual_name_update').val(),
                'is_kb_member': $('#ags_kb_update').prop('checked') ? 1 : 0,
                'is_agfk_member': $('#ags_agfk_update').prop('checked') ? 1 : 0,
                'fstate': $('#ags_fstate_update').val(),
                'timezone': $('#ags_timezone_update').text(),
                'citytype': $('#ags_type_update').val(),
            };

            $.ajax({
                url: apiUrl + 'ags/update/' + uuid + '?sr_api_key=' + apiKey + '&XDEBUG_SESSION=IDEA',
                cache: false,
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                },
                data: data,
                type: "POST",
                success: function (response) {
                    $('#ags-list').DataTable().ajax.reload();
                    $('#update-ags-msg').html(response.message);
                    $('#update-ags-key-success').modal('show');
                }
            });
        });
    }

    showAddConfirmation(apiUrl, apiKey) {
        let uuid;
        let official_name;
        let city_international_id;
        let country;
        let country_new;

        $('#add-ags-key-modal').modal('show');

        $('#ags_official_name_add').on('select2:select', function(e) {
            uuid = e.params.data.id;
            official_name = e.params.data.city;
            city_international_id = e.params.data.city_international_id;
            $('#ags_manual_name_add').val(official_name);
            $('#ags_key_add').val(city_international_id);
        });

        $('#ags_country_add').on('select2:select', function(e) {
            country = e.params.data.element.attributes["data-sr-country-name"].nodeValue;
        });

        $('#ags_country_add_new').on('select2:select', function(e) {
            country_new = e.params.data.element.attributes["data-sr-country-name"].nodeValue;
        });

        $('#ags-key-add-finish').off('click').on('click', function () {
            let data;
            let error;
            $('.error-msg').html("");

            if (error) {
                return false;
            }

            data = {
                'country': country_new,
                'country_id': $('#ags_country_add_new').val(),
                'ags': $('#ags_key_add_new').val(),
                'name': $('#ags_manual_name_add_new').val(),
                'is_kb_member': $('#ags_kb_add_new').prop('checked') ? 1 : 0,
                'is_agfk_member': $('#ags_agfk_add_new').prop('checked') ? 1 : 0,
                'fstate': $('#ags_fstate_add_new').val(),
                'timezone': $('#ags_timezone_add_new').text(),
                'citytype': $('#ags_type_add_new').val(),
            };

            $.ajax({
                url: apiUrl + 'ags/create?sr_api_key=' + apiKey + '&XDEBUG_SESSION=IDEA',
                data: data,
                type: "POST",
                cache:  false,
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                },
                success: function(response) {
                    $('#ags-list').DataTable().ajax.reload();
                    $('#add-ags-msg').html(response.messageHTML);
                    $('#add-ags-key-success').modal('show');
                    //reset values
                    window.location.reload();
                }
            });
        });
    }
}



window.AgsAdministration = AgsAdministration;
