class Blog {
    constructor(baseUrl, apiUrl, apiKey) {
        this.baseUrl = baseUrl;
        this.apiUrl = apiUrl;
        this.apiKey = apiKey;

        this.cities = document.querySelector('#cities');
        this.tags = document.querySelector('.tags');
        this.blogPosts = document.querySelector('.blog-posts');
        this.pagination = document.querySelector('#post-pagination');
        this.sortingElement = document.querySelector('#blog-sorting');
        this.starSector = document.querySelectorAll('.blog-stars');
        this.starSectorItems = document.querySelector('#sliderStar .carousel-inner');
        this.starSectorItemsMobile = document.querySelector('#sliderStarMobile .carousel-inner');
        this.starSectorList = document.querySelector('#sliderStar .carousel-indicators');
        this.starSectorListMobile = document.querySelector('#sliderStarMobile .carousel-indicators');

        this.agsRequestURL = this.apiUrl + 'blog/getItems?sr_api_key=' + apiKey;
        this.getAllPostsURL = this.apiUrl + 'blog/getBlogPosts?sr_api_key=' + apiKey;

        this.sortingValue = this.sortingElement.getAttribute('data-sr-sorting');

        // eventlistener for sorting dropdown
        this.sortingElement.addEventListener('click', (event) => {

            if (this.sortingValue === "1") {
                this.sortingElement.setAttribute('data-sr-sorting', "0");
                $(this.sortingElement.querySelector(".sorting-icon")).addClass('asc');
                $(this.sortingElement.querySelector(".sorting-text")).html("älteste zuerst");
            } else {
                this.sortingElement.setAttribute('data-sr-sorting', "1");
                $(this.sortingElement.querySelector(".sorting-icon")).removeClass('asc');
                $(this.sortingElement.querySelector(".sorting-text")).html("neuste zuerst");
            }

            this.sortingValue = this.sortingElement.getAttribute('data-sr-sorting');

            this.getBlogPosts(1);
        });
    }

    initializeBlog() {
        let urlParams = new URLSearchParams(window.location.search);
        this.initializeSelect();
        const blog = this;
        //$(this.starSector).addClass('hide');

        this.starSector.forEach((item, index) => {
            $(item).addClass('hide');
        });

        $(this.cities).on('select2:select', function(e) {
            let tagElements = [];
            blog.tags.querySelectorAll('.tag-item').forEach((element, index) => {
                tagElements.push(element.innerText);
            });

            let data = e.params.data;
            if (data.star === 1) {
                if (tagElements.indexOf(data.text) === -1) {
                    $(blog.tags).append("<div class='tag-item' data-id='" + data.id + "' data-type='star'>" + escapeHTML(data.text) + "<div class='tag-delete'></div></div>");
                }
            } else {
                if (tagElements.indexOf(data.text) === -1) {
                    $(blog.tags).append("<div class='tag-item' data-id='" + data.id + "' data-type='city'>" + escapeHTML(data.text) + "<div class='tag-delete'></div></div>");
                }
            }

            $('.tag-delete').off('click').on('click', function() {
                $(this).parent().remove();
                blog.getBlogPosts(1);
            });

            $(blog.cities).select2().val(null).trigger("change");
            blog.getBlogPosts(1);
            blog.initializeSelect();
        });

        if (urlParams.get('sr_city_id')) {
            $.ajax({
                url: this.apiUrl + 'city/' + urlParams.get('sr_city_id') + '/cityname?sr_api_key=' + this.apiKey,
                success: (response) => {
                    $(this.tags).append("<div class='tag-item' data-id='" + urlParams.get('sr_city_id') + "' data-type='city'>" + escapeHTML(response.cityname) + "<div class='tag-delete'></div></div>");

                    $('.tag-delete').off('click').on('click', function() {
                        $(this).parent().remove();
                        blog.getBlogPosts(1);
                    });

                    blog.starSector.forEach((item, index) => {
                        $(item).removeClass('hide');
                    });

                    blog.getStarsByCity(urlParams.get('sr_city_id'));
                    blog.getBlogPosts(1);
                }
            });
        } else if (urlParams.get('sr_blog_id')) {
            $.ajax({
                url: this.apiUrl + 'blog/getStarName/' + urlParams.get('sr_blog_id') + '?sr_api_key=' + this.apiKey,
                success: (response) => {

                    if (response.status === 1) {
                        $(this.tags).append("<div class='tag-item' data-id='" + urlParams.get('sr_blog_id') + "' data-type='star'>" + escapeHTML(response.firstname) + " " + escapeHTML(response.lastname) + "<div class='tag-delete'></div></div>");

                        $('.tag-delete').off('click').on('click', function() {
                            $(this).parent().remove();
                            blog.getBlogPosts(1);
                        });

                        blog.getBlogPosts(1);
                    } else {
                        $(blog.blogPosts).html('');
                    }

                }
            });
        } else {
            blog.getBlogPosts(1);
        }
    }

    initializeSelect() {
        const blog = this;
        $(this.cities).select2({
            ajax: {
                url: blog.agsRequestURL,
                dataType: 'json',
                data: function(params) {
                    return {
                        sr_cityname: params.term
                    };
                },
                processResults: function(data) {
                    let resVal = [];
                    $.each(data.result, function(index,item) {
                        let text = '';
                        let id = 0;
                        if (item.star === 1) {
                            text = item.name;
                            id = item.user_id;
                        } else {
                            text = item.cityname;
                            id = item.city_id;
                        }

                        resVal.push({
                            'id' : id,
                            'text': text,
                            'star': item.star
                        });
                    });
                    return {
                        results: resVal
                    };
                }
            }
        });
    }

    getBlogPosts(page, renderPagination = true) {
        let tags = [];
        $(this.tags).children().each((index, item) => {
            let element = {
                'id': $(item).data('id'),
                'type': $(item).data('type')
            };
            tags.push(element);
        });

        let data = {
            "tags": tags,
            "page": page,
            "maxItemsOnPage": 5,
            "sorting": this.sortingValue
        };

        const blogPosts = this;

        $.ajax({
            url: this.getAllPostsURL,
            type: 'POST',
            data: data,
            beforeSend: function() {
                $(blogPosts.blogPosts).html('<div class="content-spinner flex-row flex-center"><img src="' + blogPosts.baseUrl + '/images/loading.svg"></div>');
            },
            success: function(data) {
                $(blogPosts.blogPosts).html('');
                $(blogPosts.blogPosts).append(data.html);

                if (renderPagination) {
                    $('#pagination').html('');
                }
                paginationServersideRenderingInit(5, data.amountPosts, '#tags-position', blogPosts.baseUrl, blogPosts.apiUrl, blogPosts.apiKey, page);
            }
        });
    }

    getStarsByCity(cityId) {

        const blogPosts = this;

        $.ajax({
            url: this.apiUrl + 'blog/getStars/' + cityId + '?sr_api_key=' + this.apiKey,
            success: function(data) {
                let starItem = '';
                let starItemMobile = '';
                let listItem = '';
                let listItemMobile = '';
                let siteCounter = 1;
                let itemCounter = 1;
                let innerItemCounter = 1;
                let itemsOnSlide = 4;

                if ($(window).width() >= 768 && $(window).width() <= 992) {
                    itemsOnSlide = 3;
                }

                let maxSites = data.length/itemsOnSlide;
                let maxSitesMobile = data.length;



                for (let i = 0; i < Math.ceil(maxSites); i++) {
                    if (i === 0) {
                        listItem += '<li data-target="#sliderStar" data-slide-to="' + i + '" class="active"></li>';
                    } else {
                        listItem += '<li data-target="#sliderStar" data-slide-to="' + i + '"></li>';
                    }
                }

                for (let i = 0; i < Math.ceil(maxSitesMobile); i++) {
                    if (i === 0) {
                        listItemMobile += '<li data-target="#sliderStar" data-slide-to="' + i + '" class="active"></li>';
                    } else {
                        listItemMobile += '<li data-target="#sliderStar" data-slide-to="' + i + '"></li>';
                    }
                }

                $(blogPosts.starSectorList).append(listItem);
                $(blogPosts.starSectorListMobile).append(listItemMobile);

                data.forEach((item) => {

                    if (siteCounter === 1 && itemCounter === 1) {
                        starItem += '<div class="item slides active">';
                        starItemMobile += '<div class="item slides active">';
                    } else if(innerItemCounter === 1){
                        starItem += '<div class="item slides">';
                        starItemMobile += '<div class="item slides">';
                    } else {
                        starItemMobile += '<div class="item slides">';
                    }

                    starItem += blogPosts.starProfileTemplate(item);
                    starItemMobile += blogPosts.starProfileTemplate(item);

                    starItemMobile += '</div>';

                    if (innerItemCounter === itemsOnSlide) {
                        starItem += '</div>';
                        siteCounter += 1;
                        innerItemCounter = 1;
                    } else {
                        innerItemCounter += 1;
                    }

                    itemCounter += 1;
                });

                if (itemCounter <= itemsOnSlide) {
                    document.querySelectorAll('#sliderStar .carousel-control').forEach((item) => {
                        item.classList.add('hidden');
                    });
                }

                if (itemCounter <= 2) {
                    document.querySelectorAll('#sliderStarMobile .carousel-control').forEach((item) => {
                        item.classList.add('hidden');
                    });
                }

                $(blogPosts.starSectorItems).append(starItem);
                $(blogPosts.starSectorItemsMobile).append(starItemMobile);
            }
        });
    }

    starProfileTemplate(item) {
        let starItem = '<div class="star-item">';
        starItem += '<img src="' + item.profilePath + '" alt="STADTRADELN Star">';
        starItem += '<div class="star-name">' + item.firstname + ' ' + item.lastname + '</div>';
        starItem += '<div class="star-role">' + item.teamname + '</div>';
        starItem += '</div>';
        return starItem;
    }
}

window.Blog = Blog;
