class LightBox {

    constructor() {
        let extraImages = document.querySelectorAll('.extra-image');
        let closeBtn = document.querySelector('#image-lightbox .close');
        let nextBtn = document.querySelector('#image-lightbox .next');
        let prevBtn = document.querySelector('#image-lightbox .prev');

        if (extraImages > 0) {
            this.showSlides(this.slideIndex);
        }

        closeBtn.addEventListener('click', () => {
            this.closeModal();
        });

        nextBtn.addEventListener('click', () => {
            this.plusSlides(1);
        });

        prevBtn.addEventListener('click', () => {
            this.plusSlides(-1);
        });

        extraImages.forEach((item, index) => {
            item.addEventListener('click', () => {

                this.currentSlide(index + 1);
                this.openModal();
            })
        });
    }

    showSlides(n) {
        let i;
        const slides = document.getElementsByClassName("lightboxSlides");

        if (n > slides.length) {this.slideIndex = 1}
        if (n < 1) {this.slideIndex = slides.length}
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }

        slides[this.slideIndex-1].style.display = "block";
    }

    openModal() {
        document.getElementById("image-lightbox").style.display = "block";
    }
    closeModal() {
        document.getElementById("image-lightbox").style.display = "none";
    }
    plusSlides(n) {
        this.showSlides(this.slideIndex += n);
    }
    currentSlide(n) {
        this.showSlides(this.slideIndex = n);
    }
}

window.LightBox = LightBox;

