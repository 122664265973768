/******************************************/
/*              Pagination                */
/******************************************/

function paginationServersideRenderingInit(amountItemsOnOneSite, amountAllItems, link_id, baseUrl, apiUrl, apiKey, page) {

    let amount_sites = Math.ceil(amountAllItems/amountItemsOnOneSite);
    if (amount_sites === 0) {
        amount_sites = 1;
    }

    let paginationPages = $('#pagination li a');

    if (paginationPages.length === 0) {
        implementPagination(amount_sites, link_id, 1, null, null);
    }

    /* add the class 'active' to a page if selected */
    $('.pagination li a').off('click').click(function() {
        let current_page = calculateActiveSite(this, amount_sites, link_id, null, false);

        $('html, body').animate({
            scrollTop: $(link_id).offset().top - 150
        }, 500);

        let blog = new Blog(baseUrl, apiUrl, apiKey);
        blog.getBlogPosts(current_page, false);
    });

    if (page === 1 || page === amount_sites) {
        checkArrowLinks(page, amount_sites, null, link_id, false);
    }
}

/**
 * Initialize a pagination for cards
 *
 * @param item                  identifier of the card
 * @param link_id               id of the title (used as jumpmark when page is changing)
 * @param amount_items_mobile   amount of items shown on one page on mobile devices
 * @param amount_items_desktop  amount of items shown on one page on desktop devices
 */
function pagination_init(item, link_id, amount_items_mobile, amount_items_desktop) {

    let count_tc_statements = item.length;
    let viewportWidth = $(window).width();

    /* amount of statements per site if mobile device */
    let amount_site_statements = amount_items_mobile;

    /* change amount of statements per site if desktop device */
    if (viewportWidth > 786) {
        amount_site_statements = amount_items_desktop;
    }

    /* round amount of sites up */
    let amount_sites = Math.ceil(count_tc_statements/amount_site_statements);
    let current_site = 1;
    let current_statement = 1;

    /* every row-tag in tc-statements will get an data-attribute with the site on which the row will be visibile */
    item.each(function() {
        if (current_statement <= amount_site_statements) {
            $(this).attr('data-sr-site', current_site);
        } else {
            current_statement = 1;
            current_site = current_site + 1;
            $(this).attr('data-sr-site', current_site);
        }

        current_statement = current_statement + 1;
    });
    $('.pagination li').remove();
    implementPagination(amount_sites, link_id, 1, null, item);

    item.hide();

    /* add the class 'active' to a page if selected */
    $('.pagination li a').click(function() {
        let current_page = calculateActiveSite(this, amount_sites, link_id, item, true);
        change_site(item);

        $('html, body').animate({
            scrollTop: $(link_id).offset().top - 150
        }, 500);
    });

    change_site(item);
}

/**
 * loop through the pagignation - if on of them got the class 'active', the value of this element will be compared with the
 * data-attribute of the item and if they match the row will be shown
 *
 * @param item      identifier of the card
 */
function change_site(item) {

    $('.pagination li a').each(function () {
        if ($(this).hasClass('active')) {
            const pagination_value = parseInt($(this).html());

            item.each(function () {
                const site_value = parseInt($(this).attr('data-sr-site'));
                if (site_value === pagination_value) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            });
        }
    });
}

function calculateActiveSite(siteElement, amount_sites, link_id, item, browserRendering) {
    let current_page = parseInt($(siteElement).html());

    $('.pagination li a').each(function() {
        if ($(this).hasClass('active')) {
            current_page = parseInt($(this).html());
            $(this).removeClass('active');
        }
    });

    /* calculating the new site if the user clicks the previous or next button */
    if ($(siteElement).attr('id') === "pagination-next-link") {
        current_page = current_page + 1;
    } else if ($(siteElement).attr('id') === "pagination-previous-link"){
        current_page = current_page - 1;
    } else {
        $(siteElement).addClass('active');
        current_page = parseInt($(siteElement).html());
    }

    implementPagination(amount_sites, link_id, current_page, $(siteElement), item, browserRendering);

    return current_page;
}

function checkArrowLinks(current_page, amount_sites, item, link_id, browserSideRendering = false) {
    let previousBtn = $('#pagination-previous-link');
    let nextBtn = $('#pagination-next-link');
    /* disable the previous or next button if the first or last site is selected */
    if (amount_sites === 1) {
        previousBtn.parent().addClass('disabled');
        nextBtn.parent().addClass('disabled');

        previousBtn.off('click');
        nextBtn.off('click');

    } else if (current_page === 1) {
        previousBtn.parent().addClass('disabled');
        nextBtn.parent().removeClass('disabled');

        previousBtn.off('click');

        if (browserSideRendering) {
            nextBtn.off('click').on('click', function() {
                handlePrevNextBtnBrowserSideRendering(current_page + 1, current_page, amount_sites, item, link_id);
            });
        }

    } else if (current_page === amount_sites) {
        nextBtn.parent().addClass('disabled');
        previousBtn.parent().removeClass('disabled');

        nextBtn.off('click');

        if (browserSideRendering) {
            previousBtn.off('click').on('click', function() {
                handlePrevNextBtnBrowserSideRendering(current_page - 1, current_page, amount_sites, item, link_id);
            });
        }

    } else {
        previousBtn.parent().removeClass('disabled');
        nextBtn.parent().removeClass('disabled');

        if (browserSideRendering) {
            previousBtn.off('click').on('click', function() {
                handlePrevNextBtnBrowserSideRendering(current_page - 1, current_page, amount_sites, item, link_id);
            });
            nextBtn.off('click').on('click', function() {
                handlePrevNextBtnBrowserSideRendering(current_page + 1, current_page, amount_sites, item, link_id);
            });
        }
    }
}

function handlePrevNextBtnBrowserSideRendering(nextPage, current_page, amount_sites, item, link_id) {
    $('.pagination li a').each(function() {
        $(this).removeClass('active');
        if (parseInt($(this).attr('data-site')) === nextPage) {
            $(this).addClass('active');
        }
        implementPagination(amount_sites, link_id, nextPage, $(this), item);
    });
    change_site(item);
    $('html, body').animate({
        scrollTop: $(link_id).offset().top - 150
    }, 500);
    checkArrowLinks(nextPage, amount_sites, item, link_id, true);
}

function implementPagination(amount_sites, link_id, current_page, selectedPage, item, browserRendering) {
    /* append the pagination */
    let paginationElement = $('#pagination');
    let paginationPages = $('#pagination li a');
    let lastPage = $(paginationPages[6]).data('site');

    $(selectedPage).removeClass('active');

    // check if pagination empty
    if (paginationPages.length === 0) {
        paginationElement.append(renderPageNavigation());

        for (let i = 1; i <= amount_sites; i++) {
            if (i === 1) {
                if ($('#pagination .pagination-points.end').length === 0) {
                    $('.pagination #previous').after("<li><span class='pagination-points beginning hide'>...</span></li>");
                }
                $('.pagination #previous').after("<li><a href='" + link_id + "' class='active' data-site='" + i + "'>" + i + "</a></li>");
            } else if (i <= 5 || i === amount_sites) {
                $('.pagination #next').before("<li><a href='" + link_id + "'  data-site='" + i + "'>" + i + "</a></li>");
            } else {
                if ($('#pagination .pagination-points.end').length === 0) {
                    $('.pagination #next').before("<li><span class='pagination-points end'>...</span></li>");
                }
            }
        }
    }

    if (current_page >= 5 && current_page <= (lastPage - 4)) {
        let prevSecPage = current_page - 1;
        let nextFirstPage = current_page + 1;
        let nextSecPage = current_page + 2;
        $('.pagination-points.beginning').removeClass('hide');
        $('.pagination-points.end').removeClass('hide');
        paginationPages.each((index, page) => {

            if (index === 1) {
                $(page).text(1);
                $(page).data('site', 1);
            } else if (index === 2) {
                $(page).text(prevSecPage);
                $(page).data('site', prevSecPage);
            } else if (index === 3) {
                $(page).text(current_page);
                $(page).data('site', current_page);
                $(page).addClass('active');
            } else if (index === 4) {
                $(page).text(nextFirstPage);
                $(page).data('site', nextFirstPage);
            } else if (index === 5) {
                $(page).text(nextSecPage);
                $(page).data('site', nextSecPage);
            }

            if ($(paginationPages[index]).data('site') === current_page) {
                $(page).addClass('active');
            }

        });
    } else if (current_page >= 5 && current_page > (lastPage - 4)) {
        $('.pagination-points.end').addClass('hide');
        $('.pagination-points.beginning').removeClass('hide');
        paginationPages.each((index, page) => {
            if (index > 0 && index <= (paginationPages.length - 3)) {
                $(page).text(index);
                $(page).data('site', index);
            }

            if (current_page >= (lastPage - 5)) {
                if (index === 1) {
                    $(page).text(1);
                    $(page).data('site', 1);
                } else if (index === 2) {
                    $(page).text(lastPage - 4);
                    $(page).data('site', lastPage - 4);
                } else if (index === 3) {
                    $(page).text(lastPage - 3);
                    $(page).data('site', lastPage - 3);
                } else if (index === 4) {
                    $(page).text(lastPage - 2);
                    $(page).data('site', lastPage - 2);
                } else if (index === 5) {
                    $(page).text(lastPage - 1);
                    $(page).data('site', lastPage - 1);
                } else if (index === 6) {
                    $(page).text(lastPage);
                    $(page).data('site', lastPage);
                }
            }

            if ($(paginationPages[index]).data('site') === current_page) {
                $(page).addClass('active');
            }
        });
    } else {
        paginationPages.each((index, page) => {
            $('.pagination-points.beginning').addClass('hide');
            $('.pagination-points.end').removeClass('hide');
            if (index > 0 && index <= (paginationPages.length - 3)) {
                $(page).text(index);
                $(page).data('site', index);
            }

            if ($(paginationPages[index]).data('site') === current_page) {
                $(page).addClass('active');
            }
        });
    }

    checkArrowLinks(current_page, amount_sites, item, link_id, browserRendering);

}

function renderPageNavigation() {
    return "<ul class=\"pagination\">\n" +
        "                <li id=\"previous\" class=\"disabled\">\n" +
        "                    <a href=\"#tags-position\" aria-label=\"Previous\" id=\"pagination-previous-link\">\n" +
        "                        <svg class=\"pagination-icon\" viewBox=\"0 0 100 100\">\n" +
        "                            <path d=\"M 10,50 L 60,100 L 62,98 L 15,50  L 62,2 L 60,0 Z\" class=\"arrow\"></path>\n" +
        "                        </svg>\n" +
        "                    </a>\n" +
        "                </li>\n" +
        "                <li id=\"next\">\n" +
        "                    <a href=\"#tags-position\" aria-label=\"Next\" id=\"pagination-next-link\">\n" +
        "                        <svg class=\"pagination-icon\" viewBox=\"0 0 100 100\">\n" +
        "                            <path d=\"M 10,50 L 60,100 L 62,98 L 15,50  L 62,2 L 60,0 Z\" class=\"arrow\" transform=\"translate(100, 100) rotate(180) \"></path>\n" +
        "                        </svg>\n" +
        "                    </a>\n" +
        "                </li>\n" +
        "            </ul>";
}

window.pagination_init = pagination_init;
window.paginationServersideRenderingInit = paginationServersideRenderingInit;
