export function searchContactForm(baseUrl) {
    $('#inputCitySearchContact').on('change', function() {
        let cityId = $(this).val();
        if (cityId) {
            $.ajax({
                url: baseUrl + 'cities/searchContact/' + cityId,
                success: function(response) {
                    let contactData = JSON.parse(response);
                    $('#contactDetails').html(contactData);
                }
            });
        }
    });
    $('#inputCitySearchContact').select2({
        placeholder: '{_"Gib hier deine Kommune ein"}',
        allowClear: true,
        dropdownAutoWidth : true,
        width: '100%'
    });
    // clear contact data html on clear too
    $("#inputCitySearchContact").on("select2:unselecting", function(e) {
        $('#contactDetails').html('');
    });

    var localeId = -1;
    try {
         localeId = srGetURLVar("L");
    }
    catch(e) {
    }

    //$('.select2-selection__placeholder').text('Gib hier deine Kommune ein');
    // set search placeholder in language depending on page url (dirty solution without translation because select2 placeholder does not work)
    if (localeId == 0 || localeId == 1 || localeId == 3 || window.location.href.startsWith("https://de.") || window.location.href.startsWith("https://tdd-de.") || (window.location.href.indexOf("www.stadtradeln.de/") > -1) || (window.location.href.indexOf("tourduduerf.lu") > -1)) {
        $('.select2-selection__placeholder').text('Gib hier deine Kommune ein');
        //console.log("language DE");
    }
    else if (localeId == 2 || localeId == 4 || window.location.href.startsWith("https://en.") || window.location.href.startsWith("https://tdd-en.") || (window.location.href.indexOf("city-cycling.org") > -1) || (window.location.href.indexOf("tourduduerf.org") > -1)) {
        $('.select2-selection__placeholder').text('Enter municipality name');
        //console.log("language EN");
    }
    else if (localeId == 7 || localeId == 5 || window.location.href.startsWith("https://fr.") || window.location.href.startsWith("https://tdd-fr.") || (window.location.href.indexOf("ville-en-selle.org") > -1) || (window.location.href.indexOf("tourduduerf.eu") > -1)) {
        $('.select2-selection__placeholder').text('Entrez votre municipalité ici');
        //console.log("language FR");
    }
    else if (localeId == 8 || window.location.href.startsWith("https://es.") || (window.location.href.indexOf("biciudad.online") > -1)) {
        $('.select2-selection__placeholder').text('Indica aquí tu municipio');
        //console.log("language ES");
    }
    else if (localeId == 6 || window.location.href.startsWith("https://pt.") || (window.location.href.indexOf("pedalacidade.net") > -1)) {
        $('.select2-selection__placeholder').text('Introduza aqui o seu município');
        //console.log("language PT");
    }
    else if (localeId == 9 || window.location.href.startsWith("https://ro.") || (window.location.href.indexOf("ciclism-in-oras.org") > -1)) {
        $('.select2-selection__placeholder').text('Introduceți municipalitatea dumneavoastră aici');
        //console.log("language RO");
    }
    else {
        $('.select2-selection__placeholder').text('Gib hier deine Kommune ein');
        //console.log("no language option");
    }
}
window.searchContactForm = searchContactForm;
