class PosterGenerator {
    constructor(baseUrl, minDate, maxDate) {
        this.baseUrl = baseUrl;
        this.posterPaths = [
            '../../images/poster_generator/motive/preview/SR_Poster_1.jpg',
            '../../images/poster_generator/motive/preview/SR_Poster_2.jpg',
            '../../images/poster_generator/motive/preview/SR_Poster_3.jpg',
            '../../images/poster_generator/motive/preview/SR_Poster_4.jpg',
            '../../images/poster_generator/motive/preview/SR_Poster_5.jpg',
            '../../images/poster_generator/motive/preview/SR_Poster_6.jpg',
            '../../images/poster_generator/motive/preview/SR_Poster_7.jpg',
            '../../images/poster_generator/motive/preview/SR_Poster_8.jpg',
            '../../images/poster_generator/motive/preview/SR_Poster_9.jpg',
            '../../images/poster_generator/motive/preview/SR_Poster_10.jpg',
            '../../images/poster_generator/motive/preview/SR_Poster_11.jpg',
            '../../images/poster_generator/motive/preview/SR_Poster_12.jpg'
        ];

        // get formular data
        this.dateStart = document.querySelector('#dp-start');
        this.dateEnd = document.querySelector('#dp-end');
        this.cityName = document.querySelector("#sr-city");
        this.cityLabel = document.querySelector("#sr-city-label");
        this.cityUrl = document.querySelector("#website-url");
        this.previewCityUrl = document.querySelector('#poster-preview-url');
        this.submitBtn = document.querySelector("#confirmationButton");
        this.formElement = document.querySelector('#posterForm');
        this.motiv = document.querySelector("#select-motiv");
        this.motivImageSelector = document.querySelector(".flyer-preview img");
        this.choosenBgImage = document.querySelector("#choosen-bg-img");
        // user images
        this.imgButton1 = document.querySelector("#inputUserPicButton");
        this.imgField1 = document.querySelector("#inputUserPic1");
        this.contactFirstname = document.querySelector("#contact-firstname");
        this.contactLastname = document.querySelector("#contact-lastname");
        this.contactEmail = document.querySelector('#contact-mail');
        this.contactDataPrivacy = document.querySelector('#data-privacy');
        this.toSecondStep = document.querySelector('#toSecondStep');

        // Event listener
        this.cityUrl.addEventListener("keyup", (event) => {

            this.cityUrl.value = this.cityUrl.value.replace('ä', "ae");
            this.previewCityUrl.innerHTML = this.cityUrl.value.replace('ä', "ae");
            this.cityUrl.value = this.cityUrl.value.replace('Ä', "ae");
            this.previewCityUrl.innerHTML = this.cityUrl.value.replace('Ä', "ae");

            this.cityUrl.value = this.cityUrl.value.replace('ö', "oe");
            this.previewCityUrl.innerHTML = this.cityUrl.value.replace('ö', "oe");
            this.cityUrl.value = this.cityUrl.value.replace('Ö', "oe");
            this.previewCityUrl.innerHTML = this.cityUrl.value.replace('Ö', "oe");

            this.cityUrl.value = this.cityUrl.value.replace('ü', "ue");
            this.previewCityUrl.innerHTML = this.cityUrl.value.replace('ü', "ue");
            this.cityUrl.value = this.cityUrl.value.replace('Ü', "ue");
            this.previewCityUrl.innerHTML = this.cityUrl.value.replace('Ü', "ue");

            this.cityUrl.value = this.cityUrl.value.replace('ß', "ss");
            this.previewCityUrl.innerHTML = this.cityUrl.value.replace('ß', "ss");

            this.cityUrl.value = this.cityUrl.value.replace('.', "");
            this.previewCityUrl.innerHTML = this.cityUrl.value.replace('.', "");

            this.cityUrl.value = this.cityUrl.value.replace('/', "");
            this.previewCityUrl.innerHTML = this.cityUrl.value.replace('/', "");

            this.previewCityUrl.innerHTML = this.cityUrl.value.toLowerCase();
        });
        this.submitBtn.addEventListener("click", (event) => this.submitForm(event, this.formElement, this.baseUrl));
        this.imgButton1.addEventListener("click", () => this.imgField1.click());
        this.imgField1.addEventListener("change", () => this.selectLogoImg(1));
        this.toSecondStep.addEventListener("click", () => this.toStep2());


        $(this.motiv).on('select2:select', (e) => {
            this.motivImageSelector.setAttribute("src", this.posterPaths[e.params.data.id]);
            this.choosenBgImage.setAttribute("value", this.posterPaths[e.params.data.id]);
        });

        const posterGenerator = this;
        $(this.dateStart).datepicker("destroy").datepicker({
            startDate:  new Date(minDate + "T00:00:00"),
            endDate:    new Date(maxDate + "T00:00:00"),
            changeMonth: true,
            changeYear: false,
            format: "dd.mm.yyyy",
            daysOfWeekHighlighted: "6,0",
            language:   "de",
            calenderWeeks: true,
        }).on('changeDate', function () {
            posterGenerator.fieldVerification(
                {
                    dateStart: {
                        selector: posterGenerator.dateStart,
                        fieldName: 'Startdatum',
                        check: 'date',
                        error: false,
                        errorMsg: ''
                    }
                }
            );

            $('#poster-preview-date-start').html(
                ("0" + $(this).datepicker("getDate").getDate()).slice(-2) +
                "." +
                ("0" + ($(this).datepicker("getDate").getMonth() + 1)).slice(-2) +
                "."
            );
            const date = new Date($(this).datepicker("getDate"));
            const dateAfterTwentyOneDays = new Date(date.setDate(date.getDate() + 20));

            $('#poster-preview-date-end').html(
                ("0" + dateAfterTwentyOneDays.getDate()).slice(-2) +
                "." +
                ("0" + (dateAfterTwentyOneDays.getMonth() + 1)).slice(-2) +
                "." +
                dateAfterTwentyOneDays.getFullYear()
            );
        });

        $(this.cityName).on('select2:select', () => posterGenerator.fieldVerification(
                {
                    cityName: {
                        selector: posterGenerator.cityName,
                        fieldName: 'Kommune',
                        check: 'notEmpty',
                        error: false,
                        errorMsg: ''
                    }
                }
            )
        );


        this.cityUrl.addEventListener("change", () => this.fieldVerification(
            {
                cityUrl: {
                    selector: this.cityUrl,
                    fieldName: 'Webseiten URL',
                    check: 'notEmpty',
                    error: false,
                    errorMsg: ''
                }
            }
        ));

        this.contactFirstname.addEventListener("change", () => this.fieldVerification(
            {
                contact_firstname: {
                    selector: this.contactFirstname,
                    fieldName: 'Vorname',
                    check: 'notEmpty',
                    error: false,
                    errorMsg: ''
                }
            }
        ));

        this.contactLastname.addEventListener("change", () => this.fieldVerification(
            {
                contact_lastname: {
                    selector: this.contactLastname,
                    fieldName: 'Nachname',
                    check: 'notEmpty',
                    error: false,
                    errorMsg: ''
                }
            }
        ));

        this.contactEmail.addEventListener("change", () => this.fieldVerification(
            {
                contact_email: {
                    selector: this.contactEmail,
                    fieldName: 'Email',
                    check: 'email',
                    error: false,
                    errorMsg: ''
                }
            }
        ));

        this.contactDataPrivacy.addEventListener("change", () => this.fieldVerification(
            {
                contact_data_privacy: {
                    selector: this.contactDataPrivacy,
                    fieldName: 'Datenschutz',
                    check: 'clicked',
                    error: false,
                    errorMsg: ''
                }
            }
        ));

    }

    fieldVerification(fields) {
        let errorField = document.querySelector('#error-output');
        let errorMsg = '';
        let error = false;

        for (const key in fields) {

            if (fields[key].selector.parentNode.nextElementSibling) {
                let sibling = fields[key].selector.parentNode.nextElementSibling;
                while (sibling) {
                    if (!sibling.matches('.missing-field')) {

                        sibling = sibling.nextElementSibling;
                    } else {
                        break;
                    }
                }

                if (fields[key].check === "notEmpty") {
                    if (!fields[key].selector.value) {
                        fields[key].error = true;
                        error = true;
                        fields[key].errorMsg = "Das Feld " + fields[key].fieldName + " darf nicht leer sein";
                    }

                } else if (fields[key].check === "date") {
                    let stringDate = fields[key].selector.value.split(".");
                    let date = new Date(stringDate[2], stringDate[1] - 1, stringDate[0]);

                    if (!(date instanceof Date) || isNaN(date.valueOf())) {
                        fields[key].error = true;
                        error = true;
                        fields[key].errorMsg = "Das Feld " + fields[key].fieldName + " darf nicht leer sein und muss ein korrektes Datumsformat enthalten";

                    }

                } else if (fields[key].check === "email") {
                    const pattern = /^[a-zA-Z0-9._-]*@[a-zA-Z0-9_-]*\.[a-z]+/;
                    if(!pattern.test(String(fields[key].selector.value).toLowerCase())) {
                        fields[key].error = true;
                        error = true;
                        fields[key].errorMsg = "Bitte gebe eine korrekte Email-Adresse ein";
                    }
                } else if (fields[key].check === "clicked") {
                    if(!fields[key].selector.checked) {
                        fields[key].error = true;
                        error = true;
                        fields[key].errorMsg = "Bitte akzeptiere die Datenschutzbedingungen";
                    }
                }

                if (sibling) {
                    sibling.innerHTML = fields[key].errorMsg;
                }
            }
        }
        return error;
    }

    submitForm(event, formElement, baseUrl) {
        event.preventDefault();

        const fields = {
            contact_firstname: {
                selector: this.contactFirstname,
                fieldName: 'Vorname',
                check: 'notEmpty',
                error: false,
                errorMsg: ''
            },
            contact_lastname: {
                selector: this.contactLastname,
                fieldName: 'Nachname',
                check: 'notEmpty',
                error: false,
                errorMsg: ''
            },
            contact_email: {
                selector: this.contactEmail,
                fieldName: 'Email',
                check: 'email',
                error: false,
                errorMsg: ''
            },
            contact_data_privacy: {
                selector: this.contactDataPrivacy,
                fieldName: 'Datenschutz',
                check: 'clicked',
                error: false,
                errorMsg: ''
            }
        };

        let error = this.fieldVerification(fields);

        if (!error) {
            $('#waitModal').modal('show');
            let cityData = $('#sr-city').select2('data');
            let cityName = cityData[0].text.split(",");
            let cityLabelData = $('#sr-city-label').select2('data');
            let cityLabel = cityLabelData[0].id;
            let cityLabelArticleData = $('#sr-city-article').select2('data');
            let cityLabelArticle = cityLabelArticleData[0].id;

            let formData = $(formElement).serializeArray();

            formData = formData.concat([
                {name: "cityName", value: cityName},
                {name: "cityLabel", value: cityLabel},
                {name: "cityLabelArticle", value: cityLabelArticle}
            ]);

            $.ajax({
                url: baseUrl + 'specials/poster/create?XDEBUG_SESSION=IDEA',
                data: formData,
                type: "POST",
                success: (response) => {
                    document.querySelector('#error-msg').innerHTML = '';
                    $('#waitModal').modal('hide');
                    const data = JSON.parse(response);
                    if (data.status === true) {
                        this.toStep3();
                    } else {
                        $.map(data.error, (msg) => document.querySelector('#error-msg').innerHTML += '<p>' + msg + '</p>' );
                    }
                }
            });
        }
    }

    deleteImg(counter) {

        const userImg = document.querySelector('#user-img' + counter);

        // Save all remaining bs64 img data
        const extraImagesContainer = document.querySelector("#userUpload");
        const fileUploads = extraImagesContainer.querySelectorAll('.file-upload .inputUserPicBs64');
        const imgData = [];

        fileUploads.forEach((item, index) => {
            const value = item.getAttribute("value");
            const currentImageCounter = index + 1;

            if (value !== "0" && parseInt(counter) !== currentImageCounter) {
                const imgValues = {
                    "bs64": value,
                    "name": fileUploads[index].getAttribute('data-filename')
                };
                imgData.push(imgValues);
            }

        });

        // delete all attributes of the choosen image
        userImg.setAttribute('src', '');

        // clear all images (inside of preview and upload area
        document.querySelectorAll("#userUpload .filename-preview").forEach((item) => item.remove());
        document.querySelectorAll("#userUpload .file-upload").forEach((item) => item.remove());
        document.querySelectorAll(".logo-preview img").forEach((item) => item.setAttribute("src", ""));

        // set remaining images
        imgData.forEach((image, index) => {

            let countElements = index + 1;

            document.querySelector("#user-img" + countElements).setAttribute("src", image.bs64);

            let htmlElement = "<div class='file-upload flex-column'><input type='file' name='userpic' id='inputUserPic" + countElements + "' class='hide inputImage' accept='image/*' /><input type='hidden' id='inputUserPicDelete' name='userpic_delete' value='0' /><input type='hidden' id='inputUserPicBs64" + countElements + "' class='inputUserPicBs64' data-filename='" + image.name + "' name='inputUserPicBs64" + countElements + "' value='" + image.bs64 + "' /></div>";
            htmlElement += "<div id='filename-preview" + countElements + "' class='filename-preview'>";
            htmlElement += "<span id='filename" + countElements + "' class='filename'></span><span id='userPicMessage" + countElements + "'></span></div>";
            document.querySelector("#userUpload").innerHTML += htmlElement;

            document.getElementById('filename' + countElements).innerHTML = countElements + ". " + image.name;
            const deleteBucket = "<a id='deleteBucket" + countElements + "' class='icon-trash-alt delete-image' data-counter='" + countElements + "'></a>";
            $('#filename-preview' + countElements).append(deleteBucket);

        });

        // Add buttons in upload area with event listener
        const amountUserImages = imgData.length;
        let inputButton = document.querySelector('#inputUserPicButton');
        const uploadButtonContainer = document.querySelector('#uploadButton');

        if (amountUserImages >= 1 && amountUserImages <= 7) {
            if (inputButton !== null) {
                inputButton.remove();
            }
            uploadButtonContainer.innerHTML = "<button class='btn btn-block btn-default file-upload-button' type='button' id='inputUserPicButton'><span class='icon-plus'>+</span>Weitere hinzufügen</button>";
        } else {
            inputButton.remove();
            uploadButtonContainer.innerHTML = "<button class='btn btn-block btn-default file-upload-button' type='button' id='inputUserPicButton'>Datei auswählen</button>";
        }


        if (amountUserImages === 0) {
            extraImagesContainer.innerHTML = this.userUploadTemplate(1);
            let inputButton = document.querySelector('#inputUserPicButton');
            $(inputButton).off('click').on('click', () => inputUserPicButton.click());
            let inputUserPicButton = document.querySelector('#inputUserPic1');
            inputUserPicButton.setAttribute("data-userlogo-counter", "1");

            $(inputUserPicButton).off('click').on('change', (event) => this.selectLogoImg(event.target.getAttribute('data-userlogo-counter')));
        } else {
            extraImagesContainer.innerHTML += this.userUploadTemplate((amountUserImages + 1));
            let inputButton = document.querySelector('#inputUserPicButton');
            let inputUserPicButton = document.querySelector('#inputUserPic' + (amountUserImages + 1));
            inputUserPicButton.setAttribute("data-userlogo-counter", (amountUserImages + 1));
            $(inputButton).off('click').on('click', () => inputUserPicButton.click());
            $(inputUserPicButton).off('click').on('change', (event) => this.selectLogoImg(event.target.getAttribute('data-userlogo-counter')));
        }

        this.deleteBucketEventListener();

    }

    userUploadTemplate(counter) {
        let html = "<div class='file-upload flex-column'>";
        html += "<input type='file' name='userpic' id='inputUserPic" + counter + "' class='hide inputImage' accept='image/*'/>";
        html += "<input type='hidden' id='inputUserPicDelete' name='userpic_delete' value='0'/>";
        html += "<input type='hidden' id='inputUserPicBs64" + counter + "' class='inputUserPicBs64' name='inputUserPicBs64" + counter + "' value='0'/>";
        html += "</div>";
        html += "<div id='filename-preview" + counter + "' class='filename-preview'><span id='filename" + counter + "' class='filename'></span><span id='userPicMessage" + counter + "'></span></div>";

        return html;
    }


    selectLogoImg(counter) {

        $('#user-img' + counter).show();
        // read loaded file and show modal dialog of cropping tool
        const reader = new FileReader();
        const userPicMessage = document.querySelector("#userPicMessage" + counter);
        const file = document.querySelector('#inputUserPic' + counter).files[0];
        const error_modal = document.querySelector('#errorModal');
        const error_modal_msg = document.querySelector('#errorModal .text-center');
        const image = document.querySelector('#userPic_cropped');

        userPicMessage.innerHTML = '';

        let img;
        img = new Image();
        let objectUrl = URL.createObjectURL(file);
        let height;
        let width;
        const selectLogoImgThis = this;

        img.onload = function () {
            URL.revokeObjectURL(objectUrl);
            width = this.width;
            height = this.height;
            if (width < 200 || height < 200) {
                error_modal_msg.innerHTML = "Die Größe des Bildes ist kleiner als 200 x 200 px. <br> Bitte wählen Sie ein größeres Bild aus ";
                $(error_modal).modal("show");
                return false;
            } else {
                reader.onload = (e) => {
                    /* Set image in Poster Preview */
                    image.setAttribute('src', e.target.result);

                    /* Insert Filename into Upload overview and add delete icon */
                    document.getElementById('filename' + counter).innerHTML = counter + ". " + document.getElementById('inputUserPic' + counter).files[0].name;
                    const deleteBucket = "<a id='deleteBucket" + counter + "' class='icon-trash-alt delete-image' data-counter='" + counter + "'></a>";
                    $('#filename-preview' + counter).append(deleteBucket);
                    $('#user-img' + counter).attr('src', e.target.result);
                    document.querySelector('#inputUserPicBs64' + counter).value = e.target.result;
                    document.querySelector('#inputUserPicBs64' + counter).setAttribute('data-filename', document.getElementById('inputUserPic' + counter).files[0].name);

                    selectLogoImgThis.calculatePositionsOfUserLogos();

                    selectLogoImgThis.addExtraImages(counter);

                    // Add eventListener to all delete icons
                    selectLogoImgThis.deleteBucketEventListener();

                    const privacy = document.querySelector('#confirmPrivacy');
                    if(!privacy.checked) {
                        selectLogoImgThis.toSecondStep.setAttribute("disabled", "disabled");
                    }

                    selectLogoImgThis.checkLogoOnCmyk(document.querySelector('#inputUserPicBs64' + counter));
                };
                reader.readAsDataURL(file);
            }
        };

        img.src = objectUrl;
    }

    checkLogoOnCmyk(fileInput) {
        const error_modal = document.querySelector('#errorModal');
        const error_modal_msg = document.querySelector('#errorModal .text-center');

        const data = {
            "imgBs64": fileInput.getAttribute("value")
        };

        $.ajax({
            url: this.baseUrl + 'specials/poster/checkLogo',
            data: data,
            type: "POST",
            success: (response) => {
                const data = JSON.parse(response);
                if (data.cmyk === false) {
                    error_modal_msg.innerHTML = "Die von Ihnen hochgeladene Datei ist in RGB. Dieser Farbraum ist nicht für den Druck vorgesehen und es kann zu farblichen Abweichungen kommen.<br><br> Bitte verwenden Sie eine Datei in CMYK.";
                    $(error_modal).modal("show");
                }
            }
        });
    }

    calculatePositionsOfUserLogos() {
        let previewElement = document.querySelector(".flyer-preview-bg-img");
        let previewUserLogoElements = document.querySelectorAll(".logo-preview");
        let previewElementWidth = previewElement.clientWidth;
        let userLogoWrapperWidth = (previewElementWidth - (previewElementWidth * 17 / 100)) / 7;
        let currentUserLogoPosition = 20;

        previewUserLogoElements.forEach((element) => {
           element.setAttribute("style", "left: " + currentUserLogoPosition + "px;");
           currentUserLogoPosition += userLogoWrapperWidth;
        });

    }

    deleteBucketEventListener() {
        const deleteBuckets = document.querySelectorAll(".delete-image");

        deleteBuckets.forEach((element, index) => {
            $(element).off("click").on("click", () => this.deleteImg(element.getAttribute("data-counter")));
        });
    }

    addExtraImages(counter) {
        let extraImages = document.querySelector('#userUpload');
        let extraImagesCount = document.querySelectorAll('#userUpload .file-upload');
        let extraImagesButton = document.querySelector('#uploadButton button');
        let uploadButtonContainer = document.querySelector('#uploadButton');

        let countElements = extraImagesCount.length + 1;

        if  (countElements > 7) {
            extraImagesButton.remove();
        } else if (countElements > 1 && countElements <= 7) {
            extraImagesButton.remove();
            uploadButtonContainer.innerHTML = "<button class='btn btn-block btn-default file-upload-button' type='button' id='inputUserPicButton'><span class='icon-plus'>+</span>Weitere hinzufügen</button>";
        } else {
            extraImagesButton.remove();
            uploadButtonContainer.innerHTML = "<button class='btn btn-block btn-default file-upload-button' type='button' id='inputUserPicButton'><span class='icon-plus'>+</span>Datei auswählen</button>";
        }

        counter = parseInt(counter);

        if ((counter + 1) === countElements) {

            if (countElements <= 7) {

                extraImages.innerHTML += this.userUploadTemplate(countElements);

                let inputButton = document.querySelector('#inputUserPicButton');
                inputButton.classList.add("further-upload");

                let inputUserPicButton = document.querySelector('#inputUserPic' + countElements);
                inputUserPicButton.setAttribute("data-userlogo-counter", countElements);

                $(inputButton).off('click').on('click', () => inputUserPicButton.click());
                $(inputUserPicButton).off('change').on('change', (event) => this.selectLogoImg(event.target.getAttribute('data-userlogo-counter')));

            }
        }
    }

    toStep3() {
        var step1 = document.getElementById("step-1");
        var step2 = document.getElementById("step-2");
        var step3 = document.getElementById("step-3");
        step2.classList.add("hidden");
        step1.classList.add("hidden");
        step3.classList.remove("hidden");
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    toStep2() {
        var step1 = document.getElementById("step-1");
        var step2 = document.getElementById("step-2");
        var step3 = document.getElementById("step-3");

        let fields = {
            dateStart: {
                selector: this.dateStart,
                fieldName: 'Startdatum',
                check: 'date',
                error: false,
                errorMsg: ''
            },
            cityName: {
                selector: this.cityName,
                fieldName: 'Kommune',
                check: 'notEmpty',
                error: false,
                errorMsg: ''
            },
            cityUrl: {
                selector: this.cityUrl,
                fieldName: 'Webseiten URL',
                check: 'notEmpty',
                error: false,
                errorMsg: ''
            }
        };

        const error = this.fieldVerification(fields);

        if(!error) {
            step1.classList.add("hidden");
            step2.classList.remove("hidden");
            step3.classList.add("hidden");
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        } else {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    }
}

window.PosterGenerator = PosterGenerator;
