/**
 *  Class Jumpmark implements jumpmarks with smooth scroll
 *  For correct functionality the linkwrapper has to contains all <a> links and the
 *  href-attribute has to be the id of the element where you want to scroll.
 *
 *  Expl.
 *  <div class="jumpmarks">
 *         <a href="#change-city-data">{_"Kommunendaten ändern"}</a>
 *  </div>
 *  <legend id="change-city-data">{_"Kommunendaten ändern"}</legend>
 *
 *  <script>
 *      const jumpmarks = new Jumpmarks('.jumpmarks a');
 *  </script>
 */

class Jumpmarks {
    constructor(linkWrapper) {
        const links = document.querySelectorAll(linkWrapper);

        links.forEach(link => {
            link.addEventListener('click', (event) => {
                event.preventDefault();

                $('html, body').animate({
                    scrollTop: $(event.target.getAttribute('href')).offset().top - 235
                }, 500);
            })
        })
    }
}

window.Jumpmarks = Jumpmarks
