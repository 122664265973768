class AwardShow {
    constructor(apiUrl, apiKey) {
        const submitButton = document.querySelector('#submitAwardShowRegistration');

        submitButton.addEventListener('click', () => {
            const form = document.querySelector('#awardForm');
            const finalMessage = document.querySelector('#awardFinalMessage');


            const utility = new Utility();

            const validateName = utility.validateNotEmpty($('#awardshow-name'), 'Pflichtfeld');
            const validatePreName = utility.validateNotEmpty($('#awardshow-prename'), 'Pflichtfeld');
            const validateCity = utility.validateNotEmpty($('#awardshow-city'), 'Pflichtfeld');
            const validateEmail = utility.validateNotEmpty($('#awardshow-mail'), 'Pflichtfeld');
            const validatePhone = utility.validateNotEmpty($('#awardshow-phone'), 'Pflichtfeld');

            const validatePrivacy = utility.validateCheckbox($('#privacy'), 'Pflichtfeld');
            const validatePrivacyPhoto = utility.validateCheckbox($('#privacy-photo'), 'Pflichtfeld');

            if (validateName && validatePreName && validateCity && validateEmail && validatePhone && validatePrivacy && validatePrivacyPhoto) {
                form.classList.add('hidden');
                finalMessage.classList.remove('hidden');

                $.ajax({
                    url: apiUrl + 'awardShow/add?sr_api_key=' + apiKey,
                    method: 'POST',
                    data: $(form).serializeArray(),
                    success: (response) => {
                        console.log(response);
                    }
                });

            } else {
                const message = "Es müssen alle Pflichtfelder ausgefüllt werden";
                utility.showErrorMessageInBanner(2000, message);
                console.log("error");
            }
        });
    }
}

window.AwardShow = AwardShow;
