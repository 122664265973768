class FlyerGenerator {
    constructor(startDate, endDate, baseUrl, apiUrl, apiKey, uploadUrl, fstatePictureFileNames) {

        // if the developerMode is on 1 there is no check of mandatory fields on page change
        // also the pdf is printed after directly after submitting the formular -> for this it is mandatory to comment out the line `unlink($GLOBALS["sr_config"]["upload_path"] . '/files/' . $filename . '.pdf');`
        // in the FlyerGeneratorService.php
        this.developerMode = 0;

        this.flyerPaths = [
            '../../images/flyer_generator/live_vorschau/motiv_01.jpg',
            '../../images/flyer_generator/live_vorschau/motiv_02.jpg',
            '../../images/flyer_generator/live_vorschau/motiv_03.jpg',
            '../../images/flyer_generator/live_vorschau/motiv_04.jpg',
            '../../images/flyer_generator/live_vorschau/motiv_05.jpg',
            '../../images/flyer_generator/live_vorschau/motiv_06.jpg',
            '../../images/flyer_generator/live_vorschau/motiv_07.jpg',
            '../../images/flyer_generator/live_vorschau/motiv_08.jpg',
            '../../images/flyer_generator/live_vorschau/motiv_09.jpg',
            '../../images/flyer_generator/live_vorschau/motiv_10.jpg',
            '../../images/flyer_generator/live_vorschau/motiv_11.jpg',
            '../../images/flyer_generator/live_vorschau/motiv_12.jpg'
        ];

        // array for different picture source based on the choosen motive
        this.kbPictureSourceTextArray = [
            "Klima-Bündnis/Felix Krammer",
            "Klima-Bündnis",
            "Klima-Bündnis/Felix Krammer",
            "Klima-Bündnis",
            "Klima-Bündnis",
            "Klima-Bündnis",
            "Klima-Bündnis",
            "Klima-Bündnis/Felix Krammer",
            "Klima-Bündnis/Felix Krammer",
            "Klima-Bündnis",
            "Klima-Bündnis/Felix Krammer",
            "Klima-Bündnis/Felix Krammer"
        ];
        this.kbPictureSourceText = this.kbPictureSourceTextArray[0];
        this.outsideLeftPictureSource = "Chris Auld";
        this.outsideLeftPictureSourceDisabled = true;

        this.fstatePictureFolder = '../../images/flyer_generator/bundeslaender/';
        this.fstatePictureFileJson = JSON.parse(fstatePictureFileNames);

        this.wizardInit();

        this.initStepOne(startDate, endDate, baseUrl, apiUrl, apiKey);
        this.initStepTwo(baseUrl);
        this.initStepThree(baseUrl);
        this.handlePreviewStepFour();
        this.handlePreviewStepFive();
        this.initPreviewFlyer();

        //this.handleSiteJumpOnFinalPreview();

        this.submitFlyer(baseUrl, apiUrl, apiKey, uploadUrl);
        this.images = [];
        this.choosenFstateImagePaths = [];
        this.welcomeTextHeight = 0;
        this.choosenFstate = 0;
        this.cityNameHeight = 0;

        $('#flyerPreview').on('click', () => {
            if ($('#stepOne').hasClass('hidden') && $('#stepTwo').hasClass('hidden') && $('#stepThree').hasClass('hidden')) {
                $('.pagination .secondPage').click();
                $('.preview-wrapper').removeClass('hidden');
                $('body').addClass('overflow-y-hidden');
            }
            else {
                $('.pagination .firstPage').click();
                $('.preview-wrapper').removeClass('hidden');
                $('body').addClass('overflow-y-hidden');
            }
        });
        $('.preview-wrapper .close').on('click', () => {
            $('.preview-wrapper').addClass('hidden');
            $('body').removeClass('overflow-y-hidden');
        });
    }

    /*
     *      Initialize the Wizard on the top of the page
     */
    // Initialize the wizard for flyer generation
    wizardInit() {
        // Select DOM elements for the next and before buttons
        let nextStepButton = document.querySelector('#toNextStep');
        let beforeStepButton = document.querySelector('#toBeforeStep');
        let fieldError = false;

        // Initialize event listener for step change
        this.initializeEventListenerForStepChange();

        // Event listener for the next button, to show the next page and set the wizard point on active or not
        nextStepButton.addEventListener('click', () => {
            const allSteps = document.querySelectorAll('.flyer-generator-container');
            const currentStep = document.querySelector('.flyer-generator-container:not(.hidden)').getAttribute('data-step');
            const allWizardSteps = document.querySelectorAll('.sr-flyer-step');

            // when moving from step one to two, the picture source need to be updated
            this.setKBPictureSourceText(this.outsideLeftPictureSourceDisabled);


            // Fields that need to be checked - specified for each step
            const fields = {
                1: {
                    city: {
                        selector: document.querySelector('#sr-city'),
                        fieldName: 'Kommune',
                        check: 'notEmpty',
                        error: false,
                        errorMsg: ''
                    },
                    date: {
                        selector: document.querySelector('#sr_datepicker'),
                        fieldName: 'Aktionszeitraum',
                        check: 'date',
                        error: false,
                        errorMsg: ''
                    }
                },
                2: {
                    prename: {
                        selector: document.querySelector('#user-contact-form-surname'),
                        fieldName: 'Vorname',
                        check: 'notEmpty',
                        error: false,
                        errorMsg: ''
                    },
                    surname: {
                        selector: document.querySelector('#user-contact-form-name'),
                        fieldName: 'Nachname',
                        check: 'notEmpty',
                        error: false,
                        errorMsg: ''
                    },
                    street: {
                        selector: document.querySelector('#user-contact-form-street'),
                        fieldName: 'Straße',
                        check: 'notEmpty',
                        error: false,
                        errorMsg: ''
                    },
                    zip: {
                        selector: document.querySelector('#user-contact-form-zip'),
                        fieldName: 'PLZ',
                        check: 'number',
                        error: false,
                        errorMsg: ''
                    },
                    city: {
                        selector: document.querySelector('#user-contact-form-city'),
                        fieldName: 'Ort',
                        check: 'notEmpty',
                        error: false,
                        errorMsg: ''
                    },
                    phone: {
                        selector: document.querySelector('#user-contact-form-phone'),
                        fieldName: 'Telefonnummer',
                        check: 'notEmpty',
                        error: false,
                        errorMsg: ''
                    },
                    email: {
                        selector: document.querySelector('#user-contact-form-email'),
                        fieldName: 'Email',
                        check: 'email',
                        error: false,
                        errorMsg: ''
                    },
                    website: {
                        selector: document.querySelector('#user-contact-form-website'),
                        fieldName: 'Website',
                        check: 'notEmpty',
                        error: false,
                        errorMsg: ''
                    }
                },
                3: {
                    prename: {
                        selector: document.querySelector('#headOfPrename'),
                        fieldName: 'Vorname',
                        check: 'notEmpty',
                        error: false,
                        errorMsg: ''
                    },
                    surname: {
                        selector: document.querySelector('#headOfName'),
                        fieldName: 'Nachname',
                        check: 'notEmpty',
                        error: false,
                        errorMsg: ''
                    }
                },
                6: {
                    prename: {
                        selector: document.querySelector('#ordererPrename'),
                        fieldName: 'Vorname',
                        check: 'notEmpty',
                        error: false,
                        errorMsg: ''
                    },
                    surname: {
                        selector: document.querySelector('#ordererName'),
                        fieldName: 'Nachname',
                        check: 'notEmpty',
                        error: false,
                        errorMsg: ''
                    },
                    email: {
                        selector: document.querySelector('#ordererEmail'),
                        fieldName: 'Email',
                        check: 'email',
                        error: false,
                        errorMsg: ''
                    },
                    privacy: {
                        selector: document.querySelector('#confirmPrivacy'),
                        fieldName: 'Datenschutz',
                        check: 'clicked',
                        error: false,
                        errorMsg: ''
                    },
                }
            };

            if (!this.developerMode) {
                fieldError = this.fieldVerification(fields, currentStep);
            }

            if (!fieldError) {
                // Handle specific actions for certain steps
                if (parseInt(currentStep) === 3) {
                    this.welcomeTextHeight = $('.introducing-text')[0].clientHeight;
                }
                if (parseInt(currentStep) === 3) {
                    $('#writingHint').modal('show');
                }
                if (parseInt(currentStep) === 5) {
                    document.querySelector('#toNextStep').classList.add('hidden');
                    document.querySelector('#sendFlyer').classList.remove('hidden');
                } else {
                    document.querySelector('#toNextStep').innerHTML = "Weiter";
                }

                // Show/hide steps based on the current step
                allSteps.forEach((item) => {
                    let step = parseInt(item.getAttribute('data-step'));
                    let nextStep = parseInt(currentStep) + 1;

                    if (step === nextStep) {
                        item.classList.remove('hidden');
                    } else {
                        item.classList.add('hidden');
                    }

                    if (nextStep === 1) {
                        beforeStepButton.classList.add('hidden');
                    } else {
                        beforeStepButton.classList.remove('hidden');
                    }
                });

                // Update styling for wizard steps based on the current step
                allWizardSteps.forEach((item) => {
                    let step = parseInt(item.getAttribute('data-step'));
                    let nextStep = parseInt(currentStep) + 1;

                    if (step <= nextStep) {
                        item.classList.remove('btn-default');
                        item.classList.remove('disabled');
                        item.classList.add('btn-primary');
                    } else {
                        item.classList.add('btn-default');
                        item.classList.add('disabled');
                        item.classList.remove('btn-primary');
                    }
                });
            }

            // Reinitialize event listener for step change
            this.initializeEventListenerForStepChange();

        });

        // Event listener for the back button
        beforeStepButton.addEventListener('click', () => {
            const allSteps = document.querySelectorAll('.flyer-generator-container');
            const currentStep = document.querySelector('.flyer-generator-container:not(.hidden)').getAttribute('data-step');
            const allWizardSteps = document.querySelectorAll('.sr-flyer-step');

            this.setKBPictureSourceText(this.outsideLeftPictureSourceDisabled);

            // Additional actions for step 6
            if (parseInt(currentStep) === 6) {
                document.querySelector('#toNextStep').classList.remove('hidden');
                document.querySelector('#sendFlyer').classList.add('hidden');
            }

            // Show/hide steps based on the current step
            allSteps.forEach((item) => {
                let step = parseInt(item.getAttribute('data-step'));
                let nextStep = parseInt(currentStep) - 1;

                if (step === nextStep) {
                    item.classList.remove('hidden');
                } else {
                    item.classList.add('hidden');
                }

                if (nextStep === 1) {
                    beforeStepButton.classList.add('hidden');
                } else {
                    beforeStepButton.classList.remove('hidden');
                }
            });

            // Update styling for wizard steps based on the current step
            allWizardSteps.forEach((item) => {
                let step = parseInt(item.getAttribute('data-step'));
                let nextStep = parseInt(currentStep) - 1;

                if (step <= nextStep) {
                    item.classList.remove('btn-default');
                    item.classList.remove('disabled');
                    item.classList.add('btn-primary');
                } else {
                    item.classList.add('btn-default');
                    item.classList.add('disabled');
                    item.classList.remove('btn-primary');
                }
            });

            // Reinitialize event listener for step change
            this.initializeEventListenerForStepChange();
        });
    }


    // Initialize event listener for step change in the flyer generation wizard
    initializeEventListenerForStepChange() {
        // Select all primary wizard step links
        const wizardLinks = document.querySelectorAll('.sr-flyer-step.btn-primary');

        // Attach a click event listener to each step link's parent element
        wizardLinks.forEach((item) => {
            item.parentElement.addEventListener('click', () => {
                // Select necessary DOM elements
                const beforeStepButton = document.querySelector('#toBeforeStep');
                const clickedStep = item.getAttribute('data-step');
                const allSteps = document.querySelectorAll('.flyer-generator-container');
                const nextButton = document.querySelector('#toNextStep');
                const sendButton = document.querySelector('#sendFlyer');

                // Iterate through all steps and show/hide them based on the clicked step
                allSteps.forEach((item) => {
                    let step = parseInt(item.getAttribute('data-step'));

                    if (step === parseInt(clickedStep)) {
                        item.classList.remove('hidden');
                    } else {
                        item.classList.add('hidden');
                    }

                    // Hide or show the "Before" button based on the clicked step
                    if (parseInt(clickedStep) === 1) {
                        beforeStepButton.classList.add('hidden');
                    } else {
                        beforeStepButton.classList.remove('hidden');
                    }
                });

                // Hide or show the "Next" and "Send" buttons based on the clicked step
                if (parseInt(clickedStep) === 6) {
                    nextButton.classList.add('hidden');
                    sendButton.classList.remove('hidden');
                } else {
                    nextButton.classList.remove('hidden');
                    sendButton.classList.add('hidden');
                }
            });
        });
    }


    /*
     *      Initialize the important functions for the first step
     */
    // Initialize step one of the flyer generation wizard
    initStepOne(startDate, endDate, baseUrl, apiUrl, apiKey) {
        // Construct the URL for AGS (official municipality key) search
        let agsRequestURL = apiUrl + 'city/ags_search?sr_api_key=' + apiKey;
        const formThis = this;

        // Event listener for selecting a background image in the dropdown
        $('#select-motiv').on('select2:select', (e) => {
            // Update all flyer background images based on the selected motif
            document.querySelectorAll('.flyer-background-image').forEach((item) => {
                item.setAttribute("src", this.flyerPaths[e.params.data.id]);

                // set correct picture source based on the choosen motive
                this.kbPictureSourceText = this.kbPictureSourceTextArray[e.params.data.id];
            });
        });

        // Set the minimum and maximum date for the date picker
        let minDate = new Date(startDate + "T00:00:00");
        let maxDate = new Date(endDate + "T00:00:00");

        // Initialize Select2 for custom dropdowns
        $('.select').select2();

        // Initialize the date picker with specified configurations
        $('#sr_datepicker').datepicker({
            changeMonth: true,
            changeYear: false,
            format: "d.m.yyyy",
            daysOfWeekHighlighted: "6,0",
            language:   "de",
            calenderWeeks: true,
            startDate:  minDate,
            endDate: maxDate,
        });

        // Initialize Select2 for the city dropdown with AJAX support
        $('#sr-city').select2({
            minimumInputLength: 3,
            ajax: {
                url: agsRequestURL,
                dataType: 'json',
                data: function(params) {
                    return {
                        sr_country_id: 57,
                        sr_cityname: params.term
                    };
                },
                processResults: function(data) {
                    let resVal = [];
                    $.each(data.result, function(index,item) {
                        resVal.push({
                            'id' : item.uuid,
                            'text': item.cityname,
                            'ags': item.ags
                        });
                    });
                    return {
                        results: resVal
                    };
                }
            }
        }).on('select2:select', function() {
            // Update state information based on the selected city
            let cityData = $('#sr-city').select2('data');
            const fstate_id = cityData[0].ags.substring(0, 2);
            formThis.choosenFstate = fstate_id;
            formThis.choosenFstateImagePaths = [];
            // Update regional logos based on the selected state
            document.querySelectorAll('.stepTwo .backsiteRegionalLogos-preview img').forEach((item, index) => {
                // clear image if some already exists
                item.setAttribute('src', '');
                if (formThis.fstatePictureFileJson[fstate_id][index]) {
                    item.setAttribute('src', '../../images/flyer_generator/bundeslaender/' + fstate_id + '/' + formThis.fstatePictureFileJson[fstate_id][index]);
                    formThis.choosenFstateImagePaths.push(fstate_id + '/' + formThis.fstatePictureFileJson[fstate_id][index]);
                }
            });

            // Update regional logos in preview
            document.querySelectorAll('.pageTwo .backsiteRegionalLogos-preview img').forEach((item, index) => {
                // clear image if some already exists
                item.setAttribute('src', '');
                if (formThis.fstatePictureFileJson[fstate_id][index]) {
                    item.setAttribute('src', '../../images/flyer_generator/bundeslaender/' + fstate_id + '/' + formThis.fstatePictureFileJson[fstate_id][index]);
                }
            });
        });

        // Implement logo upload functionality
        const uploadButton = document.querySelector('#logosFirstStepPic_button');
        const hiddenUploadInput = document.querySelector('#logosFirstStepPic1');
        let imageData = {
            step: 'stepOne',
            counter: "1",
            uploadArea: '#logosFirstStep_upload',
            uploadAreaButton: '#logosFirstStepPic_uploadButton',
            allUploadBs64: '.file-upload .logosFirstStepPic_bs64',
            previewPictureId: '.logo-user-img',
            pictureMessageId: '#logosFirstStepPic_message',
            inputFileId: 'logosFirstStepPic',
            filenamePreviewId: '#logosFirstStepPic_filename-preview',
            outputFilenameId: 'logosFirstStepPic_filename',
            inputBase64: '#logosFirstStepPic_bs64',
            positionCalculate: true,
            calculatePositionsOfUserLogos: {
                previewElement: document.querySelector(".flyer-preview-bg-img.stepOne .user-logos"),
                previewUserLogoElements: document.querySelectorAll(".stepOne .logo-preview"),
                previewUserLogoImages: document.querySelectorAll(".stepOne .logo-preview img"),
                endPreviewUserLogoElements: document.querySelectorAll(".pageOne .logo-preview"),
                endPreviewUserLogoimages: document.querySelectorAll(".pageOne .logo-preview img"),
                amountOfLogos: 4,
                currentUserLogoPosition: 0,
                amountOfLogosInOneLine: 4,
                logoWidth: 50,
                logoDirection: 'left'
            },
            cmykCheck: true,
        };
        this.handlePictureUpload(baseUrl, uploadButton, hiddenUploadInput, imageData, 5000000);

        // Handle preview for step one
        this.handlePreviewStepOne();
    }


    /*
     *      Initialize the important functions for the second step
     */
    initStepTwo(baseUrl) {
        const uploadButtonLocal = document.querySelector('#localLogosBacksite_button');
        const hiddenUploadInputLocal = document.querySelector('#localLogosBacksite1');
        let imageDataLocal = {
            step: 'stepTwo',
            counter: "1",
            uploadArea: '#localLogosBacksite_upload',
            uploadAreaButton: '#localLogosBacksite_uploadButton',
            allUploadBs64: '.file-upload .localLogosBacksite_bs64',
            previewPictureId: '.backsiteLocalLogos-user-img',
            pictureMessageId: '#localLogosBacksite_message',
            inputFileId: 'localLogosBacksite',
            filenamePreviewId: '#localLogosBacksite_filename-preview',
            outputFilenameId: 'localLogosBacksite_filename',
            inputBase64: '#localLogosBacksite_bs64',
            positionCalculate: false,
            calculatePositionsOfUserLogos: {
                previewElement: document.querySelector(".flyer-preview-bg-img.stepTwo .backsiteLocalLogos"),
                previewUserLogoElements:document.querySelectorAll(".backsiteLocalLogos-preview"),
                previewUserLogoImages:document.querySelectorAll(".backsiteLocalLogos-preview img"),
                amountOfLogos: 8,
                currentUserLogoPosition: 0,
                amountOfLogosInOneLine: 4,
                logoWidth: 60,
                logoDirection: 'left'
            },
            cmykCheck: true,
        };
        this.handlePictureUpload(baseUrl, uploadButtonLocal, hiddenUploadInputLocal, imageDataLocal, 5000000);

        this.handlePreviewStepTwo();
    }

    /*
     *      Initialize the important functions for the third step
     */
    initStepThree(baseUrl) {
        const uploadButtonPortrait = document.querySelector('#headOfPicture_button');
        const hiddenUploadInputPortrait = document.querySelector('#headOfPicture1');
        let headOfIntroQuote = $('#headOfIntroQuote');
        let headOfWelcomeText = $('#headOfWelcomeText');
        let headOfExplanation = $('#headOfExplanation');
        let headOfPrename = $('#headOfPrename');

        let imageDataHeadOfPicture = {
            step: 'stepThree',
            counter: "1",
            uploadArea: '#headOfPicture_upload',
            uploadAreaButton: '#headOfPicture_uploadButton',
            allUploadBs64: '.file-upload .headOfPicture_bs64',
            previewPictureId: '.portrait-photo-user-img',
            pictureMessageId: '#headOfPicture_message',
            inputFileId: 'headOfPicture',
            filenamePreviewId: '#headOfPicture_filename-preview',
            outputFilenameId: 'headOfPicture_filename',
            inputBase64: '#headOfPicture_bs64',
            positionCalculate: false,
            calculatePositionsOfUserLogos: {
                previewElement: document.querySelector(".flyer-preview-bg-img.stepThree"),
                previewUserLogoElements:document.querySelectorAll(".portrait-photo-preview"),
                previewUserLogoImages:document.querySelectorAll(".portrait-photo-preview img"),
                amountOfLogos: 1,
                currentUserLogoPosition: 20,
                amountOfLogosInOneLine: 1,
                logoWidth: 30,
                logoDirection: 'right'
            },
            cmykCheck: true,
        };
        this.handlePictureUpload(baseUrl, uploadButtonPortrait, hiddenUploadInputPortrait, imageDataHeadOfPicture, 5000000);

        const uploadButtonSignature = document.querySelector('#headOfSignature_button');
        const hiddenUploadInputSignature = document.querySelector('#headOfSignature1');
        let imageDataSignature = {
            step: 'stepThree',
            counter: "1",
            uploadArea: '#headOfSignature_upload',
            uploadAreaButton: '#headOfSignature_uploadButton',
            allUploadBs64: '.file-upload .headOfSignature_bs64',
            previewPictureId: '.signature-photo',
            pictureMessageId: '#headOfSignature_message',
            inputFileId: 'headOfSignature',
            filenamePreviewId: '#headOfSignature_filename-preview',
            outputFilenameId: 'headOfSignature_filename',
            inputBase64: '#headOfSignature_bs64',
            positionCalculate: false,
            calculatePositionsOfUserLogos: {
                previewElement: document.querySelector(".flyer-preview-bg-img.stepThree"),
                previewUserLogoElements:document.querySelectorAll(".headOfSignature-preview"),
                previewUserLogoImages:document.querySelectorAll(".headOfSignature-preview img"),
                amountOfLogos: 1,
                currentUserLogoPosition: 20,
                amountOfLogosInOneLine: 1,
                logoWidth: 30,
                logoDirection: 'right'
            },
            cmykCheck: false,
        };
        this.handlePictureUpload(baseUrl, uploadButtonSignature, hiddenUploadInputSignature, imageDataSignature, 5000000);

        this.handlePreviewStepThree(baseUrl, imageDataHeadOfPicture, imageDataSignature);

        this.characterCounter(70, headOfIntroQuote, $('#headOfIntroQuote-character-count'));
        this.characterCounter(1000, headOfWelcomeText, $('#headOfWelcomeText-character-count'));
        this.characterCounter(200, headOfExplanation, $('#headOfExplanation-character-count'));

    }

    outOfViewEventListener() {
        const headOfWelcomeText = document.querySelector('#headOfWelcomeText');
        const headOfExplanation = document.querySelector('#headOfExplanation');
        const headOfPrename = document.querySelector('#headOfPrename');
        const headOfSignature = document.querySelectorAll('.signature-photo1');
        const headOfIntroQuote = document.querySelector('#headOfIntroQuote');

        headOfIntroQuote.addEventListener('keyup', () => {
            const errorText = document.querySelector('#greetingTextErrorMessage');

            if (this.checkIfElementIsOutOfView()) {
                errorText.classList.remove('hidden');
            } else {
                errorText.classList.add('hidden');
            }
        });


        // Check if Element is out of view
        headOfWelcomeText.addEventListener('keyup', () => {
            const errorText = document.querySelector('#greetingTextErrorMessage');

            if (this.checkIfElementIsOutOfView()) {
                errorText.classList.remove('hidden');
            } else {
                errorText.classList.add('hidden');
            }
        });

        headOfPrename.addEventListener('keyup', () => {
            const errorText = document.querySelector('#greetingTextErrorMessage');

            if (this.checkIfElementIsOutOfView()) {
                errorText.classList.remove('hidden');
            } else {
                errorText.classList.add('hidden');
            }
        });

        headOfExplanation.addEventListener('keyup', () => {
            const errorText = document.querySelector('#greetingTextErrorMessage');

            if (this.checkIfElementIsOutOfView()) {
                errorText.classList.remove('hidden');
            } else {
                errorText.classList.add('hidden');
            }
        });

        headOfSignature[0].addEventListener('load', () => {
            const errorText = document.querySelector('#greetingTextErrorMessage');

            if (this.checkIfElementIsOutOfView()) {
                errorText.classList.remove('hidden');
            } else {
                errorText.classList.add('hidden');
            }
        });


        $('#headOfSignature_delete').on('change', () => {
            const errorText = document.querySelector('#greetingTextErrorMessage');

            if (this.checkIfElementIsOutOfView()) {
                errorText.classList.remove('hidden');
            } else {
                errorText.classList.add('hidden');
            }
        });
    }

    checkIfElementIsOutOfView() {
        const windowHeight = document.querySelector('.flyer-preview-bg-img.stepThree').clientHeight;

        const topWelcomeText = document.querySelector('.introducing-text').offsetTop;
        const heightWelcomeText = document.querySelector('.introducing-text').offsetHeight;
        const topExplanation = document.querySelector('.head-of-description').offsetTop;
        const heightExplanation = document.querySelector('.head-of-description').offsetHeight;
        const topPrename = document.querySelector('.headOf-prename').offsetTop;
        const heightPrename = document.querySelector('.headOf-prename').offsetHeight;
        const topSignature = document.querySelector('.signature-photo1').offsetTop;
        const heightSignature = document.querySelectorAll('.signature-photo1')[0].clientHeight;

        let height = 0;

        if (heightExplanation > 0) {
            height += topExplanation + heightExplanation;
        } else if (heightPrename > 0) {
            height += topPrename + heightPrename;
        } else if (heightSignature > 0) {
            height += topSignature + heightSignature;
        } else if (heightWelcomeText > 0) {
            height += topWelcomeText + heightWelcomeText;
        }

        if (height >= windowHeight) {
            return true;
        } else {
            return false;
        }
    }

    /*
     *      Initialize the important functions for the sixth step
     */
    // Function to initialize the preview of the flyer based on pagination
    initPreviewFlyer() {
        // Select all page items in the pagination
        let pageItems = document.querySelectorAll('.pagination .page-item');

        // Add click event listeners to each page item
        pageItems.forEach((item) => {
            item.addEventListener('click', () => {
                // Remove 'active' class from all page items
                pageItems.forEach((element) => {
                    element.classList.remove('active');
                });

                // Add 'active' class to the clicked page item
                item.classList.add('active');

                // Show/hide flyer parts based on the clicked page item
                if (item.classList.contains('firstPage')) {
                    document.querySelector('#previewFlyer .partOne').classList.remove('hidden');
                    document.querySelector('#previewFlyer .partTwo').classList.add('hidden');
                } else {
                    document.querySelector('#previewFlyer .partOne').classList.add('hidden');
                    document.querySelector('#previewFlyer .partTwo').classList.remove('hidden');
                }
            });
        });
    }


    /*
     *      Set event listener for image upload
     */
    handlePictureUpload(baseUrl, uploadButton, hiddenUploadInput, imageData, maxImageSize) {
        uploadButton.addEventListener('click', () => hiddenUploadInput.click());
        hiddenUploadInput.addEventListener('change', () => {
            this.selectImage(baseUrl, imageData, maxImageSize);
        });
    }

    /*
     *      get the image from the browser and covert it to base64
     */
    // This function is responsible for handling the selection of an image, processing it, and updating the UI.
    selectImage(baseUrl, imageData, maxImageSize) {
        // Show the preview picture element corresponding to the current counter
        $(imageData.previewPictureId + imageData.counter).show();
        const form = this;
        // Create a new FileReader to read the selected image file
        const reader = new FileReader();

        // Get the message element for user picture information
        const userPicMessage = document.querySelector(imageData.pictureMessageId + imageData.counter);

        // Get the selected image file from the input file element
        const file = document.querySelector('#' + imageData.inputFileId + imageData.counter).files[0];

        // Get references to error modal elements
        const error_modal = document.querySelector('#errorModal');
        const error_modal_msg = document.querySelector('#errorModal .text-center');

        // Create an object representing the selected image and push it to the 'images' array
        let imageElement = {
            'name': imageData.inputFileId,
            'counter': imageData.counter,
            'file': file
        };

        // Clear the user picture message
        userPicMessage.innerHTML = '';

        // Create an image element to load and check the dimensions of the selected image
        let img;
        img = new Image();

        // Create an object URL for the selected image
        let objectUrl = URL.createObjectURL(file);

        let height;
        let width;

        // Reference to 'this' inside the onload function
        const selectLogoImgThis = this;

        // Event handler when the image is loaded
        img.onload = function () {
            // Revoke the object URL to free up resources
            URL.revokeObjectURL(objectUrl);
            width = this.width;
            height = this.height;

            // Check if the file size exceeds the maximum allowed size
            if (file.size > maxImageSize) {
                error_modal_msg.innerHTML = "Das hochgeladene Bild überschreitet die maximale Dateigröße.<br>Bitte lade ein Bild mit einer geringeren Dateigröße hoch.";
                $(error_modal).modal("show");
                return false;
            }

            // Check if the image dimensions are smaller than 200x200 pixels
            if (width < 200 || height < 200) {
                error_modal_msg.innerHTML = "Die Größe des Bildes ist kleiner als 200 x 200 px. <br> Bitte wählen Sie ein größeres Bild aus ";
                $(error_modal).modal("show");
                return false;
            } else {
                // If the image passes the size checks, read the image file as a data URL
                reader.onload = (e) => {
                    // Update the UI with information about the selected image
                    document.querySelector('#' + imageData.outputFilenameId + imageData.counter).innerHTML = imageData.counter + ". " + document.querySelector('#' + imageData.inputFileId + imageData.counter).files[0].name;

                    // Add a delete button to the image preview
                    const deleteBucket = "<a id='deleteBucket" + imageData.counter + "' class='icon-trash-alt delete-image " + imageData.step + "' data-counter='" + imageData.counter + "' data-array='" + imageData.inputFileId + "'></a>";
                    $(imageData.filenamePreviewId + imageData.counter).append(deleteBucket);

                    // Set the source of the preview picture element to the data URL
                    $(imageData.previewPictureId + imageData.counter).each((index, item) => {
                        $(item).attr('src', e.target.result);
                    });

                    // Set the base64 value of the input field to the data URL
                    document.querySelector(imageData.inputBase64 + imageData.counter).value = e.target.result;
                    document.querySelector(imageData.inputBase64 + imageData.counter).setAttribute('data-filename', document.querySelector('#' + imageData.inputFileId + imageData.counter).files[0].name);

                    // Calculate and update the positions of user logos
                    if(imageData.positionCalculate) {
                        selectLogoImgThis.calculatePositionsOfUserLogos(imageData.calculatePositionsOfUserLogos);
                    }

                    // Add extra images if any
                    selectLogoImgThis.addExtraImages(baseUrl, imageData, maxImageSize);

                    // Attach event listener for deleting the image
                    selectLogoImgThis.deleteBucketEventListener(baseUrl, imageData, maxImageSize);

                    // Check the logo for CMYK format if required
                    if (imageData.cmykCheck) {
                        selectLogoImgThis.checkLogoOnCmyk(baseUrl, document.querySelector(imageData.inputBase64 + imageData.counter));
                    }
                };

                // Read the image file as a data URL
                reader.readAsDataURL(file);
            }
            form.images.push(imageElement);
        };

        // Set the source of the image element to the object URL
        img.src = objectUrl;
    }



    // This function calculates the positions of user logos based on the provided image and logo data.
    calculatePositionsOfUserLogos(imageData) {
        // Width of the preview element of the image
        let previewElementWidth = imageData.previewElement.clientWidth;

        // Width of the wrapper for each user logo in a row
        let userLogoWrapperWidth = ((previewElementWidth - (imageData.logoWidth * imageData.amountOfLogosInOneLine)) / (imageData.amountOfLogosInOneLine - 1 ));

        // Current position for the first user logo in the row
        let currentPos = imageData.currentUserLogoPosition;

        // Counter for the number of logos in the current row
        let imageLineCounter = 0;

        // Iterate through all preview elements of the user logos
        imageData.previewUserLogoElements.forEach((element) => {
            // Count the number of logos in the current row
            imageLineCounter = imageLineCounter + 1;

            // Set the position of the current user logo in the preview image
            element.setAttribute("style", imageData.logoDirection + ": " + currentPos + "px;");

            // Update the position for the next user logo in the row
            currentPos += userLogoWrapperWidth;

            // Check if the maximum number of logos in a row is reached
            if(imageData.amountOfLogosInOneLine === imageLineCounter) {
                // Set the position for the first user logo in the next row
                currentPos = imageData.currentUserLogoPosition;
                // Reset the counter for the number of logos in the row
                imageLineCounter = 0;
            }
        });

        // Check if there are additional logos at the end
        if(imageData.endPreviewUserLogoElements) {
            // Set the position for the first additional user logo
            currentPos = imageData.currentUserLogoPosition;

            // Iterate through all additional preview elements of the user logos at the end
            imageData.endPreviewUserLogoElements.forEach((element) => {
                // Set the position of the current additional user logo
                element.setAttribute("style", imageData.logoDirection + ": " + currentPos + "px;");

                // Update the position for the next additional user logo
                currentPos += userLogoWrapperWidth;
            });
        }
    }

    /*
     *      show or not show the further upload button for more picture uploads based on the amount of logos which can be uploaded
     *      also add name of the picture and delete icon to a specific area in html
     */
    // This function manages the addition of extra images to the upload area.
    addExtraImages(baseUrl, imageData, maxImageSize) {
        // Get references to elements related to extra images
        let extraImages = document.querySelector(imageData.uploadArea);
        let extraImagesCount = document.querySelectorAll(imageData.uploadArea + ' .file-upload');
        let extraImagesButton = document.querySelector(imageData.uploadAreaButton + ' button');
        let uploadButtonContainer = document.querySelector(imageData.uploadAreaButton);

        // Calculate the current count of extra images
        let countElements = extraImagesCount.length + 1;

        // Check if the current count exceeds the maximum allowed user logos
        if  (countElements > imageData.calculatePositionsOfUserLogos.amountOfLogos) {
            extraImagesButton.remove();
        } else if (countElements > 1 && countElements <= imageData.calculatePositionsOfUserLogos.amountOfLogos) {
            // Remove the extra images button and replace it with a button to add more images
            extraImagesButton.remove();
            uploadButtonContainer.innerHTML = "<button class='btn btn-block btn-default file-upload-button' type='button' id='" + imageData.inputFileId + "_button'><span class='icon-plus'>+</span>Weitere hinzufügen</button>";
        } else {
            // Remove the extra images button and replace it with the default file selection button
            extraImagesButton.remove();
            uploadButtonContainer.innerHTML = "<button class='btn btn-block btn-default file-upload-button' type='button' id='" + imageData.inputFileId + "_button'><span class='icon-plus'>+</span>Datei auswählen</button>";
        }

        // Get the counter as an integer
        let counter = parseInt(imageData.counter);

        // Check if the counter matches the current count of elements
        if ((counter + 1) === countElements) {

            // Check if the current count is within the allowed user logos limit
            if (countElements <= imageData.calculatePositionsOfUserLogos.amountOfLogos) {

                // Append a new user upload template to the extra images area
                extraImages.innerHTML += this.userUploadTemplate(countElements, imageData.inputFileId);

                // Get references to the new input button and user picture input
                let inputButton = document.querySelector('#' + imageData.inputFileId + '_button');
                inputButton.classList.add("further-upload");

                let inputUserPicButton = document.querySelector('#' + imageData.inputFileId + countElements);
                inputUserPicButton.setAttribute("data-userlogo-counter", countElements);

                // Attach click event for the new input button to trigger the user picture input
                $(inputButton).off('click').on('click', () => inputUserPicButton.click());

                // Attach change event for the new user picture input to trigger the image selection
                $(inputUserPicButton).off('change').on('change', (event) => {
                    imageData.counter = event.target.getAttribute('data-userlogo-counter');
                    this.selectImage(baseUrl, imageData, maxImageSize);
                });
            }
        }

        // check if the closing text should be visible or not
        this.checkIfStepTwoClosingTextShouldBeVisible(imageData.counter);
    }


    /*
     *      function to delete a picture
     */
    // This function handles the deletion of an image, updates the UI, and manages the array of images.
    deleteImg(baseUrl, counter, imageData, maxImageSize) {

        // Get the user image element based on the provided counter
        const userImg = document.querySelectorAll(imageData.previewPictureId + counter);

        // Remove the image from the array of files
        this.images.forEach((item, index) => {
            if (item.name === imageData.inputFileId && parseFloat(item.counter) === parseFloat(counter)) {
                this.images.splice(index, 1);
            }
        });

        // Reset the counters after deleting the image from the array
        let imageCounter = 1;
        this.images.forEach((item, index) => {
            if (item.name === imageData.inputFileId) {
                item.counter = imageCounter;
                imageCounter++;
            }
        });

        // Save all remaining base64 image data
        const extraImagesContainer = document.querySelector(imageData.uploadArea);
        const fileUploads = extraImagesContainer.querySelectorAll(imageData.allUploadBs64);
        const imgData = [];
        const flyerParent = this;
        fileUploads.forEach((item, index) => {
            const value = item.getAttribute("value");
            const currentImageCounter = index + 1;

            if (value !== "0" && parseInt(counter) !== currentImageCounter) {
                const imgValues = {
                    "bs64": value,
                    "name": fileUploads[index].getAttribute('data-filename')
                };
                imgData.push(imgValues);
            }
        });

        // Delete all attributes of the chosen image
        userImg.forEach((item) => item.setAttribute('src', ''));

        // Clear all images (inside preview and upload area)
        document.querySelectorAll(imageData.uploadArea + " ." + imageData.inputFileId + "_filename-preview").forEach((item) => item.remove());
        document.querySelectorAll(imageData.uploadArea + " .file-upload").forEach((item) => item.remove());

        // Clear preview images for user logos
        imageData.calculatePositionsOfUserLogos.previewUserLogoImages.forEach((item) => item.setAttribute("src", ""));

        // Clear preview images for additional user logos if present
        if(imageData.calculatePositionsOfUserLogos.endPreviewUserLogoimages) {
            imageData.calculatePositionsOfUserLogos.endPreviewUserLogoimages.forEach((item) => item.setAttribute("src", ""));
        }

        // Set the remaining images
        imgData.forEach((image, index) => {
            let countElements = index + 1;
            document.querySelectorAll(imageData.previewPictureId + countElements).forEach((item) => item.setAttribute("src", image.bs64));

            document.querySelector(imageData.uploadArea).innerHTML += flyerParent.userUploadTemplate(countElements, imageData.inputFileId, true, image);

            document.getElementById(imageData.outputFilenameId + countElements).innerHTML = countElements + ". " + image.name;
            const deleteBucket = "<a id='deleteBucket" + countElements + "' class='icon-trash-alt delete-image " + imageData.step + "' data-counter='" + countElements + "'></a>";

            // Append delete button for each previewed filename
            $('#' + imageData.outputFilenameId + "-preview" + countElements).append(deleteBucket);
        });

        // Add buttons in the upload area with event listener
        const amountUserImages = imgData.length;
        let inputButton = document.querySelector('#' + imageData.inputFileId + '_button');
        const uploadButtonContainer = document.querySelector(imageData.uploadAreaButton);

        // Update the upload area buttons based on the remaining user images
        if (amountUserImages >= 1 && amountUserImages <= imageData.calculatePositionsOfUserLogos.amountOfLogos) {
            if (inputButton !== null) {
                inputButton.remove();
            }
            uploadButtonContainer.innerHTML = "<button class='btn btn-block btn-default file-upload-button' type='button' id='" + imageData.inputFileId + "_button'><span class='icon-plus'>+</span>Weitere hinzufügen</button>";
        } else if (imageData.calculatePositionsOfUserLogos.amountOfLogos === 1 && inputButton === null) {
            document.querySelector(imageData.uploadArea).innerHTML = flyerParent.userUploadTemplate(1, imageData.inputFileId);
            uploadButtonContainer.innerHTML = "<button class='btn btn-block btn-default file-upload-button' type='button' id='" + imageData.inputFileId + "_button'>Datei auswählen</button>";
        } else {
            inputButton.remove();
            uploadButtonContainer.innerHTML = "<button class='btn btn-block btn-default file-upload-button' type='button' id='" + imageData.inputFileId + "_button'>Datei auswählen</button>";
        }

        // check if the closing text should be visible or not
        this.checkIfStepTwoClosingTextShouldBeVisible(amountUserImages);

        // Handle the case when there are no remaining user images
        if (amountUserImages === 0) {
            extraImagesContainer.innerHTML = this.userUploadTemplate(1, imageData.inputFileId);
            let inputButton = document.querySelector('#' + imageData.inputFileId + '_button');
            $(inputButton).off('click').on('click', () => inputUserPicButton.click());
            let inputUserPicButton = document.querySelector('#' + imageData.inputFileId + '1');
            inputUserPicButton.setAttribute("data-userlogo-counter", "1");

            $(inputUserPicButton).off('click').on('change', (event) => {
                imageData.counter = event.target.getAttribute('data-userlogo-counter');
                this.selectImage(baseUrl, imageData, maxImageSize);
            });
        } else {
            // Append a new user upload template for the next available counter
            extraImagesContainer.innerHTML += this.userUploadTemplate((amountUserImages + 1), imageData.inputFileId);
            let inputButton = document.querySelector('#' + imageData.inputFileId + '_button');
            let inputUserPicButton = document.querySelector('#' + imageData.inputFileId + (amountUserImages + 1));
            inputUserPicButton.setAttribute("data-userlogo-counter", (amountUserImages + 1));
            $(inputButton).off('click').on('click', () => inputUserPicButton.click());
            $(inputUserPicButton).off('click').on('change', (event) => {
                imageData.counter = event.target.getAttribute('data-userlogo-counter');
                this.selectImage(baseUrl, imageData, maxImageSize);
            });
        }

        this.outOfViewEventListener();
        $('#headOfSignature_delete').trigger('change');

        // Add event listeners for deleting images in the updated UI
        this.deleteBucketEventListener(baseUrl, imageData, maxImageSize);
    }


    /*
     *      set the event listener for the delet button also after some pictures got deleted
     */
    deleteBucketEventListener(baseUrl, imageData, maxImageSize) {
        const deleteBuckets = document.querySelectorAll(imageData.uploadArea + " .delete-image." + imageData.step);

        deleteBuckets.forEach((element) => {
            $(element).off("click").on("click", () => this.deleteImg(baseUrl, element.getAttribute("data-counter"), imageData, maxImageSize));
        });
    }

    /*
     *      html template for another picture upload form
     */
    userUploadTemplate(counter, idName, withFileName = false, imageData = {}) {
        let html = "<div class='file-upload flex-column'>";
        html += "<input type='file' name='" + idName + "' id='" + idName + counter + "' class='hide inputImage' accept='image/*'/>";
        html += "<input type='hidden' id='" + idName + "_delete' name='" + idName + "_delete' value='0'/>";

        if (withFileName === true) {
            html += "<input type='hidden' id='" + idName + "_bs64" + counter + "' class='" + idName + "_bs64' name='" + idName + "_bs64" + counter + "' value='" + imageData.bs64 + "' data-filename='" + imageData.name + "' /></div>";
        } else {
            html += "<input type='hidden' id='" + idName + "_bs64" + counter + "' class='" + idName + "_bs64' name='" + idName + "_bs64" + counter + "' value='0'/>";
        }
        html += "</div>";
        html += "<div id='" + idName + "_filename-preview" + counter + "' class='" + idName + "_filename-preview'><span id='" + idName + "_filename" + counter + "' class='filename'></span><span id='" + idName + "_message" + counter + "'></span></div>";

        return html;
    }

    /*
     *      function to implement the text and logos from the formular into the preview part on the left side of step one
     */
    // This function handles the preview for step one of the form.
    handlePreviewStepOne() {
        const formThis = this;

        // Event listener for handling the city selection in the form
        $('#sr-city-label').on('select2:select', function() {
            let cityData = $('#sr-city').select2('data');
            let cityName = cityData[0].text.split(",");

            if (cityData[0].text) {
                // Update the preview city labels based on the selected city
                document.querySelectorAll('.flyer-preview-city').forEach((item) => {
                    let cityLabelData = $('#sr-city-label').select2('data');
                    let cityLabel = cityLabelData[0].id;
                    let cityLabelArticle = cityLabelData[0].element.dataset.srArticleDe;

                    if (!cityLabel || cityLabel === "0"){
                        item.innerHTML = cityName[0];
                    } else {
                        item.innerHTML = cityLabelArticle + " " + cityLabel + " " + cityName[0];
                    }

                    // Update the height of the city name in the preview
                    formThis.cityNameHeight = $('.city-preview')[0].clientHeight;
                });
            }
        });

        // Event listener for handling the city selection in the form
        $('#sr-city').on('select2:select', function() {
            let data = $('#sr-city').select2('data');
            let cityName = data[0].text.split(",");
            let cityLabelData = $('#sr-city-label').select2('data');
            let cityLabel = cityLabelData[0].id;
            let cityLabelArticle = cityLabelData[0].element.dataset.srArticleDe;

            let cityText = cityName[0];

            // Update the preview city labels based on the selected city
            document.querySelectorAll('.flyer-preview-city').forEach((item) => {
                if (cityLabel) {
                    cityText = cityLabelArticle + " " + cityLabel + " " + cityName[0];
                }
                item.innerHTML = cityText;
            });

            // Update the information about the selected city in the form
            document.querySelector('#cityName').innerHTML = cityText + ' ist dabei.';
            formThis.cityNameHeight = $('.city-preview')[0].clientHeight;
        });

        // Event listener for handling the date selection in the form
        $('#sr_datepicker').datepicker().on('changeDate', function() {
            // Update the start date in the preview based on the selected date
            $('.flyer-preview-date-start').each((index, item) => {
                let date = ($(this).datepicker("getDate").getDate()) +
                    "." +
                    (($(this).datepicker("getDate").getMonth() + 1)) +
                    ".";
                $(item).html(date);
                document.querySelector('#cycle-period .start').innerHTML = date;
            });

            // Calculate the end date based on the selected start date
            const date = new Date($(this).datepicker("getDate"));
            const dateAfterTwentyOneDays = new Date(date.setDate(date.getDate() + 20));

            // Update the end date in the preview based on the calculated date
            $('.flyer-preview-date-end').each((index, item) => {
                let date = (dateAfterTwentyOneDays.getDate()) +
                    "." +
                    ((dateAfterTwentyOneDays.getMonth() + 1)) +
                    "." +
                    dateAfterTwentyOneDays.getFullYear();
                $(item).html(date);
                document.querySelector('#cycle-period .end').innerHTML = date;
            });
        });
    }


    /*
     *      function to implement the text and logos from the formular into the preview part on the left side of step two
     */
    // Function to handle the preview for step two of the form
    handlePreviewStepTwo() {
        const mainThis = this;

        // Selecting various form elements
        let userSalutation = $('#user-contact-form-salutation');
        let userSurname = document.querySelector('#user-contact-form-surname');
        let userName = document.querySelector('#user-contact-form-name');
        let userDepartment = document.querySelector('#user-contact-form-department');
        let userStreet = document.querySelector('#user-contact-form-street');
        let userZip = document.querySelector('#user-contact-form-zip');
        let userTown = document.querySelector('#user-contact-form-city');
        let userEmail = document.querySelector('#user-contact-form-email');
        let userWebsite = document.querySelector('#user-contact-form-website');
        let userPrePhone = document.querySelector('#user-contact-form-pre-phone');
        let userPhone = document.querySelector('#user-contact-form-phone');
        let userPhotoSource = document.querySelector('#user-contact-form-sources');

        // Initialize Select2 for userSalutation
        userSalutation.select2();

        // Event listener for handling city selection
        $('#sr-city').on('select2:select', function() {
            let data = $('#sr-city').select2('data');
            let cityName = data[0].text.split(",");
            mainThis.fillFlyerFromForm('.user-contact-city', cityName[0]);
        });

        // Event listener for handling salutation selection
        userSalutation.on('select2:select', function() {
            let data = $('#user-contact-form-salutation').select2('data');
            let previewSalutation = document.querySelectorAll('.user-contact-salutation');

            // Clear existing classes and fill flyer based on selection
            previewSalutation.forEach((item) => item.classList.remove('mr-3'));
            mainThis.fillFlyerFromForm('.user-contact-salutation', "");

            if (data[0].id !== "1") {
                previewSalutation.forEach((item) => item.classList.add('mr-3'));
                mainThis.fillFlyerFromForm('.user-contact-salutation', data[0].text);
            }
        });

        // Event listeners for various form fields to update the flyer preview dynamically
        userSurname.addEventListener('keyup', () => mainThis.fillFlyerFromForm('.user-contact-firstname', userSurname.value));
        userName.addEventListener('keyup', () => mainThis.fillFlyerFromForm('.user-contact-lastname', userName.value));
        userDepartment.addEventListener('keyup', () => mainThis.fillFlyerFromForm('.user-contact-department', userDepartment.value));
        userStreet.addEventListener('keyup', () => mainThis.fillFlyerFromForm('.user-contact-street', userStreet.value));
        userZip.addEventListener('keyup', () => mainThis.fillFlyerFromForm('.user-contact-zip', userZip.value));
        userTown.addEventListener('keyup', () => mainThis.fillFlyerFromForm('.user-contact-town', userTown.value));

        userPhone.addEventListener('keyup', () => mainThis.fillFlyerFromForm('.user-contact-phone', userPrePhone.value + " " + userPhone.value));
        userEmail.addEventListener('keyup', () => mainThis.fillFlyerFromForm('.user-contact-email', userEmail.value));
        userWebsite.addEventListener('keyup', () => mainThis.fillFlyerFromForm('.user-contact-website', userWebsite.value));

        this.setKBPictureSourceText(this.outsideLeftPictureSourceDisabled);
    }

    // in this method the kb picture source will be set based on the chosen motive
    // It will also enable or disable the outside left input field on page two if the greeting word is the default or an own
    setKBPictureSourceText(disabled = true) {
        const userPhotoSource = document.querySelector('#user-contact-form-sources');
        const userPhotoSource2 = document.querySelector('#user-contact-form-sources-2');
        let sourceFixText = " rechts: " + this.kbPictureSourceText + ", Innenseite: Neonbrand";
        const mainThis = this;

        if (disabled) {
            userPhotoSource.value = this.outsideLeftPictureSource;
            userPhotoSource.disabled = true;
            sourceFixText = "Außenseite links: " + this.outsideLeftPictureSource + " " + sourceFixText;
        } else {
            userPhotoSource.disabled = false;
        }

        this.fillFlyerFromForm('.backsitePhotoSources', "Bildnachweis: " + sourceFixText);
        userPhotoSource2.value = this.kbPictureSourceText;

        userPhotoSource.addEventListener('keyup', () => {
            if (userPhotoSource.value.length === 0) {
                mainThis.fillFlyerFromForm('.backsitePhotoSources', "Bildnachweis: " + sourceFixText);
            } else {
                mainThis.fillFlyerFromForm('.backsitePhotoSources', "Bildnachweis: Außenseite links: " + userPhotoSource.value + ', ' + sourceFixText);
            }
        });
    }

    checkIfStepTwoClosingTextShouldBeVisible(amountImage) {
        const currentStep = document.querySelector('.flyer-generator-container:not(.hidden)').getAttribute('data-step');
        const closingText = document.querySelectorAll('.backsite-contact-logo-wrapper .closing');

        if (currentStep === "2") {
            if (amountImage === "0" || amountImage === 0) {
                closingText.forEach((item) => {
                    item.classList.add('hide');
                });
            } else {
                closingText.forEach((item) => {
                    item.classList.remove('hide');
                });
            }
        }
    }

    // Function to handle the preview for step three of the form
    handlePreviewStepThree(baseUrl, imageDataHeadOfPicture, imageDataSignature) {
        const mainThis = this;

        // Selecting various form elements related to step three
        let chooseOwnText = document.querySelector('#toggle-grusswort');
        let formStepThree = document.querySelector('#formStepThree');
        let headOfQuote = document.querySelector("#headOfIntroQuote");
        let headOfIntroductionText = document.querySelector("#headOfWelcomeText");
        let headOfPrename = document.querySelector("#headOfPrename");
        let headOfName = document.querySelector("#headOfName");
        let headOfDescription = document.querySelector("#headOfExplanation");

        $(formStepThree).hide();
        // implement the event listener for the toggle button for the grußwort
        $(chooseOwnText).on('change', () => {
           if (chooseOwnText.checked) {

               this.outsideLeftPictureSourceDisabled = false;
               $('#user-contact-form-sources').val("");
               $(formStepThree).show();

               // delete all values
               // delete quote
               let previewQuote = document.querySelectorAll('.portrait-quote');
               headOfQuote.value = "";
               previewQuote.forEach((item) => {
                  item.innerHTML = "";
               });

               // delete portrait
               this.deleteImg(baseUrl, imageDataHeadOfPicture.counter, imageDataHeadOfPicture, 5000000);

               // delete introtext
               let previewIntroductionText = document.querySelectorAll('.introducing-text');
               headOfIntroductionText.value = "";
               previewIntroductionText.forEach((item) => {
                   item.innerHTML = "";
               });

               // delete signature image
               this.deleteImg(baseUrl, imageDataSignature.counter, imageDataSignature, 5000000);

               // delete pre name and name
               let previewPrename = document.querySelectorAll('.headOf-prename');
               headOfPrename.value = "";
               previewPrename.forEach((item) => {
                   item.innerHTML = "";
               });
               let previewName = document.querySelectorAll('.headOf-name');
               headOfName.value = "";
               previewName.forEach((item) => {
                   item.innerHTML = "";
               });

               // delete description
               let previewDescription = document.querySelectorAll('.head-of-description');
               headOfDescription.value = "";
               previewDescription.forEach((item) => {
                   item.innerHTML = "";
               });

               // Trigger fields with character counter to reset the counter after the default text
               $(headOfQuote).trigger('keyup');
               $(headOfIntroductionText).trigger('keyup');
               $(headOfDescription).trigger('keyup');
               this.outOfViewEventListener();
           } else {
               const errorText = $('#greetingTextErrorMessage');
               errorText.addClass('hidden');

               $(formStepThree).hide();
               this.outsideLeftPictureSourceDisabled = true;
               // set default values
               // set quote
               let previewQuote = document.querySelectorAll('.portrait-quote');
               headOfQuote.value = "Für mich ist das Fahrrad nicht nur Arbeitsgerät. Es ist Lebensinhalt.";
               previewQuote.forEach((item) => {
                   item.innerHTML = "Für mich ist das Fahrrad nicht nur Arbeitsgerät. Es ist Lebensinhalt.";
               });

               // delete portrait
               let previewPortrait = document.querySelectorAll('.portrait-photo-user-img1');
               previewPortrait.forEach((item) => {
                   item.setAttribute('src', '../../images/flyer_generator/portrait_vorschau.jpg');
               });

               // delete introtext
               let previewIntroductionText = document.querySelectorAll('.introducing-text');
               headOfIntroductionText.value = "Das Fahrrad ist aus meinem Leben nicht wegzudenken und ich glaube, es ist wichtig Menschen zu motivieren mehr Rad zu fahren – aus Überzeugung. Als aktiver Beitrag zum Klimaschutz aber auch zur eigenen Lebensqualität und Gesundheit. Genau das schafft die wunderbare Aktion STADTRADELN für Groß und Klein.<br><br>Das Fahrrad hat die perfekte Reisegeschwindigkeit – die Umwelt, die Eindrücke werden wahrgenommen. Man schießt nicht an Cafés, Shops, Wohngebieten vorbei, man entdeckt sie, findet kleine Nischen, schöne Orte im Grünen, Lieblingsstrecken und Lieblingsorte. Man nimmt Natur bewusster wahr. Es ist ein Lebensgefühl, mein Lebensgefühl und ich würde mir wünschen, dass so viele wie möglich an diesen 21 Tagen, aber auch darüber hinaus, einen Großteil ihrer Alltagswege mit dem Rad zurücklegen und Spaß daran haben – ob allein, mit Freunden oder der Familie. Wir sehen uns auf dem Rad!";
               previewIntroductionText.forEach((item) => {
                   item.innerHTML = "Das Fahrrad ist aus meinem Leben nicht wegzudenken und ich glaube, es ist wichtig Menschen zu motivieren mehr Rad zu fahren – aus Überzeugung. Als aktiver Beitrag zum Klimaschutz aber auch zur eigenen Lebensqualität und Gesundheit. Genau das schafft die wunderbare Aktion STADTRADELN für Groß und Klein.<br><br>Das Fahrrad hat die perfekte Reisegeschwindigkeit – die Umwelt, die Eindrücke werden wahrgenommen. Man schießt nicht an Cafés, Shops, Wohngebieten vorbei, man entdeckt sie, findet kleine Nischen, schöne Orte im Grünen, Lieblingsstrecken und Lieblingsorte. Man nimmt Natur bewusster wahr. Es ist ein Lebensgefühl, mein Lebensgefühl und ich würde mir wünschen, dass so viele wie möglich an diesen 21 Tagen, aber auch darüber hinaus, einen Großteil ihrer Alltagswege mit dem Rad zurücklegen und Spaß daran haben – ob allein, mit Freunden oder der Familie. Wir sehen uns auf dem Rad!";
               });

               // delete signature image
               let previewSignature = document.querySelectorAll('.signature-photo1');
               previewSignature.forEach((item) => {
                   item.setAttribute('src', '../../images/flyer_generator/signature.png');
               });

               // set pre name and name
               let previewPrename = document.querySelectorAll('.headOf-prename');
               headOfPrename.value = "John";
               previewPrename.forEach((item) => {
                   item.innerHTML = "John";
               });
               let previewName = document.querySelectorAll('.headOf-name');
               headOfName.value = "Degenkolb";
               previewName.forEach((item) => {
                   item.innerHTML = "Degenkolb";
               });

               // delete description
               let previewDescription = document.querySelectorAll('.head-of-description');
               headOfDescription.value = "Profi-Radsportler im Team dsm-firmenich PostNL";
               previewDescription.forEach((item) => {
                   item.innerHTML = "Profi-Radsportler im Team dsm-firmenich PostNL";
               });

               $('#user-contact-form-sources').val(this.outsideLeftPictureSource);

           }
        });

        // Event listeners for various form fields to update the flyer preview dynamically
        headOfQuote.addEventListener('keyup', () => mainThis.fillFlyerFromForm('.portrait-quote', headOfQuote.value, true));

        headOfIntroductionText.addEventListener('keyup', () => {
            mainThis.fillFlyerFromForm('.introducing-text', headOfIntroductionText.value, true);
            mainThis.welcomeTextHeight = $('.introducing-text')[0].clientHeight;
        });

        headOfPrename.addEventListener('keyup', () => mainThis.fillFlyerFromForm('.headOf-prename', headOfPrename.value, true));
        headOfName.addEventListener('keyup', () => mainThis.fillFlyerFromForm('.headOf-name', headOfName.value, true));

        headOfDescription.addEventListener('keyup', () => mainThis.fillFlyerFromForm('.head-of-description', headOfDescription.value, true));

        // Triggering the 'change' event for headOfIntroductionText to update its height
        $(headOfIntroductionText).trigger('change');
    }

    // Function to check and adjust the text height dynamically
    checkTextHeight(height, outputElement, outputId, inputElement) {
        const mainThis = this;

        if (outputElement.clientHeight <= height) {
            mainThis.fillFlyerFromForm(outputId, inputElement.value, true);
        } else {
            outputElement.innerText = outputElement.innerText.slice(0, -1);
            $(inputElement).val(outputElement.innerText.slice(0, -1));
            mainThis.checkTextHeight(height, outputElement, outputId, inputElement);
        }
    }

    // Function to handle the preview for step four of the form
    handlePreviewStepFour() {
        const mainThis = this;

        // Selecting various form elements related to step four
        let titelOne = $('#ownTitle_1');
        let textOne = $('#ownText_1');
        let titelTwo = $('#ownTitle_2');
        let textTwo = $('#ownText_2');
        let titelThree =$('#ownTitle_3');
        let textThree = $('#ownText_3');
        let titelFour = $('#ownTitle_4');
        let textFour = $('#ownText_4');
        let titelFive = $('#ownTitle_5');
        let textFive = $('#ownText_5');

        // Event listeners for various form fields to update the flyer preview dynamically
        titelOne.on('keyup', () => mainThis.fillFlyerFromForm('.ownTitel1', titelOne.val()));
        textOne.on('keyup', () => mainThis.fillFlyerFromForm('.ownText1', textOne.val()));
        titelTwo.on('keyup', () => mainThis.fillFlyerFromForm('.ownTitel2', titelTwo.val()));
        textTwo.on('keyup', () => mainThis.fillFlyerFromForm('.ownText2', textTwo.val()));
        titelThree.on('keyup', () => mainThis.fillFlyerFromForm('.ownTitel3', titelThree.val()));
        textThree.on('keyup', () => mainThis.fillFlyerFromForm('.ownText3', textThree.val()));
        titelFour.on('keyup', () => mainThis.fillFlyerFromForm('.ownTitel4', titelFour.val()));
        textFour.on('keyup', () => mainThis.fillFlyerFromForm('.ownText4', textFour.val()));
        titelFive.on('keyup', () => mainThis.fillFlyerFromForm('.ownTitel5', titelFive.val()));
        textFive.on('keyup', () => mainThis.fillFlyerFromForm('.ownText5', textFive.val()));

        // Character counter for each textarea
        mainThis.characterCounter(300, textOne, $('#ownText_1-character-count'));
        mainThis.characterCounter(300, textTwo, $('#ownText_2-character-count'));
        mainThis.characterCounter(200, textThree, $('#ownText_3-character-count'));
        mainThis.characterCounter(200, textFour, $('#ownText_4-character-count'));
        mainThis.characterCounter(200, textFive, $('#ownText_5-character-count'));

        // Triggering keyup events to initialize
        titelOne.keyup();
        textOne.keyup();
        titelTwo.keyup();
        textTwo.keyup();
        titelThree.keyup();
        textThree.keyup();
        titelFour.keyup();
        textFour.keyup();
        titelFive.keyup();
        textFive.keyup();

    }

    // Function to handle the preview for step five of the form
    handlePreviewStepFive() {
        const mainThis = this;

        const textToggle = document.querySelector('#toggle-text');
        const textHiddenInputField = document.querySelector('#teaserPhotoText');

        let text = "Lade dir die STADTRADELN-App herunter, tracke deine Strecken und hilf die Radinfrastruktur vor deiner Haustür zu verbessern!";
        $(textToggle).on('change', () => {
            if (textToggle.checked) {
                text = "Laden Sie die STADTRADELN-App herunter, tracken Sie Ihre Strecken und helfen Sie die Radinfrastruktur vor Ihrer Haustür zu verbessern!";
                textHiddenInputField.value = text;
            } else {
                text = "Lade dir die STADTRADELN-App herunter, tracke deine Strecken und hilf die Radinfrastruktur vor deiner Haustür zu verbessern!";
                textHiddenInputField.value = text;
            }
            mainThis.fillFlyerFromForm('.previewTeaserPhotoText', text);
        });
    }

    // This function handles the site jump on the final preview by adding an event listener to different parts of the final preview.
    // It identifies the step to be edited based on the clicked element, then shows or hides flyer elements and adjusts the appearance
    // of wizard steps accordingly. The button text is also updated to "Weiter."
    handleSiteJumpOnFinalPreview() {
        // Event listener for clicking on different parts of the final preview
        document.querySelectorAll('.endPreviewPage ').forEach((siteElement) => {
            siteElement.addEventListener('click', () => {
                let editStep = parseInt(siteElement.getAttribute('data-edit-step'));

                if (!isNaN(editStep)) {
                    // Show/hide flyer elements based on the selected step
                    const allSteps = document.querySelectorAll('.flyer-generator-container');
                    const allWizardSteps = document.querySelectorAll('.sr-flyer-step');

                    allSteps.forEach((item) => {
                        let step = parseInt(item.getAttribute('data-step'));

                        if (step === editStep) {
                            item.classList.remove('hidden');
                        } else {
                            item.classList.add('hidden');
                        }
                    });

                    allWizardSteps.forEach((item) => {
                        let step = parseInt(item.getAttribute('data-step'));

                        if (step <= editStep) {
                            item.classList.remove('btn-default');
                            item.classList.remove('disabled');
                            item.classList.add('btn-primary');
                        } else {
                            item.classList.add('btn-default');
                            item.classList.add('disabled');
                            item.classList.remove('btn-primary');
                        }
                    });
                }
                document.querySelector('#toNextStep').innerHTML = "Weiter";
            });
        });
    }

    /*
     *      collect all data and send it via ajax to the backend
     */
    submitFlyer(baseUrl, apiUrl, apiKey, uploadUrl) {
        document.querySelector('#sendFlyer').addEventListener('click', (event) => {
            event.preventDefault();
            let fieldError;
            const currentStep = document.querySelector('.flyer-generator-container:not(.hidden)').getAttribute('data-step');

            // Field which need to be checked - first parameter is the step of the fields
            const fields = {
                6: {
                    prename: {
                        selector: document.querySelector('#ordererPrename'),
                        fieldName: 'Vorname',
                        check: 'notEmpty',
                        error: false,
                        errorMsg: ''
                    },
                    surname: {
                        selector: document.querySelector('#ordererName'),
                        fieldName: 'Nachname',
                        check: 'notEmpty',
                        error: false,
                        errorMsg: ''
                    },
                    email: {
                        selector: document.querySelector('#ordererEmail'),
                        fieldName: 'Email',
                        check: 'email',
                        error: false,
                        errorMsg: ''
                    },
                    privacy: {
                        selector: document.querySelector('#confirmPrivacy'),
                        fieldName: 'Datenschutz',
                        check: 'clicked',
                        error: false,
                        errorMsg: ''
                    },
                }
            };

            if (!this.developerMode) {
                fieldError = this.fieldVerification(fields, currentStep);
            }

            if (!fieldError) {
                $('#waitModal').modal('show');
                let cityData = $('#sr-city').select2('data');
                let cityName = cityData[0].text.split(",");

                let cityLabelData = $('#sr-city-label').select2('data');
                let cityLabelArticle = cityLabelData[0].element.dataset.srArticleDe;

                let grusswortCheckbox = document.querySelector('#toggle-grusswort');

                let firstFormArray = $('#formStepOne').serializeArray();
                let secondFormArray = $('#formStepTwo').serializeArray();
                let thridFormArray = $('#formStepThree').serializeArray();
                let fourthFormArray = $('#formStepFour').serializeArray();
                let fifthFormArray = $('#formStepFive').serializeArray();
                let seventhFormArray = $('#formStepSix').serializeArray();
                let privacyFormArray = $('#formPrivacy').serializeArray();


                firstFormArray = this.removeBase64FromArray(firstFormArray, "logosFirstStepPic_bs64");
                secondFormArray = this.removeBase64FromArray(secondFormArray, "localLogosBacksite_bs64");
                thridFormArray = this.removeBase64FromArray(thridFormArray, "headOfPicture_bs64");
                thridFormArray = this.removeBase64FromArray(thridFormArray, "headOfSignature_bs64");

                let formData = new FormData();
                formData.append("firstStep", JSON.stringify(firstFormArray));
                formData.append("secondStep", JSON.stringify(secondFormArray));
                formData.append("thirdStep", JSON.stringify(thridFormArray));
                formData.append("fourthStep", JSON.stringify(fourthFormArray));
                formData.append("fifthStep", JSON.stringify(fifthFormArray));
                formData.append("seventhStep", JSON.stringify(seventhFormArray));
                formData.append("privacy", JSON.stringify(privacyFormArray));
                formData.append("cityName", cityName[0]);
                formData.append("cityLabel", cityLabelArticle);
                formData.append('heightWelcomeText', this.welcomeTextHeight);
                formData.append('choosenFstate', this.choosenFstate);
                formData.append('heightCityName', this.cityNameHeight);
                formData.append('toggle-grusswort', grusswortCheckbox.checked);
                formData.append('user-contact-picture-source-2', $('#user-contact-form-sources-2').val());
                formData.append('user-contact-picture-source', $('#user-contact-form-sources').val());

                this.images.forEach((item) => {
                    formData.append(item.name + item.counter, item.file);
                });

                this.choosenFstateImagePaths.forEach((item, index) => {
                   formData.append('fstateImagePath' + index, item);
                });

                $.ajax({
                    url: apiUrl + 'flyer/generate?sr_api_key=' + apiKey + "&XDEBUG_SESSION=IDEA",
                    data: formData,
                    type: "POST",
                    processData: false,
                    contentType: false,
                    success: (response) => {
                        if (this.developerMode) {
                            window.open(uploadUrl + '/files/' + response.filename);
                        }

                        $('#waitModal').modal('hide');
                        if (response.status === true) {
                            if (!this.developerMode) {
                                $('#flyer-gen-headline').html("Vielen Dank!");
                                $('.wizard').addClass('hidden');
                                $('.flyer-generator-container').addClass('hidden');
                                $('.row-buttons').addClass('hidden');
                                $('#stepFinal').removeClass('hidden');
                            }
                        } else {
                            $('#errorModal').modal('show');
                            $.map(response.error, (msg) => document.querySelector('#errorModal .text-center').innerHTML += '<p>' + msg + '</p>' );
                        }
                    }
                });
            }
        });
    }

    /*
     *      helper function to count remaining characters of an input field or a text field
    */
    characterCounter(maxAreaLength, textAreaId, characterCountId) {
        textAreaId.keyup(function() {
            textAreaId.val(textAreaId.val().substring(0, maxAreaLength));
            let textLength = textAreaId.val().length;
            let textRemaining = maxAreaLength - textLength;
            characterCountId.html(textRemaining);
        }).keyup();
    }

    /*
     *      helper function to delete a specific id from a specific array
     */
    removeBase64FromArray(array, base64Id) {
        array.forEach((item, index) => {
            if (item.name.includes(base64Id)) {
                array.splice(index, 1);
            }
        });

        return array;
    }

    /*
     *      helper function to check if the uploaded picture got cmyk or rgb colors
     */
    checkLogoOnCmyk(baseUrl, fileInput) {
        const error_modal = document.querySelector('#errorModal');
        const error_modal_msg = document.querySelector('#errorModal .text-center');

        const data = {
            "imgBs64": fileInput.getAttribute("value")
        };

        $.ajax({
            url: baseUrl + 'specials/poster/checkLogo',
            data: data,
            type: "POST",
            success: (response) => {
                const data = JSON.parse(response);
                if (data.cmyk === false) {
                    error_modal_msg.innerHTML = "Die von Ihnen hochgeladene Datei ist in RGB. Dieser Farbraum ist nicht für den Druck vorgesehen und es kann zu farblichen Abweichungen kommen.<br><br> Bitte verwenden Sie eine Datei in CMYK.";
                    $(error_modal).modal("show");
                }
            }
        });
    }

    /*
     *      Helper function to fill the preview from the form at a specific point
     */
    fillFlyerFromForm(idInPreviewFlyer, idInFormFlyer, activateLineBreakForTextarea = true) {
        document.querySelectorAll(idInPreviewFlyer).forEach((item) => {
            if (activateLineBreakForTextarea) {
                let textArray = idInFormFlyer.split(/\r?\n/);
                item.innerHTML = textArray.join('<br>');
            } else {
                item.innerHTML = idInFormFlyer;
            }
        });
    }

    /*
     *      helper function to check if there is an empty field on the specific page
     */
    // Function to verify form field values
    fieldVerification(fields, currentStep) {
        let error = false;
        let returnError = false;

        // Iterate through each step in the fields
        for (const step in fields) {
            if (step === currentStep) {
                // Iterate through each field in the current step
                for (const key in fields[step]) {
                    // Get the error message field for the current field
                    let errorMsgField = fields[step][key].selector.parentElement.querySelector('.sr_form_feedback');
                    if (errorMsgField === null) {
                        errorMsgField = fields[step][key].selector.parentElement.parentElement.parentElement.querySelector('.sr_form_feedback');
                    }

                    // Attach a keyup event listener to trigger field verification checks
                    $(fields[step][key].selector).on('keyup', () => {
                        this.fieldVerificationChecks(fields[step][key], error, errorMsgField);
                    });

                    $(fields[step][key].selector).on('change', () => {
                        this.fieldVerificationChecks(fields[step][key], error, errorMsgField);
                    });

                    // Perform field verification checks and update error status
                    error = this.fieldVerificationChecks(fields[step][key], error, errorMsgField);
                    if (error) {
                        returnError = true;
                    }
                }
            }
        }

        return returnError;
    }


    /*
     *      helper function to check if the content of a specific field matches to a field check
     */
    // Function to perform verification checks on a specific form field
    fieldVerificationChecks(field, error, errorMsgField) {
        // Check if the field is not empty
        if (field.check === "notEmpty") {
            if (!field.selector.value) {
                field.error = true;
                error = true;
                field.errorMsg = "Das Feld " + field.fieldName + " darf nicht leer sein.";
            } else {
                field.error = false;
                error = false;
            }
        }
        // Check if the field is a number
        else if (field.check === "number") {
            if (!$.isNumeric(field.selector.value)) {
                field.error = true;
                error = true;
                field.errorMsg = "Bitte geben Sie eine PLZ ein.";
            } else {
                field.error = false;
                error = false;
            }
        }
        // Check if the field is a valid date
        else if (field.check === "date") {
            let stringDate = field.selector.value.split(".");
            let date = new Date(stringDate[2], stringDate[1] - 1, stringDate[0]);

            if (!(date instanceof Date) || isNaN(date.valueOf())) {
                field.error = true;
                error = true;
                field.errorMsg = "Das Feld " + field.fieldName + " darf nicht leer sein und muss ein korrektes Datumsformat enthalten";
            } else {
                field.error = false;
                error = false;
            }
        }
        // Check if the field is a valid email address
        else if (field.check === "email") {
            const pattern = /^[a-zA-Z0-9._-]*@[a-zA-Z0-9_-]*\.[a-z]+/;
            if (!pattern.test(String(field.selector.value).toLowerCase())) {
                field.error = true;
                error = true;
                field.errorMsg = "Bitte gebe eine korrekte Email-Adresse ein";
            } else {
                field.error = false;
                error = false;
            }
        }
        // Check if a checkbox is clicked (for mandatory checkboxes)
        else if (field.check === "clicked") {
            if (!field.selector.checked) {
                field.error = true;
                error = true;
                field.errorMsg = "Diese Checkbox ist ein Pflichtfeld";
            } else {
                field.error = false;
                error = false;
            }
        }

        // Update the visibility and content of the error message field
        if (error) {
            errorMsgField.classList.remove('hidden');
        } else {
            errorMsgField.innerHTML = "";
            errorMsgField.classList.add('hidden');
        }

        if (errorMsgField) {
            errorMsgField.innerHTML = field.errorMsg;
        }
        return error;
    }

}

window.FlyerGenerator = FlyerGenerator;
