class CitySignupLateRegistration {
    constructor(citySignupInitialization ,citySignupForm, citySignupValidation, data, translationData) {
        this.citySignupForm = citySignupForm;
        this.citySignupInitialization = citySignupInitialization;
        this.citySignupValidation = citySignupValidation;
        this.data = data;
        this.translationData = translationData;

        this.citySignupValidation = new CitySignupValidation(this.translationData);

    }

    /** geprüft */
    initializeLateRegistrationForm(tabOffset) {

        let addSubCityMain = $('#addSubCityMain');
        const submitButton = $('.button_big_c');

        addSubCityMain.html(this.data.subHtml.html);

        let startDate = $("#sr_creg_start_date");
        const form = this;

        if ($("#sr_creg_radar_enabled").is(":checked")) {
            $("#sr_creg_radar_enabled_" + tabOffset).attr("readonly", "readonly");
            $("#sr_creg_radar_enabled_label_" + tabOffset).css("opacity", 0.5);
        }

        startDate.datepicker("destroy").datepicker({
            changeMonth: true,
            changeYear: true,
            format: "dd.mm.yyyy",
            daysOfWeekHighlighted: "6,0",
            language:   "de",
            calenderWeeks: true,
        }).change(function() {
            form.citySignupForm.update_enddate();
        }).datepicker("setdate", this.data.parentStartDate).change();
        this.citySignupForm.check_datepicker(startDate);

        this.citySignupForm.citySignupShoppingBasketInit.initializeShoppingBasketScrollFunction();

        this.citySignupInitialization.initializeRadarDatepicker(this.data.radarMinDate, this.data.radarMaxEndDate, this.citySignupForm, this.citySignupValidation, this.data.tabOffset);

        this.citySignupForm.citySignupShoppingBasketInit.initTrigger();
        this.citySignupForm.citySignupShoppingBasketInit.initBasketTrigger();
        this.citySignupForm.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.update_basket_new($('#sr_kommunenregistrierung_sub_' + tabOffset),'lk_attendence');

        $("input[id='sr_creg_population_" + tabOffset + "']").off('change').on('change', function (e) {
            form.citySignupForm.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.update_basket_lk_attendence($('#sr_creg_is_kb_member_' + tabOffset), 'lk_attendence');
            form.citySignupForm.citySignupShoppingBasketInit.citySignupShoppingBasketUpdate.citySignupShoppingBasketCalculation.ajaxCalculatePromotion($('#sr_creg_fstate').val(), $(this).val())
        });

        submitButton.on('click', () => {
            this.submit_nachmeldung('create', tabOffset);
        })
    }

    /** geprüft */
    submit_nachmeldung(action, tabOffset)
    {
        let form = this;
        this.citySignupForm.trim_all();

        let radarValid = this.citySignupValidation.validate_radar_date_range(tabOffset)

        if (!radarValid) {
            form.citySignupValidation.showErrorMessage(2000, this.translationData.radarDateFailure);
            return false;
        }

        if (!this.citySignupValidation.validate_all().result)
        {
            $("#sr_commune_reg_feedback").html("<span class='sr_form_feedback'>" + form.translationData.mandatoryField + "</span>");
            return false;
        } else {
            $("#sr_commune_reg_feedback").html("");
        }

        if (citySignUpConfig.g_formAJAXSaveRequest) {
            form.citySignupValidation.showErrorMessage(2000, this.translationData.dataAlreadySend);
            //g_formAJAXSaveRequest.abort();
            return;
        }

        if (action !== "save" && !confirm(form.translationData.confirmText1 + "\n" + form.translationData.confirmText2))
        {
            return false;
        }
        let data = this.citySignupForm.serialize_all();
        let form_data = this.citySignupForm.serialize_sub( $(".sr_kommunenregistrierung_sub").first() );
        form_data.sr_creg_pricing_json = JSON.parse($('#sr_creg_pricing_json').val());


        citySignUpConfig.g_formAJAXSaveRequest = $.ajax({
            url:  this.data.formLateRegistrationUrl + "" + action + "&XDEBUG_SESSION=IDEA",
            type: "POST",
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            data: JSON.stringify(data), //JSON.stringify( serialize_sub( $(".sr_kommunenregistrierung_sub").first() ) ),
            complete: function () {
                citySignUpConfig.g_formAJAXSaveRequest = false;
            },
            success: function (data) {

                if (data[0] === '{') {
                    data = JSON.parse(data);
                }
                if (typeof data.status !== "undefined") {
                    if (data.status === "error") {
                        $("#sr_commune_reg_feedback").html("<span class='sr_error'><b>"+data.message+"</b></span><br><br>");
                    } else {
                        $("#sr_commune_reg_feedback").html(data.message + "<br><br>");
                    }
                } else {
                    $("#sr_commune_reg_feedback").html(data + "<br><br>");
                }
            },
            error: function (xhr) {
                if (!isNaN(xhr.responseText)) {
                    form.citySignupValidation.showErrorMessage(2000, xhr.responseText);
                } else {
                    form.citySignupValidation.showErrorMessage(2000, form.translationData.error);
                }
            }
        });
        return true;
    }
}

window.CitySignupLateRegistration = CitySignupLateRegistration;
