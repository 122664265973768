class BlogOverview {
    constructor(baseUrl, apiUrl, apiKey) {
        this.baseUrl = baseUrl;
        this.apiUrl = apiUrl;
        this.apiKey = apiKey;
        this.getblogUrl = this.apiUrl + "blog/getBlogOverviewEntries?sr_api_key=" + apiKey;

        let amountOfResultsPerSite = document.querySelectorAll('.numberOfResults .siteNumber');
        let searchBox = document.querySelector('#blogtitle_searchbox');
        let sortBtn = document.querySelector('#tableSortXs button');

        const utility = new Utility();
        const srUserId = utility.getUrlParameter('sr_user_id');

        if (srUserId) {
            this.getblogUrl += "&sr_user_id=" + srUserId;
        }

        let overview = this;

        let tableDesktop = $('#blog-table').DataTable({
            //"dom": '<<t>>', // show only table, no pagination and no filter
            "info": false,
            "paging": true,
            "order": [[1, "asc"]],
            "lengthChange": false,
            "lengthMenu": [[10, 20, 50, -1], [10, 20, 50, "Alle"]],  //[[20, 50, 100, -1], [20, 50, 100, "{_"Alle"}"]],
            "pagingType": "simple_numbers",
            "iDisplayLength": 10,
            'columnDefs':  [
                {
                    "targets": [ 0 ],
                    "className": "title",
                    "width": "35%"
                },
                {
                    "targets": [ 1 ],
                    "className": "date",
                },
                {
                    "targets": [ 2 ],
                    "className": "status",
                },
                {
                    'targets': [ 3 ],
                    'orderable': false,
                    "className": "action",
                },

            ],
            ajax: {
                url: this.getblogUrl,
                type: "POST",
                cache:  false,
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                }
            },
            columns: [
                {data: "title"},
                {data: "date"},
                {data: "status"},
                {data: "action"}
            ],
            processing: true,
            serverSide: true,
            "language": {
                "loadingRecords": "&nbsp;",
                "processing": '<div class="spinner"><img src="/images/loading.svg"></div>',
                "lengthMenu": "Einträge pro Seite: _MENU_",
                "zeroRecords": '<p style="padding-top:40px; padding-bottom:40px; font-weight:bold">Keine Einträge gefunden</p>',
                "info": "Seite _PAGE_ von _PAGES_",
                "infoEmpty": "Keine Einträge gefunden",
                "infoFiltered": "(gefiltert aus _MAX_)",
                "decimal": ".",
                "thousands": ",",
                "search":'Kommune suchen',
                oPaginate: {
                    sNext: '<svg class="pagination-icon" viewBox="0 0 100 100"><path d="M 10,50 L 60,100 L 62,98 L 15,50  L 62,2 L 60,0 Z" class="arrow" transform="translate(100, 100) rotate(180) "></path></svg>',
                    sPrevious: '<svg class="pagination-icon" viewBox="0 0 100 100"><path d="M 10,50 L 60,100 L 62,98 L 15,50  L 62,2 L 60,0 Z" class="arrow"></path></svg>',
                    sFirst: '<i class="fa fa-step-backward"></i>',
                    sLast: '<i class="fa fa-step-forward"></i>'
                }
            }
        }).on('draw.dt', function() {
            overview.eventListenerActionIcons(tableDesktop);
        });

        let tableMobile = $('#blog-table-mobile').DataTable({
            //"dom": '<<t>>', // show only table, no pagination and no filter
            "info": false,
            "paging": true,
            "order": [[0, "asc"]],
            "lengthChange": false,
            "lengthMenu": [[10, 20, 50, -1], [10, 20, 50, "Alle"]],  //[[20, 50, 100, -1], [20, 50, 100, "{_"Alle"}"]],
            "pagingType": "simple_numbers",
            "bAutoWidth": false,
            "iDisplayLength": 10,
            'columnDefs':  [
                {
                    "targets": [ 0 ],
                    "title": "",
                    "className": "status",
                    "width": "5%"
                },
                {
                    "targets": [ 1 ],
                    "title": "",
                    "className": "title",
                    "width": "60%"
                },
                {
                    'targets': [2],
                    "title": "",
                    "className": "date",
                    "width": "15%"
                },
                {
                    'targets': [3],
                    "title": "",
                    'orderable': false,
                    "className": "icons",
                    "width": "20%"
                },
            ],
            "aoColumns": [
                { "orderSequence": [ "desc", "asc" ], "sWidth": '5%' },
                { "orderSequence": [ "desc", "asc" ], "sWidth": '60%' },
                { "orderSequence": [ "desc", "asc" ], "sWidth": '15%' },
                { "orderSequence": [ "desc", "asc" ], "sWidth": '20%' }
            ],
            fixedHeader: true,
            ajax: {
                url: this.getblogUrl,
                type: "POST",
                cache:  false,
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                }
            },
            columns: [
                {data: "status"},
                {data: "title"},
                {data: "date"},
                {data: "action"}
            ],
            processing: true,
            serverSide: true,
            "language": {
                "loadingRecords": "&nbsp;",
                "processing": '<div class="spinner"><img src="/images/loading.svg"></div>',
                "lengthMenu": "Einträge pro Seite: _MENU_",
                "zeroRecords": '<p style="padding-top:40px; padding-bottom:40px; font-weight:bold">Keine Einträge gefunden</p>',
                "info": "Seite _PAGE_ von _PAGES_",
                "infoEmpty": "Keine Einträge gefunden",
                "infoFiltered": "(gefiltert aus _MAX_)",
                "decimal": ".",
                "thousands": ",",
                "search":'Kommune suchen',
                oPaginate: {
                    sNext: '<svg class="pagination-icon" viewBox="0 0 100 100"><path d="M 10,50 L 60,100 L 62,98 L 15,50  L 62,2 L 60,0 Z" class="arrow" transform="translate(100, 100) rotate(180) "></path></svg>',
                    sPrevious: '<svg class="pagination-icon" viewBox="0 0 100 100"><path d="M 10,50 L 60,100 L 62,98 L 15,50  L 62,2 L 60,0 Z" class="arrow"></path></svg>',
                    sFirst: '<i class="fa fa-step-backward"></i>',
                    sLast: '<i class="fa fa-step-forward"></i>'
                }
            }
        }).on('draw.dt', function() {
            overview.eventListenerActionIcons(tableMobile);
        });

        amountOfResultsPerSite.forEach(item => {
           item.addEventListener('click', (event) => {

               amountOfResultsPerSite.forEach(site => {
                  site.classList.remove('active');
               });

               item.classList.add('active');
               tableDesktop.page.len(item.getAttribute('data-number')).draw();
               tableMobile.page.len(item.getAttribute('data-number')).draw();
           });
        });

        searchBox.addEventListener('keyup', (event) => {
            if (typeof event == "undefined" || event.keyCode === 13) {
                tableDesktop.search(event.target.value).draw();
                tableMobile.search(event.target.value).draw();
            }
        });

        $('#tableSortXs button').on('click', (event) => {
            let sortBtn = document.querySelectorAll('#tableSortXs button');
            sortBtn.forEach((item) => {
                item.classList.remove('active');
            });
            event.target.classList.add('active');

            let sorting = event.target.dataset.srSort;
            let direction = event.target.dataset.srDirection;

            if (sorting === "date") {
                if (direction === "asc") {
                    tableMobile.order( [ 2, 'desc' ] ).draw();
                    event.target.setAttribute('data-sr-direction', 'desc');
                } else {
                    tableMobile.order( [ 2, 'asc' ] ).draw();
                    event.target.setAttribute('data-sr-direction', 'asc');
                }
            } else {
                if (direction === "asc") {
                    tableMobile.order( [ 0, 'desc' ] ).draw();
                    event.target.setAttribute('data-sr-direction', 'desc');
                } else {
                    tableMobile.order( [ 0, 'asc' ] ).draw();
                    event.target.setAttribute('data-sr-direction', 'asc');
                }
            }

            tableMobile.columns.adjust().draw();
        });

    }

    eventListenerActionIcons() {
        let deleteBtn = document.querySelectorAll('.action-icons.delete');
        let editBtn = document.querySelectorAll('.action-icons.edit');
        let deleteModal = document.querySelector('#sr-modal-blog-delete');
        let deleteConfirmBtn = document.querySelector('#blogEntryDeleteConfirmButton');
        let errorModal = document.querySelector('#sr-modal-blog-error-message');
        let errorModalText = document.querySelector('#sr-modal-blog-error-message .message');
        let successModal = document.querySelector('#sr-modal-blog-success-message');
        let successModalText = document.querySelector('#sr-modal-blog-success-message .message');
        let tableDesktop = document.querySelector('#blog-table');
        let tableMobile = document.querySelector('#blog-table-mobile');

        deleteBtn.forEach(item => {
            item.addEventListener('click', (eventTableBtn) => {
                $(deleteModal).modal('show');

                $(deleteConfirmBtn).off('click').on('click', (eventConfirmBtn) => {
                    let postId = eventTableBtn.target.attributes['data-sr-post-id'].value;

                    $.ajax({
                        url: this.apiUrl + "blog/user/delete/" + postId + "?sr_api_key=" + this.apiKey,
                        method: "GET",
                        success: (response) => {
                            $(deleteModal).modal('hide');

                            if (response.error === false) {
                                $(successModal).modal('show');
                                successModalText.innerHTML = response.msg;
                                setTimeout(function() {
                                    $(successModal).modal('hide');
                                }, 2000);

                                $(tableDesktop).DataTable().ajax.reload();
                                $(tableMobile).DataTable().ajax.reload();
                            } else {
                                $(errorModal).modal('show');
                                errorModalText.innerHTML = response.msg;
                                setTimeout(function() {
                                    $(successModal).modal('hide');
                                }, 2000);
                            }
                        }
                    })
                });
            });
        });

        editBtn.forEach(item => {

            item.addEventListener('click', (event) => {
                const utility = new Utility()
                let postId = event.target.attributes['data-sr-post-id'].value
                let userId = utility.getUrlParameter('sr_user_id');

                if (userId) {
                    window.location = this.baseUrl + "user/blog?L=0&sr_action=edit&sr_post_id=" + postId + "&sr_user_id=" + userId;
                } else {
                    window.location = this.baseUrl + "user/blog?L=0&sr_action=edit&sr_post_id=" + postId;
                }
            });
        })

    }

}

window.BlogOverview = BlogOverview;
