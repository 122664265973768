class PartnerLocal {
    constructor(baseUrl, apiUrl, apiKey) {

        const fstateSelect = document.querySelector('#fstate-select');
        const citySelect = document.querySelector('#city-select');
        const partner = this;

        $(citySelect).select2({
            placeholder: "Bitte auswählen",
            width: '100%',
            minimumInputLength: 3,
            ajax: {
                url: apiUrl + "cities/partnerlocal/cities?sr_api_key=" + apiKey,
                dataType: 'json',
                data: (params) => {
                    return {
                        sr_cityname: params.term
                    };
                },
                processResults: (data) => {
                    let resVal = [];
                    $.each(data, function(index,item) {
                        resVal.push({
                            'id' : item.id,
                            'text': item.text,
                            'layout': item.layout
                        });
                    });
                    return {
                        results: resVal
                    };
                }
            }
        });

        $(fstateSelect).select2({
            placeholder: "Bitte auswählen",
            width: '100%',
            ajax: {
                url: apiUrl + "cities/partnerlocal/fstates?sr_api_key=" + apiKey,
                dataType: 'json',
                data: (params) => {
                    return {
                        sr_fstate: params.term
                    };
                },
                processResults: (data) => {
                    let resVal = [];
                    $.each(data, function(index,item) {
                        resVal.push({
                            'id' : index,
                            'text': item.text,
                        });
                    });
                    return {
                        results: resVal
                    };
                }
            }
        });

        $(citySelect).on('select2:select', function(e) {
           let partnerWrapper = document.querySelector('.partners');
           partnerWrapper.innerHTML = partner.templateForCityPartners(e.params.data, baseUrl);
        });

        $(fstateSelect).on('select2:select', function(e) {

            $.ajax({
                url: apiUrl + "cities/partnerlocal/cities?sr_api_key=" + apiKey + "&sr_fstate=" + e.params.data.text,
                dataType: 'json',
                method: 'GET',
                success: (response) => {
                    let partnerWrapper = document.querySelector('.partners');
                    let html = '';
                    response.forEach(item => {
                        html += partner.templateForCityPartners(item, baseUrl);
                    });
                    partnerWrapper.innerHTML = html;
                }
            });

        });
    }

    templateForCityPartners(data, baseUrl) {
        const layoutData = JSON.parse(data.layout);

        let html = '<div class="row-partner">';
        html += '<h2 class="row-headline">In ' + data.text + '</h2>';
        html += '<div class="grid-wrapper">';

        for (const key in layoutData) {
            // external link add https if missing
            if (!layoutData[key].url.includes("http") && layoutData[key].url.length > 0) {
                layoutData[key].url = "https://" + layoutData[key].url;
            }
            html += '<div class="grid-item">';
            // empty fallback
            if (layoutData[key].url) {
                html += '<a href="' + layoutData[key].url + '" target="_blank">';
                html += '<img alt="" class="img-responsive" src="' + baseUrl + 'upload/ork/user_assets/kommunen_partner/' + layoutData[key].image + '">';
                html += '</a></div>';
            }
            else {
                html += '<img alt="" class="img-responsive" src="' + baseUrl + 'upload/ork/user_assets/kommunen_partner/' + layoutData[key].image + '">';
                html += '</div>';
            }
            
        }

        html += '</div></div>';

        return html;
    }
}

window.PartnerLocal = PartnerLocal;
